import { Menu } from '@sede-x/shell-ds-react-framework';
import React from 'react';
import { MenuInfo } from 'rc-menu/lib/interface';

interface PopupMenuProps {
  selectedMenu: string;
  setMenu: (menu: MenuInfo) => void;
}
const menuList = [
  { key: 'Card', label: 'Card' },
  { key: 'Contract', label: 'Contract' },
  { key: 'Customer', label: 'Customer' },
  { key: 'OBU', label: 'OBU' },
  { key: 'Vehicle', label: 'Vehicle' }
];
const PopupMenu: React.FC<PopupMenuProps> = ({ selectedMenu, setMenu }) => (
  <div>
    <Menu items={menuList} selectedKeys={[selectedMenu]} onClick={setMenu} />
  </div>
);

export default PopupMenu;
