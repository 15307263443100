import { ColumnDef, Row } from '@tanstack/react-table';
import { Dispatch, ReactNode, SetStateAction, useRef } from 'react';
import { Icons, Loading } from '@sede-x/shell-ds-react-framework';
import { PaginationProps } from 'components/Table/types';
import ErrorState from 'components/ErrorState/ErrorState';
import Table from '../Table/Table';
import ContentHeader from '../ContentHeader/ContentHeader';
import { ParamsType, ValidationRef } from '../ContentHeader/types';
import { FilterType } from '../Filter/types';

interface Props<TData> {
  menuKey: string;
  onButtonClick: () => void;
  buttonText?: string;
  data: TData[];
  cols: ColumnDef<TData>[];
  params: ParamsType;
  setParams: Dispatch<SetStateAction<ParamsType>>;
  isLoading?: boolean;
  isError?: boolean;
  paginationProps: PaginationProps;
  filterObject: FilterType;
  renderRowSubComponent?: (row?: Row<TData>) => void;
  title?: string;
  onClickExportAll?: (errors?: ParamsType) => void;
  exportAllEnabled?: boolean;
  onSelectedRowsChange?: (rows: TData[]) => void;
  enableMultiRowSelection?: boolean;
  disableDetailSection?: boolean;
  exportEnabled?: boolean;
}

function Item({
  children,
  isLoading,
  isError
}: Readonly<{
  children: ReactNode;
  isLoading?: boolean;
  isError?: boolean;
}>) {
  if (isLoading) {
    return (
      <div className="flex justify-center items-center m-auto">
        <Loading size="large" />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex justify-center items-center m-auto">
        <ErrorState
          icon={<Icons.Alert />}
          sentiment="negative"
          title="Error"
          description="An error occurred while executing the report, please try again after some time."
        />
      </div>
    );
  }
  return <>{children}</>;
}

function ReportsDetails<TData>({
  onButtonClick,
  buttonText,
  data,
  cols,
  menuKey,
  params,
  setParams,
  isLoading,
  isError,
  paginationProps,
  filterObject,
  renderRowSubComponent,
  title,
  onClickExportAll,
  exportAllEnabled,
  onSelectedRowsChange,
  enableMultiRowSelection,
  disableDetailSection = false,
  exportEnabled = true
}: Readonly<Props<TData>>) {
  const filterRef = useRef<ValidationRef>(null);

  const handleClickExportAll = () => {
    if (onClickExportAll) {
      onClickExportAll(filterRef.current?.validateFilter());
    }
  };
  return (
    <div className="flex flex-col h-full">
      <ContentHeader
        onButtonClick={onButtonClick}
        menu={menuKey}
        filters={params}
        setFilters={setParams}
        filterObject={filterObject}
        title={title}
        ref={filterRef}
        buttonText={buttonText}
      />

      {!disableDetailSection && (
        <div className="flex flex-col bg-white mt-2 p-5 border border-slate-300 grow overflow-y-auto gap-2">
          <Item isLoading={isLoading} isError={isError}>
            {data ? (
              <Table<TData>
                exportFileName={`${menuKey}-Report`}
                data={data}
                columns={cols}
                paginationData={paginationProps}
                renderRowSubComponent={renderRowSubComponent}
                handleClickExportAll={handleClickExportAll}
                exportAllEnabled={exportAllEnabled}
                onSelectedRowsChange={onSelectedRowsChange}
                enableMultiRowSelection={enableMultiRowSelection}
                exportEnabled={exportEnabled}
              />
            ) : (
              <div className="flex justify-center items-center grow font-ShellFont">
                Please make your search criteria and click execute to get the
                report.
              </div>
            )}
          </Item>
        </div>
      )}
    </div>
  );
}

export default ReportsDetails;
