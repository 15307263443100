import { SingleDatePicker } from '@sede-x/shell-ds-react-framework';
import { FieldProps } from 'formik';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import dayjs from 'dayjs';
import FormLabel from './FormLabel';

dayjs.extend(weekday);
dayjs.extend(localeData);

const DatePicker = (props: FieldProps) => {
  const {
    field,
    form: { touched, errors },
    ...rest
  } = props;

  return (
    <div>
      <FormLabel {...field} {...rest}>
        <SingleDatePicker
          elevation
          inputReadOnly
          getPopupContainer={(triggerNode: HTMLElement) =>
            triggerNode.parentElement as HTMLElement
          }
          {...rest}
          invalid={!!(touched[field.name] && errors[field.name])}
          data-testid={`${field.name}-datepicker`}
          value={field.value ? dayjs(field.value) : null}
        />
        {touched[field.name] && errors[field.name] && (
          <div className="text-red-500 text-sm">
            {errors[field.name] as string}
          </div>
        )}
      </FormLabel>
    </div>
  );
};
export default DatePicker;
