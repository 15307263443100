import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
  ExpandedState,
  getExpandedRowModel,
  getFilteredRowModel
} from '@tanstack/react-table';

import {
  THead,
  TR as SdsTr,
  TH as SdsTh,
  TBody,
  TD as SdsTd,
  Flexbox,
  SorterButton,
  IPaginationProps,
  EmptyState,
  PaginationSelect,
  TableWrapper,
  TextInput,
  Icons
} from '@sede-x/shell-ds-react-framework';

import { PAGE_SIZE_OPTIONS } from 'constants/table';
import { CustomTable } from './types';
import {
  ExpandableTableContainer,
  StyledPagination,
  SDSTableExpandable,
  RowGap
} from './style';
import ExportTableData from './TableExport';
import ShowHideColumns from './ShowHideColumns';

const DEFAULT_MAX_HEIGHT = 0;
const DEFAULT_HEADER_SIZE = 150;

declare module 'react' {
  function forwardRef<T, P = object>(
    render: (props: P, ref: React.Ref<T>) => React.ReactNode | null
  ): (props: P & React.RefAttributes<T>) => React.ReactNode | null;
}

interface TableRefType {
  resetExpanded?: () => void;
}

function Table<TData>(
  {
    data,
    columns,
    exportFileName = 'report',
    stickyColumns,
    maxHeight = DEFAULT_MAX_HEIGHT,
    maxWidth,
    stickyHeader = true,
    paginationData,
    renderRowSubComponent,
    handleClickExportAll,
    exportAllEnabled,
    exportEnabled = true,
    columnSelection = true,
    onSelectedRowsChange,
    enableMultiRowSelection = true,
    resetSelectedRowsOnPageChange = true,
    onRowDoubleClick,
    onRowClick,
    enableRowSelection,
    selectedRows,
    getRowId
  }: CustomTable<TData>,
  ref: React.ForwardedRef<TableRefType>
) {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState({});
  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [globalFilter, setGlobalFilter] = useState('');

  useEffect(() => {
    if (selectedRows) {
      setRowSelection(selectedRows);
    }
  }, [selectedRows]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      rowSelection,
      expanded,
      globalFilter
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    manualPagination: true,
    enableMultiRowSelection,
    enableExpanding: true,
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    enableGlobalFilter: true,
    getFilteredRowModel: getFilteredRowModel(),
    globalFilterFn: 'includesString',
    enableRowSelection,
    getRowId
  });

  useImperativeHandle(ref, () => ({
    resetExpanded() {
      table.resetExpanded();
    }
  }));

  const mounted = useRef(false);
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    }
    if (onSelectedRowsChange) {
      onSelectedRowsChange(
        table.getSelectedRowModel().flatRows.map((row) => row.original)
      );
    }
  }, [rowSelection, table, onSelectedRowsChange]);

  const wrapperProps = {
    ...(maxHeight && { maxHeight }),
    ...(maxWidth && { maxWidth }),
    ...(stickyHeader && {
      stickyRows: stickyHeader
    })
  };

  const handlePageChange = (pageNumber: number, newPageSize: number) => {
    if (resetSelectedRowsOnPageChange) {
      setRowSelection({});
    }
    paginationData?.onPaginationChange(pageNumber, newPageSize);
  };

  const paginationProps: IPaginationProps = {
    total: paginationData?.total,
    current: paginationData?.page,
    pageSize: paginationData?.pageSize,
    onChange: handlePageChange,
    showSizeChanger: true,
    selectComponentClass: PaginationSelect,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    showTotal: (total) => `Total ${total} items`
  };

  const isEmpty = table.getRowModel().rows.length === 0;
  const title = 'No data found';

  return (
    <>
      <ExpandableTableContainer className="pt-4 gap-4">
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div style={{ width: '320px' }}>
            <TextInput
              suffix={{
                node: <Icons.Search height={24} width={24} />
              }}
              placeholder="Search"
              value={globalFilter ?? ''}
              onChange={(e) => setGlobalFilter(e.target.value)}
              data-testid="search-input"
              clearable
              onClear={() => {
                setGlobalFilter('');
              }}
            />
          </div>
        </div>

        {columnSelection && <ShowHideColumns<TData> table={table} />}

        <TableWrapper {...wrapperProps}>
          <div>
            <SDSTableExpandable size="small">
              <THead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <SdsTr
                    size="small"
                    key={headerGroup.id}
                    depth={headerGroup.depth}
                    {...(stickyHeader && {
                      sticky: true,
                      stickyType: 'header'
                    })}
                    style={{
                      zIndex: 2,
                      height: '30px'
                    }}
                  >
                    {headerGroup.headers.map((header) => (
                      <SdsTh
                        key={header.id}
                        colSpan={header.colSpan}
                        {...(stickyColumns?.includes(header?.column.id) && {
                          sticky: true,
                          stickyType: 'column',
                          startingPoint: header?.column.getStart()
                        })}
                        style={{
                          width:
                            header.getSize() !== DEFAULT_HEADER_SIZE
                              ? header.getSize()
                              : undefined
                        }}
                      >
                        <Flexbox
                          gap="12px"
                          alignItems="center"
                          className="whitespace-nowrap"
                        >
                          <>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                            {header.column.getCanSort() && (
                              <SorterButton
                                sortDirection={
                                  header.column.getIsSorted() || undefined
                                }
                                onClick={header.column.getToggleSortingHandler()}
                              />
                            )}
                          </>
                        </Flexbox>
                      </SdsTh>
                    ))}
                  </SdsTr>
                ))}
              </THead>
              <TBody>
                {isEmpty && (
                  <SdsTr>
                    <SdsTd
                      colSpan={table.getAllColumns().length}
                      style={{ padding: 'unset' }}
                    >
                      <EmptyState
                        title={title}
                        style={{ padding: '60px', backgroundColor: 'white' }}
                      />
                    </SdsTd>
                  </SdsTr>
                )}
                {table.getRowModel().rows.map((row) => (
                  <>
                    <SdsTr
                      key={row.id}
                      onDoubleClick={() => onRowDoubleClick?.(row)}
                      onClick={() => onRowClick?.(row)}
                      className={`${
                        onRowDoubleClick || onRowClick ? 'cursor-pointer' : ''
                      } `}
                      style={{
                        ...(row.getIsSelected() && {
                          border: '3px solid rgb(251, 206, 7)'
                        })
                      }}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <SdsTd
                          key={cell.id}
                          {...(stickyColumns?.includes(cell?.column.id) && {
                            sticky: true,
                            stickyType: 'column',
                            startingPoint: cell?.column.getStart()
                          })}
                          className="whitespace-nowrap"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </SdsTd>
                      ))}
                    </SdsTr>

                    {row.getIsExpanded() ? (
                      <SdsTr key={`exp_${row.id}`}>
                        <SdsTd
                          colSpan={table.getVisibleFlatColumns().length}
                          style={{ padding: 'unset' }}
                        >
                          {renderRowSubComponent
                            ? renderRowSubComponent(row)
                            : null}
                        </SdsTd>
                      </SdsTr>
                    ) : null}
                    <RowGap>
                      <td
                        aria-hidden="true"
                        tabIndex={-1}
                        colSpan={columns.length}
                        className="!bg-transparent"
                      />
                    </RowGap>
                  </>
                ))}
              </TBody>
            </SDSTableExpandable>
          </div>
        </TableWrapper>
      </ExpandableTableContainer>
      <div className="flex flex-col gap-5">
        {paginationData && (
          <StyledPagination
            {...paginationProps}
            size="medium"
            position="center"
          />
        )}
        {exportEnabled && (
          <ExportTableData<TData>
            fileName={exportFileName}
            table={table}
            handleClickExportAll={handleClickExportAll}
            exportAllEnabled={exportAllEnabled}
          />
        )}
      </div>
    </>
  );
}

const ExpandableTable = React.forwardRef(Table);

export { ExpandableTable };
export type { TableRefType };
