import {
  Button,
  Icons,
  Text,
  Variants
} from '@sede-x/shell-ds-react-framework';
import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api';
import { customerEndPoints } from 'api/apiEndpoints';
import { ExpandableTable } from 'components/Table/ExpandableTable';
import { useSdsPagination } from 'hooks/use-pagination';
import { useState } from 'react';
import { columns } from 'components/Table/columns/overviewColumns';
import QueryError from 'components/QueryError';
import { STALE_TIME, queryClient } from 'react-query';
import CustomerSearch from 'Pages/Actions/ProviderSpecfic/DetailComponents/CustomerSearch/CustomerSearch';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { ColumnDef } from '@tanstack/react-table';
import { errorHelper } from 'utils/helpers/errorHelper';

const DEFAULT_PAGE_SIZE = 20;
const ADDITIONAL_ACCOUNT_QUERY_KEY = 'customer-additionalAccount';

interface AdditionalAccount {
  customerAdditonalID: string;
  customerID: string;
  additionalCustomerID: string;
  status: string;
  createdAt: string;
  createdBy: string;
  lastChangedAt: string;
  lastChangedBy: string;
  customerNo: string;
  customerCountryCode: string;
  additionalNo: string;
  additionalCountryCode: string;
}

export const AdditionalAccounts = ({ customerId }: { customerId: string }) => {
  async function fetchAdditionalAccounts(pageNumber: number, size: number) {
    const { data } = await customerInstance.post(
      customerEndPoints.additionalAccounts,
      {
        pageNumber,
        pageSize: size,
        customerId
      }
    );
    return data;
  }
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [selectedRow, setSelectedRow] = useState<AdditionalAccount[] | null>(
    null
  );

  const { errorDialog, successDialog, confirmCloseDialog } =
    useConfirmDialogs();

  const {
    data: additionalAccountsData,
    isLoading,
    isError
  } = useQuery({
    queryKey: [ADDITIONAL_ACCOUNT_QUERY_KEY, page, pageSize, customerId],
    queryFn: () => fetchAdditionalAccounts(page, pageSize),
    keepPreviousData: true,
    refetchOnWindowFocus: 'always',
    staleTime: STALE_TIME
  });

  const addNewAdditionalAccount = (additionalCustomerID: string) => {
    customerInstance
      .post(customerEndPoints.saveAdditionalAccount, {
        customerID: customerId,
        additionalCustomerID
      })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: [ADDITIONAL_ACCOUNT_QUERY_KEY]
        });

        successDialog('', 'New Additional Account added successfully');
      })
      .catch((error) => {
        const message = 'Failed to add a new Additional Account.';
        errorDialog('', errorHelper(error, message));
      });
  };

  const handleDelete = async (customerAdditonalAccountID: string) => {
    const isConfirmed = await confirmCloseDialog(
      'Delete Additional Account',
      'Are you sure you want to delete this Additional Account?'
    );
    if (!isConfirmed) {
      return;
    }

    customerInstance
      .post(customerEndPoints.deleteAdditionalAccount, {
        customerAdditonalAccountID
      })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: [ADDITIONAL_ACCOUNT_QUERY_KEY]
        });
        successDialog('', 'Additional account deleted successfully');
        setSelectedRow(null);
      })
      .catch((error) => {
        const message = 'Failed to delete additional account.';
        errorDialog('', errorHelper(error, message));
      });
  };

  const paginationProps = useSdsPagination((newPage, newPageSize) => {
    setPage(newPage);
    setPageSize(newPageSize);
  });
  return (
    <div
      className="flex flex-col grow overflow-y-auto pb-3 gap-2 bg-shellExtraPaleGrey2"
      data-testid="additional-account-list"
    >
      <div className=" bg-white p-5">
        <div className="flex flex-row justify-between items-center">
          <Text prominence="strong" size="medium" type="p" bold>
            Additional Accounts
          </Text>
          <div className="flex justify-end gap-3">
            <CustomerSearch
              label="Add additional account"
              title="Add Additional Account"
              onSubmit={(customer) =>
                addNewAdditionalAccount(customer?.customerId ?? '')
              }
              triggerComponent="button"
              buttonIcon={<Icons.Add />}
              buttonText="New"
              variant={Variants.Filled}
              sentiment="primary"
              confirmButtonText="Submit"
            />
            <Button
              data-testid="delete-account"
              size="xsmall"
              variant="transparent"
              disabled={!selectedRow?.length}
              icon={<Icons.TrashClear />}
              onClick={() =>
                handleDelete(selectedRow?.[0].customerAdditonalID ?? '')
              }
              hidden={!additionalAccountsData?.data.length}
              style={{
                display: !additionalAccountsData?.data.length ? 'none' : ''
              }}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
      <QueryError isLoading={isLoading} isError={isError}>
        <ExpandableTable<AdditionalAccount>
          columnSelection={false}
          enableMultiRowSelection={false}
          columns={columns.AdditionalAccounts as ColumnDef<AdditionalAccount>[]}
          exportEnabled={false}
          paginationData={{
            ...paginationProps,
            total: additionalAccountsData?.count
          }}
          onRowClick={(row) => setSelectedRow([row.original])}
          data={additionalAccountsData?.data ?? []}
          selectedRows={{
            [selectedRow?.[0]?.customerAdditonalID as string]: true
          }}
          getRowId={(row) => row.customerAdditonalID}
        />
      </QueryError>
    </div>
  );
};
