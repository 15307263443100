import { FormikErrors } from 'formik';
import { Contact } from '../types';

export const contactValidation = (values: Contact) => {
  const errors: FormikErrors<Contact> = {};

  if (!values.countryTypeID) {
    errors.countryTypeID = 'Please select Country';
  }

  if (!values.lastName) {
    errors.lastName = 'Please enter Last Name';
  }

  if (
    values.countryTypeID === '2dfb93b4-027e-4ae4-aa03-3e7138af0223' &&
    !values.belgianRegionId
  ) {
    errors.belgianRegionId = 'Please enter Belgian Region';
  }
  if (
    values.countryTypeID === '7f50cb6e-aecb-4870-90c4-fcb8799ee539' &&
    !values.italianProvinceId
  ) {
    errors.italianProvinceId = 'Please enter Italian Province';
  }

  return errors;
};
