import CheckBox from 'components/FormCmponents/CheckBox';
import SelectField from 'components/FormCmponents/SelectField';
import { Field } from 'formik';
import ParticlateFilters from 'utils/StaticData/Vehicle/particulateFilters.json';

export const OtherEquipment = () => (
  <>
    <Field
      id="particulateFilterClass"
      name="particulateFilterClass"
      label="Particulate filter class V.5"
      labelPosition="left"
      placeholder="Select"
      component={SelectField}
      options={ParticlateFilters.map((item) => ({
        label: item.Name,
        value: item.Id
      }))}
    />
    <Field
      id="metallizedWindshield"
      name="metallizedWindshield"
      label="Metallized windshield"
      labelPosition="left"
      component={CheckBox}
    />
  </>
);
