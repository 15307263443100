import { FormikErrors } from "formik";
import { Vehicle } from "../types";

export const validateVehicleDetails = (values: Vehicle) => {
    const errors: FormikErrors<Vehicle> = {};
    const MANDATORY_FIELDS = [
        'weightF1',
        'carRegistration',
        'cleanLicensePlate',
        'truckTractorAxlesNumber',
        'firstDayOfRegistration'
    ];
    MANDATORY_FIELDS.forEach((field) => {
        if (String(values[field as keyof Vehicle]).trim() === '') {
            errors[field as keyof Vehicle] = 'Please fill out this mandatory field';
        }
    });

    return errors;
};
