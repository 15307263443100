import { Text } from '@sede-x/shell-ds-react-framework';
import { ReportsIcon } from './styles';

interface Props {
  welcomeTitle: string;
  content: string;
}

const ReportsMenuUnselected = ({ welcomeTitle, content }: Props) => (
  <div className="flex flex-col bg-white w-full items-center justify-center h-full">
    <ReportsIcon />
    <Text
      prominence="strong"
      size="large"
      type="p"
      bold
      data-testid="reportsWelcomeText"
    >
      {welcomeTitle}
    </Text>
    <Text
      prominence="subtle"
      size="medium"
      type="p"
      className="break-words w-4/12 text-center"
    >
      {content}
    </Text>
  </div>
);

export default ReportsMenuUnselected;
