import { useState, useEffect } from 'react';
import { Country } from 'utils/Interfaces/StaticData';
import { useStaticData } from './useStaticData';

interface ColcoMap {
  [key: string]: Country;
}

const useCustomerColco = (fetchEnabled: boolean = true) => {
  const [colcoMap, setColcoMap] = useState<ColcoMap>({});

  const { data } = useStaticData('/country', '/country', fetchEnabled);

  useEffect(() => {
    if (data?.data?.length) {
      const obj: ColcoMap = {};
      data.data.forEach((dataObj: Country) => {
        if (dataObj.countryCode && dataObj.shellCountryCode) {
          obj[dataObj.countryID] = dataObj;
        }
      });
      setColcoMap(obj);
    }
  }, [data]);

  return [colcoMap];
};

export default useCustomerColco;
