import {
  Alignments,
  Button,
  Flexbox,
  Icons,
  ModalAction,
  SelectionCheckbox,
  Sentiments,
  Sizes,
  Variants
} from '@sede-x/shell-ds-react-framework';
import { columnBuilder, removerOrders } from 'utils/helpers';
import { EVignetteCustomers, EVignetteOrdersActions } from 'utils/Interfaces';
import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from 'api';
import QueryError from 'components/QueryError';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { useState } from 'react';
import Table from 'components/Table/Table';
import { createColumnHelper } from '@tanstack/react-table';
import { useSdsPagination } from 'hooks/use-pagination';
import ActionColumns from '../../../../../components/Table/columns/actionColumns.json';
import { StyledModal } from './style';

export interface OrdersPayload {
  pageNumber: number;
  pageSize: number;
  customerID: string;
  tollID?: string;
}
const columnHelper = createColumnHelper<EVignetteOrdersActions>();

const actions = [
  columnHelper.display({
    id: 'actions',
    size: 20,
    cell: ({ row }) => (
      <Flexbox gap="12px" alignItems="center">
        <SelectionCheckbox
          checked={row.getIsSelected()}
          indeterminate={row.getIsSomeSelected()}
          onChange={row.getToggleSelectedHandler()}
          data-testid={`rowcheck-${row.index}`}
        />
      </Flexbox>
    ),
    header: ({ table }) => (
      <Flexbox gap="12px" alignItems="center">
        <SelectionCheckbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      </Flexbox>
    )
  })
];
const ordersColumns = [
  ...actions,
  ...columnBuilder<EVignetteOrdersActions>(ActionColumns.eVignetteOrders)
];
function OrderDetails<TData>({
  selectedRows,
  onSuccess,
  tollId
}: Readonly<{
  selectedRows: Array<TData>;
  onSuccess?: () => void;
  tollId?: string;
}>) {
  const [open, setOpen] = useState(false);
  const [selectedOrders, setSelectedOrders] = useState<
    Array<EVignetteOrdersActions>
  >([]);

  const { confirmCloseDialog, errorDialog, successDialog } =
    useConfirmDialogs();
  const [selectedCustomer] = selectedRows;

  const payload = {
    customerID: (selectedCustomer as EVignetteCustomers)?.customerID,
    tollID: tollId,
    pageNumber: 1,
    pageSize: 20
  };

  const {
    mutate: getOrders,
    data,
    isLoading,
    isError,
    reset: resetData
  } = useMutation(
    ['getCustomer', payload],
    (orderPayload: OrdersPayload) =>
      axiosInstance.post('report/action/evignette/orders', orderPayload, {
        headers: {
          'Content-Type': 'application/json'
        }
      }),
    {
      cacheTime: 0,
      retry: false
    }
  );
  const { data: ordersData, totalNumberOfRecords } = data?.data || {};

  const handleChangePagination = (pageNumber: number, newPageSize: number) => {
    getOrders({
      pageNumber,
      pageSize: newPageSize,
      customerID: (selectedCustomer as EVignetteCustomers)?.customerID,
      tollID: tollId
    });
  };
  const paginationProps = useSdsPagination(handleChangePagination);
  const { resetPagination } = paginationProps;

  const handleRemove = async () => {
    const isConfirmed = await confirmCloseDialog(
      selectedOrders.length > 1
        ? 'Are you sure you want to remove these records?'
        : 'Are you sure you want to remove this record?',
      selectedOrders.length > 1 ? 'Remove the records' : 'Remove the record'
    );
    if (isConfirmed) {
      const orders = selectedOrders.map((row) => row.detailID);

      removerOrders('/report/action/evignette/orders/remove', orders)
        .then((_response) => {
          successDialog(
            '',
            selectedOrders.length > 1
              ? 'Records Deleted successfully'
              : 'Record Deleted successfully'
          );
          handleOnClose();
          onSuccess?.();
        })
        .catch(() => {
          errorDialog('', 'Your request has been failed. Please try again.');
        });
    }
  };
  const handleOnClose = () => {
    resetData();
    resetPagination();
    setOpen(false);
    setSelectedOrders([]);
  };

  const removeAction = [
    {
      label: 'Remove',
      action: () => handleRemove(),
      props: {
        variant: Variants.Outlined,
        iconPosition: 'left',
        sentiment: Sentiments.Negative,
        icon: <Icons.Cross />,
        hidden: isError || isLoading,
        'data-testid': 'remove-button',
        disabled: !selectedOrders.length
      }
    }
  ];

  return (
    <>
      <Button
        data-testid="next-button"
        disabled={!selectedRows.length}
        id="nextbutton"
        iconPosition="right"
        icon={<Icons.Play />}
        onClick={() => {
          setOpen(true);
          getOrders({
            customerID: (selectedCustomer as EVignetteCustomers)?.customerID,
            tollID: tollId,
            pageNumber: 1,
            pageSize: 20
          });
        }}
        size="small"
      >
        Next
      </Button>
      {open && (
        <StyledModal
          width="70%"
          title="Orders"
          open={open}
          size={Sizes.Small}
          maskClosable={false}
          actions={removeAction as ModalAction[]}
          mask
          onClose={handleOnClose}
          contentScrollable
          actionsAlignment={Alignments.Right}
          centered
          bodyPadding={false}
        >
          <div className="mr-2 flex p-3 flex-col flex-grow overflow-auto h-full ">
            <QueryError isError={isError} isLoading={isLoading}>
              <Table
                columns={ordersColumns}
                data={ordersData ?? []}
                columnSelection={false}
                exportEnabled={false}
                onSelectedRowsChange={setSelectedOrders}
                paginationData={{
                  ...paginationProps,
                  total: totalNumberOfRecords
                }}
              />
            </QueryError>
          </div>
        </StyledModal>
      )}
    </>
  );
}

export default OrderDetails;
