import { Flexbox, SelectionCheckbox } from '@sede-x/shell-ds-react-framework';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { columnBuilder } from 'utils/helpers';
import { ProvidersData } from '../../../Pages/Providers/types';
import ProviderColumns from './providersColumns.json';

const columnHelper = createColumnHelper<ProvidersData>();
const CCO_ARRAY_LENGTH: number = 16;

export function getProvidersColumn(value: string) {
  return columns[value as keyof typeof columns];
}
const actions = [
  columnHelper.display({
    id: 'actions',
    size: 20,
    cell: ({ row }) => (
      <Flexbox gap="12px" alignItems="center">
        <SelectionCheckbox
          checked={row.getIsSelected()}
          indeterminate={row.getIsSomeSelected()}
          onChange={row.getToggleSelectedHandler()}
          data-testid={`rowcheck-${row.index}`}
        />
      </Flexbox>
    ),
    header: ({ table }) => (
      <Flexbox gap="12px" alignItems="center">
        <SelectionCheckbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      </Flexbox>
    )
  })
];

const columnGenerator = (arrayLen: number, field: string, column: string) => {
  const ccoArray: ColumnDef<ProvidersData>[] = [];
  [...Array(arrayLen)].forEach((_, i) =>
    ccoArray.push(
      columnHelper.accessor(`${field}${i + 1}`, {
        header: `${column}${i + 1}`,
        cell: (info) => info.getValue()
      })
    )
  );
  return ccoArray;
};

const autoPayRegisteredVehiclesReport = [
  ...actions,
  ...columnBuilder<ProvidersData>(
    ProviderColumns.autoPayRegisteredVehiclesReport
  )
];

const autoPayVehicleRequestReport = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.autoPayVehicleRequestReport)
];

const fdeCCLReport = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.fdeCCLReport)
];

const fdeRegistrationReport = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.fdeRegistrationReport)
];

const fdeTransactionReport = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.fdeTransactionReport)
];

const fdeVirtualCardOrder = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.fdeVirtualCardOrder)
];

const telepassOBUMonitoringReport = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.telepassOBUMonitoringReport)
];

const germanyInvoice = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.germanyInvoice)
];

const germanyNotificationMonthly = [
  ...actions,
  ...columnBuilder<ProvidersData>(
    ProviderColumns.germanyTransactionNotification
  )
];

const germanyTransNotificationDaily = [
  ...actions,
  ...columnBuilder<ProvidersData>(
    ProviderColumns.germanyTransactionNotification
  )
];

const germanyTransReconciliation = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.germanyTransReconciliation)
];

const germanyInboundOutbound = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.germanyInboundOutbound)
];

const germanyContractList = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.germanyContractList)
];

const germanyContractStatus = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.germanyContractStatus)
];

const germanyPanIDMismatch = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.germanyPanIDMismatch)
];

const czechTollRegAccounts = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.czechTollRegAccounts)
];

const czechTollRegistrationRequests = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.czechTollRegistrationRequests)
];

const czechTollCardChange = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.czechTollCardChange)
];
const evignetteRegisteredCustomers = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.evignetteRegisteredCustomers)
];
const evignettesOpenOrders = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.evignettesOpenOrders)
];

const evignetteCardBlocked = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.cardBlocked)
];

const evignetteCardExpired = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.cardExpired)
];
const ukLevyCardExpired = [...evignetteCardExpired];

const czechTollCardExpired = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.czechTollCardExpired)
];

const germanyCardExpired = [...evignetteCardExpired];
const telepassCardExpired = [...evignetteCardExpired];
const fdeCardExpired = [...evignetteCardExpired];
const t4eCardExpired = [...evignetteCardExpired];
const slovakiaCardExpired = [...evignetteCardExpired];

const evignetteError = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.evignetteError)
];
const evignettesOrders = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.evignettesOrders)
];

const germanyLimitConfirmations = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.germanyLimitConfirmations)
];

const germanyLimitRequests = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.germanyLimitRequests)
];

const germanyLimitConfirmationsErrors = [
  ...actions,
  ...columnBuilder<ProvidersData>(
    ProviderColumns.germanyLimitConfirmationsErrors
  )
];

const germanyDeregisteredbyCustomer = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.germanyDeregisteredbyCustomer)
];

const germanyTransactionError = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.germanyTransactionError)
];

const germanytransNotificationFortnight = [
  ...actions,
  ...columnBuilder<ProvidersData>(
    ProviderColumns.germanyTransactionNotification
  )
];

const germanyUsingBlockedCard = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.germanyUsingBlockedCard)
];

const belgiumCardBlocked = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.cardBlocked)
] as ColumnDef<ProvidersData>[];

const czechTollCardBlocked = [...belgiumCardBlocked];

const belgiumCardExpired = [...evignetteCardExpired];

const slovakiaCardBlocked = [...belgiumCardBlocked];

const t4eCardBlocked = [...belgiumCardBlocked];

const autopayCardBlocked = [...belgiumCardBlocked];

const autopayCardExpired = [...evignetteCardExpired];

const swizCardExpired = [...evignetteCardExpired];

const swizCardBlocked = [...belgiumCardBlocked];

const fdeCardBlocked = [...belgiumCardBlocked];

const germanyCardBlocked = [...belgiumCardBlocked];

const slovakiaContractList = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.slovakiaContractList)
];

const slovakiaBlacklist = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.slovakiaBlacklist)
];

const slovakiaCardChange = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.slovakiaCardChange)
];

const slovakiaCardExpiryByFCE = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.slovakiaCardExpiryByFCE)
];

const slovakiaErrors = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.slovakiaErrors)
];

const slovakiaRegistration = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.slovakiaRegistration)
];

const slovakiaUnknownContracts = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.slovakiaUnknownContracts)
];

const slovakiaRejectedOrders = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.slovakiaRejectedOrders)
];

const slovakiaUsingBlockedCard = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.slovakiaUsingBlockedCard)
];

const slovakiaTransaction = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.slovakiaTransaction)
];

const germanyTransactionGFN = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.germanyTransactionGFN)
];

const germanyTransactionESI = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.germanyTransactionESI)
];

const germanyUninvoice = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.germanyUninvoice)
];

const t4eObuMonthlyRentalFees = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.t4eObuMonthlyRentalFees)
];

const t4eCardPayerAssociationCodes = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.t4eCardPayerAssociationCodes)
];

const swizTransaction = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.swizTransaction)
];

const belgiumCardSyncErrors = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.belgiumCardSyncErrors)
];
const belgiumTransaction = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.belgiumTransaction)
];

const belgiumContracts = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.belgiumContracts)
];

const belgiumCustomerListReport = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.belgiumCustomerListReport)
];

const belgiumObusOnBlackList = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.belgiumObusOnBlackList)
];

const belgiumUnknownContracts = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.belgiumUnknownContracts)
];

const belgiumUnknownCountries = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.belgiumUnknownCountries)
];

const telepassCardBlocked = [...belgiumCardBlocked];

const telepassErrorReportOBU = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.telepassErrorReportOBU)
];

const errorReportContract = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.errorReportContract)
];

const telepassOBUListReport = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.telepassOBUListReport)
];

const telepassObuServiceListReport = [
  ...actions,
  columnHelper.accessor('customerNumber', {
    header: 'Customer Number',
    cell: (info) => info.getValue()
  }),
  columnHelper.accessor('shellContractNumber', {
    header: 'Shell Contract Number',
    cell: (info) => info.getValue()
  }),
  columnHelper.accessor('telepassContractNumber', {
    header: 'Telepass Contract Number',
    cell: (info) => info.getValue()
  }),
  columnHelper.accessor('obuNumber', {
    header: 'OBU Number',
    cell: (info) => info.getValue()
  }),
  columnHelper.accessor('lpnCountry', {
    header: 'LPN Country',
    cell: (info) => info.getValue()
  }),
  columnHelper.accessor('lpn', {
    header: 'LPN',
    cell: (info) => info.getValue()
  }),
  columnHelper.accessor('service', {
    header: 'Service',
    cell: (info) => info.getValue()
  }),
  columnHelper.accessor('status', {
    header: 'Status',
    cell: (info) => String(info.getValue())
  }),
  columnHelper.accessor('pan', {
    header: 'PAN',
    cell: (info) => info.getValue()
  }),
  ...columnGenerator(CCO_ARRAY_LENGTH, 'ccO', 'CCO'),
  columnHelper.accessor('efcContextMark', {
    header: 'EFC Context Mark',
    cell: (info) => info.getValue()
  }),
  columnHelper.accessor('openingDate', {
    header: 'Opening Date',
    cell: (info) => info.getValue()
  }),
  columnHelper.accessor('closureDate', {
    header: 'Closure Date',
    cell: (info) => info.getValue()
  })
] as ColumnDef<ProvidersData>[];

const telepassOBUMonthlyRentalFees = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.telepassOBUMonthlyRentalFees)
];

const telepassELCONReport = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.telepassELCONReport)
] as ColumnDef<ProvidersData>[];

const telepassContractList = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.telepassContractList)
] as ColumnDef<ProvidersData>[];

const activeEvignettes = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.activeEvignettes)
];

const telepassVehicleList = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.telepassVehicleList)
];

const ukLevyCardBlocked = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.cardBlocked)
] as ColumnDef<ProvidersData>[];

const ukLevyTransaction = [
  ...actions,
  ...columnBuilder<ProvidersData>(ProviderColumns.ukLevyTransaction)
] as ColumnDef<ProvidersData>[];

export const columns = {
  autopayCardBlocked,
  autopayCardExpired,
  autoPayRegisteredVehiclesReport,
  autoPayVehicleRequestReport,
  belgiumCardBlocked,
  belgiumCardSyncErrors,
  belgiumContracts,
  belgiumCustomerListReport,
  belgiumObusOnBlackList,
  belgiumTransaction,
  belgiumUnknownContracts,
  belgiumUnknownCountries,
  belgiumCardExpired,
  telepassOBUMonitoringReport,
  errorReportContract,
  telepassCardBlocked,
  telepassCardExpired,
  telepassOBUListReport,
  telepassObuServiceListReport,
  telepassOBUMonthlyRentalFees,
  telepassErrorReportOBU,
  telepassContractList,
  telepassELCONReport,
  ukLevyCardBlocked,
  ukLevyTransaction,
  activeEvignettes,
  evignettesOpenOrders,
  telepassVehicleList,
  evignetteCardBlocked,
  evignetteCardExpired,
  ukLevyCardExpired,
  evignetteError,
  evignetteRegisteredCustomers,
  evignettesOrders,
  czechTollRegAccounts,
  czechTollRegistrationRequests,
  czechTollCardChange,
  czechTollCardExpired,
  czechTollCardBlocked,
  germanyInvoice,
  germanyUninvoice,
  germanyTransNotificationDaily,
  germanyNotificationMonthly,
  germanyTransReconciliation,
  germanyInboundOutbound,
  germanyPanIDMismatch,
  germanyUsingBlockedCard,
  germanyContractList,
  germanyContractStatus,
  germanyLimitConfirmations,
  germanyLimitConfirmationsErrors,
  germanyLimitRequests,
  germanyDeregisteredbyCustomer,
  germanyTransactionError,
  germanytransNotificationFortnight,
  germanyTransactionGFN,
  germanyTransactionESI,
  germanyCardBlocked,
  germanyCardExpired,
  slovakiaBlacklist,
  slovakiaContractList,
  slovakiaCardBlocked,
  slovakiaCardExpired,
  slovakiaCardChange,
  slovakiaErrors,
  slovakiaRegistration,
  slovakiaRejectedOrders,
  slovakiaUnknownContracts,
  slovakiaCardExpiryByFCE,
  slovakiaUsingBlockedCard,
  slovakiaTransaction,
  fdeCardExpired,
  fdeCCLReport,
  fdeCardBlocked,
  fdeVirtualCardOrder,
  fdeRegistrationReport,
  fdeTransactionReport,
  t4eCardExpired,
  t4eCardBlocked,
  t4eObuMonthlyRentalFees,
  t4eCardPayerAssociationCodes,
  swizCardExpired,
  swizCardBlocked,
  swizTransaction
};
