import { useMatches } from 'react-router-dom';
import { JSX } from 'react';

interface HandleType {
  crumb: (data?: unknown) => JSX.Element;
}

const Breadcrumb = () => {
  const matches = useMatches();

  const crumbs = matches
    .filter(
      (match) => match?.handle && Boolean((match?.handle as HandleType).crumb)
    )
    .map((match) => (match?.handle as HandleType).crumb(match.data));

  return (
    <>
      {crumbs?.map((crumb, index) => (
        <span
          key={crumb.props.children}
          data-testid={`${index}-breadCrumb`}
          className="text-sm text-shellBlack font-ShellFont"
        >
          {crumb}
        </span>
      ))}
    </>
  );
};

export default Breadcrumb;
