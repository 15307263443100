/* eslint-disable no-console */
import { createContext, useEffect, useMemo, useRef, useState } from 'react';
import axios, { isAxiosError } from 'axios';
import { baseUrl } from 'api';
import {
  fetchTokens,
  fetchWithRefreshToken,
  redirectToLogin
} from './authentication';
import useBrowserStorage from './useBrowserStorage';
import {
  AuthUrlResponse,
  IAuthContext,
  IAuthProvider,
  TTokenData,
  TTokenResponse
} from './Types';
import {
  epochAtSecondsFromNow,
  epochTimeIsPast,
  FALLBACK_EXPIRE_TIME,
  getRefreshExpiresIn
} from './timeUtils';
import { decodeJWT } from './decodeJWT';

export const AuthContext = createContext<IAuthContext>({
  token: '',
  error: null,
  loginInProgress: false
});

const TOKEN_REFRESH_INTERVAL = 10000;
const DEFAULT_SECONDS = 2;

export const AuthProvider = ({ children }: IAuthProvider) => {
  const [refreshToken, setRefreshToken] = useBrowserStorage<string | undefined>(
    'refreshToken',
    undefined
  );
  const [refreshTokenExpire, setRefreshTokenExpire] = useBrowserStorage<number>(
    'refreshTokenExpire',
    epochAtSecondsFromNow(DEFAULT_SECONDS * FALLBACK_EXPIRE_TIME)
  );
  const [token, setToken] = useBrowserStorage<string>('token', '');
  const [tokenExpire, setTokenExpire] = useBrowserStorage<number>(
    'tokenExpire',
    epochAtSecondsFromNow(FALLBACK_EXPIRE_TIME)
  );
  const [loginInProgress, setLoginInProgress] = useBrowserStorage<boolean>(
    'loginInProgress',
    false
  );
  const [refreshInProgress, setRefreshInProgress] = useBrowserStorage<boolean>(
    'refreshInProgress',
    false
  );
  const [tokenData, setTokenData] = useState<TTokenData | undefined>();
  const [error, setError] = useState<string | null>(null);

  let interval: ReturnType<typeof setInterval>;

  function clearStorage() {
    setRefreshToken(undefined);
    setToken('');
    setTokenExpire(epochAtSecondsFromNow(FALLBACK_EXPIRE_TIME));
    setRefreshTokenExpire(epochAtSecondsFromNow(FALLBACK_EXPIRE_TIME));
    setTokenData(undefined);
    setLoginInProgress(false);
  }

  function login() {
    clearStorage();
    setLoginInProgress(true);

    const authUrl = `${baseUrl}/Auth/GetUri`;

    axios
      .get<AuthUrlResponse>(authUrl)
      .then((response) => {
        const { url, codeChallenge } = response.data;

        let redirectUrl = url;

        if (
          process.env.NODE_ENV === 'development' ||
          process.env.NODE_ENV === 'test'
        ) {
          const authurl = new URL(url);
          const { origin, pathname } = authurl;
          const params = new URLSearchParams(authurl.search);
          params.set('redirect_uri', 'http://localhost:3000/');

          redirectUrl = `${origin + pathname}?${params.toString()}`;
        }

        redirectToLogin(redirectUrl, codeChallenge);
      })
      .catch(() => {
        setLoginInProgress(false);
        setError('Failed to authenticate please check after some time');
      });
  }

  function handleTokenResponse(response: TTokenResponse) {
    setToken(response.accessToken);
    setRefreshToken(response.refreshToken);
    const tokenExpiresIn = response.expiresIn ?? FALLBACK_EXPIRE_TIME;
    setTokenExpire(epochAtSecondsFromNow(tokenExpiresIn));
    const refreshTokenExpiresIn = getRefreshExpiresIn(tokenExpiresIn, response);
    setRefreshTokenExpire(epochAtSecondsFromNow(refreshTokenExpiresIn));

    try {
      setTokenData(decodeJWT(response.accessToken));
    } catch (e) {}
  }

  function handleExpiredRefreshToken(_initial = false): void {
    return login();
  }

  function checkTokensNeedRefresh(initial = false): boolean {
    if (!token) {
      return false;
    }

    // The token has not expired. Do nothing
    if (!epochTimeIsPast(tokenExpire)) {
      return false;
    }

    // Other instance (tab) is currently refreshing. This instance skip the refresh if not initial
    if (refreshInProgress && !initial) {
      return false;
    }
    return true;
  }

  const handleErrorResponse = (fetchTokenerror: unknown, initial = false) => {
    if (isAxiosError(fetchTokenerror)) {
      // If the fetch failed with status 500, assume expired refresh token
      const STATUS_CODE_BAD_REQUEST = 500;
      if (fetchTokenerror.response?.status === STATUS_CODE_BAD_REQUEST) {
        return handleExpiredRefreshToken(initial);
      }

      setError(fetchTokenerror.message);
      if (initial) {
        login();
      }
    }
    // Unknown error. Set error, and login if first page load
    else if (fetchTokenerror instanceof Error) {
      setError(fetchTokenerror.message);
      if (initial) {
        login();
      }
    }
    return fetchTokenerror;
  };

  function refreshAccessToken(initial = false): void {
    if (!checkTokensNeedRefresh(initial)) {
      return;
    }

    // The refreshToken has expired
    if (epochTimeIsPast(refreshTokenExpire)) {
      // eslint-disable-next-line consistent-return
      return handleExpiredRefreshToken(initial);
    }

    // The access_token has expired, and we have a non-expired refresh_token. Use it to refresh access_token.
    if (refreshToken) {
      setRefreshInProgress(true);
      fetchWithRefreshToken({ refreshToken })
        .then((result: TTokenResponse) => handleTokenResponse(result))
        .catch((fetchTokenerror: unknown) => {
          handleErrorResponse(fetchTokenerror, initial);
        })
        .finally(() => {
          setRefreshInProgress(false);
        });
    }
  }

  // Register the 'check for soon expiring access token' interval (Every 10 seconds)
  useEffect(() => {
    interval = setInterval(() => refreshAccessToken(), TOKEN_REFRESH_INTERVAL);
    return () => clearInterval(interval);
  }, [token, refreshToken, tokenExpire]); // Replace the interval with a new when values used inside refreshAccessToken changes

  const didFetchTokens = useRef(false);

  // Runs once on page load
  useEffect(() => {
    // The client has been redirected back from the auth endpoint with an auth code
    if (loginInProgress) {
      const urlParams = new URLSearchParams(window.location.search);

      if (!urlParams.get('code')) {
        // This should not happen. There should be a 'code' parameter in the url by now..."
        const errorDescription =
          urlParams.get('error_description') ??
          'Bad authorization state. Refreshing the page and log in again might solve the issue.';
        setError(errorDescription);
        clearStorage();
        return;
      }

      if (!didFetchTokens.current) {
        didFetchTokens.current = true;

        // Request tokens from BE
        fetchTokens()
          .then((tokens: TTokenResponse) => {
            handleTokenResponse(tokens);
            localStorage.setItem('userLoggedIn', 'true');
          })
          .catch((fetchTokenError: Error) => {
            setError(fetchTokenError.message);
          })
          .finally(() => {
            window.history.replaceState(null, '', window.location.pathname);
            setLoginInProgress(false);
          });
      }
      return;
    }

    if (!token) {
      login();
    }

    try {
      if (token) {
        setTokenData(decodeJWT(token));
      }
    } catch (e) {}
    refreshAccessToken(true); // Check if token should be updated
  }, []); // eslint-disable-line

  const providerValue = useMemo(
    () => ({
      token,
      tokenData,
      error,
      loginInProgress
    }),
    [token, tokenData, error, loginInProgress]
  );

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  );
};
