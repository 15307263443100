import SelectField from 'components/FormCmponents/SelectField';
import TextField from 'components/FormCmponents/TextField';
import { Field } from 'formik';
import TyreTypes from 'utils/StaticData/Vehicle/tyreType.json';

export const Dimensions = () => (
  <>
    <Field
      id="length"
      name="length"
      label="Length(mm)"
      labelPosition="left"
      component={TextField}
      placeholder="Type here"
    />
    <Field
      id="height"
      name="height"
      label="Height(mm)"
      labelPosition="left"
      component={TextField}
      placeholder="Type here"
    />
    <Field
      id="width"
      name="width"
      label="Width(mm)"
      labelPosition="left"
      component={TextField}
      placeholder="Type here"
    />
    <Field
      id="firstAxleHeight"
      name="firstAxleHeight"
      label="First Axle Height(mm)"
      labelPosition="left"
      component={TextField}
      placeholder="Type here"
    />
    <Field
      id="tyreTypeID"
      name="tyreTypeID"
      label="Tyre type"
      labelPosition="left"
      component={SelectField}
      options={TyreTypes.map((item) => ({
        label: item.Name,
        value: item.Id
      }))}
      placeholder="Select"
    />
  </>
);
