import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api';
import { TollStatusEndpoints } from 'api/apiEndpoints';
import QueryError from 'components/QueryError';
import { ExpandableTable } from 'components/Table/ExpandableTable';
import {
  columnHelper,
  getOverviewColumns
} from 'components/Table/columns/overviewColumns';
import React from 'react';
import { STALE_TIME } from 'react-query';
import { Sizes, Text } from '@sede-x/shell-ds-react-framework';
import { Toll } from './types';

interface TollStatusProps {
  type: string;
  id: string;
}

type Identifier = {
  [key: string]: string;
};

const identifier: Identifier = {
  customer: 'customerId',
  obu: 'obuId',
  contract: 'contractId',
  vehicle: 'vehicleId',
  contact: 'contactId',
  card: 'cardId'
};

async function fetchTollStatus(type: string, id: string) {
  const { data } = await customerInstance.post(TollStatusEndpoints[type], {
    [identifier[type]]: id
  });
  return data;
}

const getIcon = (status: string) => {
  switch (status) {
    case 'Active':
      return 'bg-shellGreen';
    case 'Not Registered':
      return 'bg-shellBGGrey';
    case 'Deregistered':
    case 'Blocked':
      return 'bg-shellRed';
    case 'Inactive':
      return 'bg-shellYellow';
    default:
      return 'bg-shellYellow';
  }
};

const legents = [
  columnHelper.display({
    id: 'actions',
    size: 20,
    cell: ({ row }) => (
      <div
        className={`rounded-full h-3 w-3 ${getIcon(
          (row.original as Toll).status
        )}`}
      />
    ),
    header: ''
  })
];

const TollStatusLegend = () => (
  <div className="flex gap-2 justify-end p-2">
    {['Blocked', 'Active', 'Not Registered', 'Inactive', 'Deregistered'].map(
      (item) => (
        <div className="flex items-center gap-2" key={item}>
          <div className={`rounded-full h-3 w-3 ${getIcon(item)}`} />
          <Text bold size={Sizes.Small}>
            {item}
          </Text>
        </div>
      )
    )}
  </div>
);

const TollStatus: React.FC<TollStatusProps> = ({ type, id }) => {
  const { isError, isLoading, data } = useQuery({
    queryKey: ['toll-Status', id, type],
    queryFn: () => fetchTollStatus(type, id),
    staleTime: STALE_TIME,
    refetchOnWindowFocus: false,
    enabled: !!id
  });

  const { data: tollStatusData } = data || {};
  return (
    <div className=" bg-white p-4 h-full">
      <div className="flex  flex-col h-full">
        <QueryError isLoading={isLoading} isError={isError}>
          <TollStatusLegend />
          <div className="bg-[#F0F0F0] h-full overflow-y-auto flex flex-col">
            <ExpandableTable
              data={tollStatusData}
              columns={[...legents, ...getOverviewColumns('TollStatus')]}
              columnSelection={false}
              exportEnabled={false}
            />
          </div>
        </QueryError>
      </div>
    </div>
  );
};

export default TollStatus;
