import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api';
import SelectField from 'components/FormCmponents/SelectField';
import { Field, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { STALE_TIME } from 'react-query';
import CheckBox from 'components/FormCmponents/CheckBox';
import QueryError from 'components/QueryError';
import { customerEndPoints } from 'api/apiEndpoints';
import { NewOBU } from '../../types';

interface SubscriptionCheckBox {
  propertyName: string;
  checked: boolean;
  enabled: boolean;
}

export const subscriptionFields = [
  'Viapass',
  'Herrentunnel',
  'EasyGo',
  'Portugal',
  'Spain',
  'Liefkenshok',
  'Germany',
  'Italy',
  'GeofencingNotification',
  'France'
];

async function fetchSubscriptionComponent(
  contractId: string,
  subscriptionType?: string
) {
  const { data } = await customerInstance.post(
    customerEndPoints.obuGetFranceSubscriptionCheckboxes,
    {
      subscriptionType,
      contractId
    }
  );

  return data;
}

const FranceSelection: React.FC = () => {
  const { values, setFieldValue } = useFormikContext<NewOBU>();

  const {
    data: subscriptionComponentData,
    isError,
    isLoading
  } = useQuery({
    queryKey: [
      'subscriptionComponentData',
      values.subscriptionType,
      values.contractID
    ],
    queryFn: () =>
      fetchSubscriptionComponent(
        values.contractID ?? '',
        values.subscriptionType
      ),
    staleTime: STALE_TIME
  });

  const { data: subscriptionTypesCheckBoxes } = subscriptionComponentData ?? {};

  useEffect(() => {
    if (subscriptionTypesCheckBoxes) {
      (
        subscriptionTypesCheckBoxes?.oBUGetFranceSubscriptionTypeCheckBoxDto as SubscriptionCheckBox[]
      )?.forEach((item) => {
        setFieldValue(item.propertyName, item.checked);
      });
    }
  }, [subscriptionComponentData]);

  return (
    <div className="flex flex-col gap-5">
      <Field
        id="subscriptionType"
        name="subscriptionType"
        label="Subscription Type"
        labelPosition="left"
        component={SelectField}
        placeholder="Select"
        dataEndPoint={customerEndPoints.obuGetFranceSubscriptions}
        dataField="value"
        dataLabelField="description"
        onOption
        isCustomerInstance
        required
      />
      <div>
        <QueryError isLoading={isLoading} isError={isError}>
          {subscriptionTypesCheckBoxes &&
            (
              subscriptionTypesCheckBoxes?.oBUGetFranceSubscriptionTypeCheckBoxDto as SubscriptionCheckBox[]
            )?.map((item) => (
              <Field
                key={item.propertyName}
                id={item.propertyName}
                name={item.propertyName}
                label={item.propertyName}
                size="small"
                labelPosition="left"
                component={CheckBox}
                checked={values[item.propertyName as keyof NewOBU]}
                disabled={!item.enabled}
              />
            ))}
        </QueryError>
      </div>
    </div>
  );
};

export default FranceSelection;
