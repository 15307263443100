import { DEFAULT_ITEMS_COUNT } from 'constants/table';
import { useEffect, useState } from 'react';

export function useSdsPagination(
  onChange?: (page: number, pageSize: number) => void
): {
  page: number;
  pageSize: number;
  onPaginationChange: (newPage: number, newPageSize: number) => void;
  resetPagination: () => void;
} {
  const [page, setPage] = useState<string>();
  useEffect(() => {
    if (page === undefined) {
      setPage('1');
    }
  }, [page, setPage]);
  const parsedPage = page === undefined ? 1 : Number.parseInt(page, 10);

  const [pageSize, setPageSize] = useState<string>();
  useEffect(() => {
    if (pageSize === undefined) {
      setPageSize(DEFAULT_ITEMS_COUNT.toString());
    }
  }, [pageSize, setPageSize]);
  const parsedPageSize =
    pageSize === undefined
      ? DEFAULT_ITEMS_COUNT
      : Number.parseInt(pageSize, 10);

  return {
    page: parsedPage,
    pageSize: parsedPageSize,
    onPaginationChange: (newPage: number, newPageSize: number): void => {
      const newPageNumber =
        String(newPageSize) !== pageSize ? String(1) : String(newPage);
      setPage(newPageNumber);
      setPageSize(String(newPageSize));
      const parsedPageNumber = Number.parseInt(newPageNumber, 10);
      onChange?.(parsedPageNumber, newPageSize);
    },
    resetPagination: () => {
      setPage(String(1));
      setPageSize(String(DEFAULT_ITEMS_COUNT));
    }
  };
}
