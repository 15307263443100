import { Button, Icons } from '@sede-x/shell-ds-react-framework';
import { saveAs } from 'file-saver';
import { axiosInstance } from 'api';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { actionButtonEndpoints } from 'api/apiEndpoints';
import CzechCardChange from './CzechCardChange';
import CardChangeUpload from './CardChangeUpload';
import SlovakiaCardChange from './SlovakiaCardChange';

const CardChange = ({
  selectedRows,
  onSuccess,
  menu
}: {
  selectedRows: Array<unknown>;
  onSuccess?: () => void;
  menu: string;
}) => {
  const { errorDialog } = useConfirmDialogs();
  const handleDownloadTemplate = () => {
    let api = actionButtonEndpoints.czechTollTemplateDownload;
    let fileName = 'CzechTollCardChangeTemplate.xlsx';
    if (menu === 'slovakiaExpiredCards') {
      api = actionButtonEndpoints.slovakiaTemplateDownload;
      fileName = 'SlovakiaCardChangeTemplate.xlsx';
    }
    axiosInstance
      .get(api, {
        responseType: 'arraybuffer'
      })
      .then((res) => {
        const file = new Blob([res.data], {
          type: 'application/octet-stream;'
        });

        saveAs(file, fileName);
      })
      .catch(() => {
        errorDialog(
          '',
          'Your download template request has been failed. Please try again.'
        );
      });
  };

  return (
    <div
      className="flex justify-end gap-3"
      data-testid="cardchange-ActionSection"
    >
      {menu === 'czechTollExpiredCards' ? (
        <CzechCardChange selectedRows={selectedRows} onSuccess={onSuccess} />
      ) : (
        <SlovakiaCardChange selectedRows={selectedRows} onSuccess={onSuccess} />
      )}
      <Button
        data-testid="downloadTmplt"
        id="downloadTmplt"
        icon={<Icons.Download />}
        onClick={handleDownloadTemplate}
        variant="outlined"
        size="small"
      >
        Download Template
      </Button>
      <CardChangeUpload onSuccess={onSuccess} menu={menu} />
    </div>
  );
};

export default CardChange;
