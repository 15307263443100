import { Select } from '@sede-x/shell-ds-react-framework';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useStaticData } from '../../hooks/useStaticData';
import { SelectProps } from './types';

interface ValueMap {
  [key: string]: {
    label: string;
    value: string;
  };
}

const StyledSelect = styled(Select)`
  svg {
    display: unset;
    vertical-align: unset;
  }
`;

function SDSSelect({
  label,
  name,
  options,
  placeHolder,
  onChange,
  onOption = false,
  value,
  dataEndPoint,
  dataField = 'value',
  dataLabelField = 'label',
  dataIdentifier,
  invalid,
  handleBlur
}: Readonly<SelectProps>) {
  const [menuOptions, setMenuOptions] = useState<SelectProps['options']>();
  const [valueMap, setValueMap] = useState<ValueMap>({});

  const { data, isSuccess, isLoading } = useStaticData(
    dataEndPoint as string,
    dataEndPoint as string,
    onOption
  );

  useEffect(() => {
    if (isSuccess) {
      const results: SelectProps['options'] = [];
      const obj: ValueMap = {};
      data.data.forEach((dataObj: { [x: string]: string }) => {
        if (dataObj[dataField] && !obj[dataObj[dataIdentifier || dataField]]) {
          results.push({
            value: dataIdentifier
              ? dataObj[dataIdentifier]
              : dataObj[dataField],
            label: dataObj[dataLabelField]
          });
          obj[dataObj[dataIdentifier ?? dataField]] = {
            value: dataObj[dataField],
            label: dataObj[dataLabelField]
          };
        }
      });

      // Inside the useEffect block:
      const updatedOptions = processOptions(obj);
      setValueMap(obj);
      setMenuOptions([...(updatedOptions ?? []), ...results]);
    }
  }, [data, isSuccess, dataField, dataLabelField, dataIdentifier]);

  function processOptions(obj: ValueMap): SelectProps['options'] {
    const results: SelectProps['options'] = [];
    if (options?.length) {
      options.forEach((option) => {
        if (option.value && !obj[option.value]) {
          results.push(option);
          obj[option.value] = {
            value: option.value as string,
            label: option.label as string
          };
        }
      });
    }
    return results;
  }

  useEffect(() => {
    if (!onOption) {
      setMenuOptions(options);
    }
  }, []);

  const handleChange = (eventValue: string) => {
    const selectValue = onOption ? valueMap[eventValue]?.value : eventValue;
    onChange(selectValue);
  };

  return (
    <StyledSelect
      id={name}
      options={menuOptions}
      placeholder={placeHolder}
      size="small"
      value={value}
      aria-label={name}
      virtual={false}
      onChange={handleChange}
      data-testid={`${label}-select`}
      optionFilterProp="label"
      optionLabelProp="label"
      invalid={invalid}
      onBlur={handleBlur}
      loading={isLoading && onOption}
    />
  );
}

export default SDSSelect;
