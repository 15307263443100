import { useQuery } from '@tanstack/react-query';
import { Row } from '@tanstack/react-table';
import { customerInstance } from 'api';
import { customerEndPoints } from 'api/apiEndpoints';
import QueryError from 'components/QueryError';
import { ExpandableTable } from 'components/Table/ExpandableTable';
import {
  columns,
  detailAction
} from 'components/Table/columns/overviewColumns';
import { useSdsPagination } from 'hooks/use-pagination';
import { useState } from 'react';
import { Drawer } from 'components/Drawer/Drawer';
import { STALE_TIME } from 'react-query';
import { Text } from '@sede-x/shell-ds-react-framework';
import { VehicleDetails } from '../VehicleDetails/VehicleDetails';
import { Vehicle } from '../types';
import { SEARCH_VEHICLE_QUERY_KEY } from '../utils/constants';
import { AddVehicle } from '../AddVehicle/AddVehicle';

const DEFAULT_PAGE_SIZE = 20;

const VehicleList = ({ customerId }: { customerId: string }) => {
  async function fetchVehicleByCustomer(pageNumber: number, size: number) {
    const { data } = await customerInstance.post(
      customerEndPoints.vehicleByCustomer,
      {
        pageNumber,
        pageSize: size,
        customerId
      }
    );
    return data;
  }
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [openDetails, setOpenDetails] = useState(false);
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null);

  const {
    data: vehiclesData,
    isLoading,
    isError
  } = useQuery({
    queryKey: [SEARCH_VEHICLE_QUERY_KEY, page, pageSize, customerId],
    queryFn: () => fetchVehicleByCustomer(page, pageSize),
    staleTime: STALE_TIME,
    keepPreviousData: true,
    refetchOnWindowFocus: 'always'
  });

  const paginationProps = useSdsPagination((pageNumber, newPageSize) => {
    setPage(pageNumber);
    setPageSize(newPageSize);
  });

  const handleClickDetails = (row: Row<unknown>) => {
    setSelectedVehicle(row.original as Vehicle);
    setOpenDetails(true);
  };

  const handleOnClose = () => {
    setOpenDetails(false);
    setSelectedVehicle(null);
  };

  const detailIcons = detailAction(handleClickDetails);

  return (
    <div
      className="flex flex-col grow overflow-y-auto gap-2 pb-3 bg-shellExtraPaleGrey2"
      data-testid="vehicle-list"
    >
      <div className=" bg-white p-5">
        <div className="flex flex-row justify-between items-center">
          <Text prominence="strong" size="medium" type="p" bold>
            Vehicle List
          </Text>
          <div className="flex	space-x-4 ">
            <AddVehicle customerId={customerId} />
          </div>
        </div>
      </div>
      <QueryError isLoading={isLoading} isError={isError}>
        <ExpandableTable
          stickyColumns={['expandAction']}
          columnSelection={false}
          columns={[...detailIcons, ...columns.Vehicle]}
          exportEnabled={false}
          paginationData={{ ...paginationProps, total: vehiclesData?.count }}
          data={vehiclesData?.data ?? []}
          selectedRows={{
            [selectedVehicle?.vehicleID as string]: true
          }}
          getRowId={(row) => (row as Vehicle).vehicleID as string}
        />
      </QueryError>
      <Drawer
        open={openDetails}
        header="Vehicle Overview"
        mask={false}
        onClose={handleOnClose}
        width="70%"
        bodyPadding={false}
        sticky
      >
        <div className="flex grow h-full">
          <VehicleDetails
            vehicleId={selectedVehicle?.vehicleID ?? ''}
            onSuccess={handleOnClose}
          />
        </div>
      </Drawer>
    </div>
  );
};

export default VehicleList;
