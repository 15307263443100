import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api';
import { customerEndPoints } from 'api/apiEndpoints';
import QueryError from 'components/QueryError';
import Table from 'components/Table/Table';
import { getOverviewColumns } from 'components/Table/columns/overviewColumns';
import React, { useEffect, useState } from 'react';
import { STALE_TIME } from 'react-query';
import { ColumnDef } from '@tanstack/react-table';
import { useFormikContext } from 'formik';
import { Toll } from './types';
import { NewOBU } from '../../types';

async function fetchTollList() {
  const { data } = await customerInstance.post(
    customerEndPoints.getTollsForObu
  );
  return data;
}

interface TollSelectionProps {
  customerId: string;
}

const TollSelection: React.FC<TollSelectionProps> = ({ customerId }) => {
  const [selectedRows, setSelectedRows] = useState<Toll[]>([]);

  const { setFieldValue } = useFormikContext<NewOBU>();

  const {
    isError,
    data: tollList,
    isLoading
  } = useQuery({
    queryKey: ['obu-toll-list', customerId],
    queryFn: () => fetchTollList(),
    staleTime: STALE_TIME
  });

  useEffect(() => {
    if (selectedRows.length) {
      const [selectedToll] = selectedRows;
      setFieldValue('tollID', selectedToll.tollID);
      setFieldValue('tollName', selectedToll.toll);
    }
  }, [selectedRows]);

  return (
    <QueryError isLoading={isLoading} isError={isError}>
      <Table<Toll>
        data={tollList?.data ?? []}
        columns={getOverviewColumns('obuTollList') as ColumnDef<Toll>[]}
        columnSelection={false}
        exportEnabled={false}
        enableMultiRowSelection={false}
        onSelectedRowsChange={setSelectedRows}
      />
    </QueryError>
  );
};

export default TollSelection;
