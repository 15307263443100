import axios from 'axios';
import { baseUrl } from 'api';
import {
  TTokenResponse,
  TTokenRequest,
  TTokenRequestWithCodeAndVerifier,
  TTokenRequestForRefresh
} from './Types';

const codeChallengeStorageKey = 'code_Challenge';

export async function redirectToLogin(
  endpoint: string,
  codeChallenge: string
): Promise<void> {
  sessionStorage.setItem(codeChallengeStorageKey, codeChallenge);
  return window.location.replace(endpoint);
}

function isTokenResponse(body: unknown): body is TTokenResponse {
  return (body as TTokenResponse).accessToken !== undefined;
}

function postTokenRequest(
  tokenRequest: TTokenRequest
): Promise<TTokenResponse> {
  let endpoint = '/Auth/GetToken';

  if ('refreshToken' in tokenRequest) {
    endpoint = '/Auth/GetRefreshToken';
  } else if (
    process.env.NODE_ENV === 'development' ||
    process.env.NODE_ENV === 'test'
  ) {
    tokenRequest.isLocal = true;
  }
  const refreshUrl = `${baseUrl}${endpoint}`;

  return axios.post(`${refreshUrl}`, tokenRequest).then((response) => {
    if (isTokenResponse(response.data)) {
      return response.data;
    }
    throw Error(response.data);
  });
}

export const fetchTokens = (): Promise<TTokenResponse> => {
  const urlParams = new URLSearchParams(window.location.search);
  const authCode = urlParams.get('code');
  const codeChallenge = window.sessionStorage.getItem(codeChallengeStorageKey);

  if (!authCode) {
    throw Error(
      "Parameter 'code' not found in URL. \nHas authentication taken place?"
    );
  }
  if (!codeChallenge) {
    throw Error(
      "Can't get tokens without the Codechallenge. \nHas authentication taken place?"
    );
  }

  const tokenRequest: TTokenRequestWithCodeAndVerifier = {
    code: authCode,
    challenge: codeChallenge
  };

  return postTokenRequest(tokenRequest);
};

export const fetchWithRefreshToken = (props: {
  refreshToken: string;
}): Promise<TTokenResponse> => {
  const { refreshToken } = props;
  const refreshRequest: TTokenRequestForRefresh = {
    refreshToken
  };
  return postTokenRequest(refreshRequest);
};
