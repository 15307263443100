import { ReactNode, createContext, useMemo, useState } from 'react';
import { IConfirmConfig, IConfirmContext } from 'utils/Interfaces/confirm';

export const confirmInitialValue: IConfirmContext = [
  {
    isOpen: false,
    title: '',
    description: '',
    proceed: () => {},
    cancel: () => {}
  },
  () => {}
];

export const ConfirmContext =
  createContext<IConfirmContext>(confirmInitialValue);

const ConfirmContextProvider = ({ children }: { children: ReactNode }) => {
  const [confirm, setConfirm] = useState<IConfirmConfig>(
    confirmInitialValue[0]
  );

  const providerValue: IConfirmContext = useMemo(
    () => [confirm, setConfirm],
    [confirm, setConfirm]
  );

  return (
    <ConfirmContext.Provider value={providerValue}>
      {children}
    </ConfirmContext.Provider>
  );
};

export default ConfirmContextProvider;
