import { FormField } from '@sede-x/shell-ds-react-framework';
import { Label } from 'components/Label/Label';
import React from 'react';

type FormLabelProps = {
  labelPosition?: string;
  label?: string;
  children: React.ReactNode;
};

const FormLabel: React.FC<FormLabelProps> = ({
  labelPosition,
  label,
  children,
  ...rest
}) => {
  if (labelPosition === 'left') {
    return (
      <div className="grid gap-1 w-full  h-full grid-cols-1 md:grid-cols-3 lg:grid-cols-3  items-center p-2">
        <div>
          <Label textWrap={false} text={label ?? ''} {...rest} />
        </div>
        <div className="col-span-2">
          <div>{children}</div>
        </div>
      </div>
    );
  }
  return (
    <FormField size="small" {...rest} label={label}>
      {children}
    </FormField>
  );
};

export default FormLabel;
