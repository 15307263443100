import { Icons } from '@sede-x/shell-ds-react-framework';

export const customerMenu = [
  {
    key: 'customer',
    label: 'Customer Data',
    itemIcon: <Icons.PersonOutlined />
  },
  {
    key: 'additionalAccount',
    label: 'Additional Accounts',
    itemIcon: <Icons.PersonGear />
  },

  {
    key: 'addresses',
    label: 'Addresses',
    itemIcon: <Icons.Globe />
  },
  {
    key: 'contacts',
    label: 'Contacts',
    itemIcon: <Icons.Mobile />
  },
  {
    key: 'cards',
    label: 'Cards',
    itemIcon: <Icons.CreditCard />
  },
  {
    key: 'vehicles',
    label: 'Vehicles',
    itemIcon: <Icons.Truck />
  },
  {
    key: 'obus',
    label: 'OBU',
    itemIcon: <Icons.Sensor />
  },
  {
    key: 'contracts',
    label: 'Contracts',
    itemIcon: <Icons.FileText />
  }
];
