import { AuthContext } from 'auth/AuthContext';
import { useContext } from 'react';
import { IAuthContext } from 'auth/Types';
import { Loading, Pecten } from '@sede-x/shell-ds-react-framework';
import { useStaticData } from 'hooks/useStaticData';
import Navigation from './navigation/Navigation';

function App() {
  const { error, tokenData, loginInProgress } =
    useContext<IAuthContext>(AuthContext);
  useStaticData('userroles', 'Auth/userroles', !!tokenData?.mail, null, {
    retryOnMount: false
  });

  const shouldUserLogin = () => {
    if (localStorage.getItem('userLoggedIn') === 'true') {
      return false;
    }
    return true;
  };

  const renderApp = () => {
    if (shouldUserLogin() && loginInProgress) {
      return (
        <div className="bg-white h-screen">
          <div className="flex justify-center flex-col items-center gap-14 h-screen">
            <div>
              <div className="flex justify-center">
                <Pecten size="large" />
              </div>
              <div className="font-ShellFont text-xl font-normal">
                Welcome to e-Toll Manager
              </div>
              <div className="flex justify-center">
                <Loading type="default" size="medium" />
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (error) {
      return (
        <div
          className="flex justify-center items-center h-screen"
          aria-label="error"
          data-testid="error"
        >
          <h1>There was a problem. Please refresh the page</h1>
        </div>
      );
    }
    return <Navigation />;
  };
  return <>{renderApp()}</>;
}

export default App;
