import { Button, Icons, Sentiments } from '@sede-x/shell-ds-react-framework';
import { actionButtonEndpoints } from 'api/apiEndpoints';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import {
  GermanyContractDeregistered,
  GermanyTransactionError
} from 'utils/Interfaces';
import { blockRequest, deleteTransationErrorRequest } from 'utils/helpers';
import ObuReportDetails from './DetailComponents/ObuReportDetails/ObuReportDetails';
import AddException from './DetailComponents/AddExceptionAction/AddException';
import CardChange from './DetailComponents/CardChange';
import OrderDetails from './DetailComponents/OrderDetails/OrderDetails';
import AssignCustomer from './DetailComponents/UnknownContracts/AssignCustomer';
import AssignGFN from './DetailComponents/UnknownContracts/AssignGFN';

function ActionSection<TData>({
  menu,
  selectedRows,
  onSuccess,
  tollId
}: Readonly<{
  menu: string;
  selectedRows: Array<TData>;
  onSuccess?: () => void;
  tollId?: string;
}>) {
  const { errorDialog, successDialog, confirmCloseDialog } =
    useConfirmDialogs();
  const selectedRowsLength = selectedRows.length;

  const handleDelete = async () => {
    const isConfirmed = await confirmCloseDialog(
      selectedRowsLength > 1
        ? 'Are you sure you want to remove these records?'
        : 'Are you sure you want to remove this record',
      selectedRowsLength > 1 ? 'Remove the records' : 'Remove the record'
    );
    if (isConfirmed) {
      const transactionIDs = selectedRows.map(
        (row) => (row as GermanyTransactionError).detailID
      );
      const requestPayload = {
        detailID: transactionIDs
      };
      deleteTransationErrorRequest(actionButtonEndpoints[menu], requestPayload)
        .then((_response) => {
          successDialog(
            '',
            selectedRowsLength > 1
              ? 'Records Deleted successfully'
              : 'Record Deleted successfully'
          );
          onSuccess?.();
        })
        .catch(() => {
          errorDialog('', 'Your request has been failed. Please try again.');
        });
    }
  };

  const handleBlock = async () => {
    const isConfirmed = await confirmCloseDialog(
      selectedRowsLength > 1
        ? 'Are you sure you want to Block these records?'
        : 'Are you sure you want to Block this record?',
      selectedRowsLength > 1 ? 'Block the records' : 'Block the record'
    );
    if (isConfirmed) {
      const contractIDs = selectedRows.map(
        (row) => (row as GermanyContractDeregistered).contractId
      );
      const requestPayload = {
        contractId: contractIDs
      };
      blockRequest(actionButtonEndpoints[menu], requestPayload)
        .then((_response) => {
          successDialog(
            '',
            selectedRowsLength > 1
              ? 'Records updated successfully'
              : 'Record updated successfully'
          );
          onSuccess?.();
        })
        .catch(() => {
          errorDialog('', 'Your request has been failed. Please try again.');
        });
    }
  };

  switch (menu) {
    case 'germanyTransactionError':
      return (
        <div className="bg-white p-3 text-right">
          <Button
            data-testid="deleteaction"
            disabled={!selectedRows.length}
            id="deletetransactionbtn"
            sentiment={Sentiments.Negative}
            icon={<Icons.Cross />}
            variant="outlined"
            size="small"
            onClick={handleDelete}
          >
            Delete selected
          </Button>
        </div>
      );
    case 'germanyContractDeregistered':
      return (
        <div className="bg-white p-3 text-right">
          <Button
            data-testid="blockaction"
            disabled={!selectedRows.length}
            id="blockbtn"
            sentiment={Sentiments.Negative}
            icon={<Icons.Cross />}
            variant="outlined"
            size="small"
            onClick={handleBlock}
          >
            Block selected
          </Button>
        </div>
      );
    case 'belgiumObusOnBlackList':
      return (
        <div className="bg-white p-3 text-right">
          <ObuReportDetails selectedRows={selectedRows} onSuccess={onSuccess} />
        </div>
      );
    case 't4eTransferObuExceptions':
      return (
        <div className="bg-white p-3 text-right">
          <AddException onSuccess={onSuccess} />
        </div>
      );
    case 'czechTollExpiredCards':
    case 'slovakiaExpiredCards':
      return (
        <div className="bg-white p-3 text-right">
          <CardChange
            selectedRows={selectedRows}
            onSuccess={onSuccess}
            menu={menu}
          />
        </div>
      );
    case 'evignetteRemove':
      return (
        <div className="bg-white p-3 text-right">
          <OrderDetails
            selectedRows={selectedRows}
            onSuccess={onSuccess}
            tollId={tollId}
          />
        </div>
      );
    case 'czechTollUnknownContracts':
      return (
        <div className="bg-white p-3 text-right">
          <AssignGFN selectedRows={selectedRows} onSuccess={onSuccess} />
        </div>
      );

    case 'slovakiaActionsUnknownContracts':
      return (
        <div className="bg-white p-3 text-right">
          <AssignCustomer onSuccess={onSuccess} selectedRows={selectedRows} />
        </div>
      );

    default:
      return null;
  }
}

export default ActionSection;
