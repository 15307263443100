import {
  Button,
  Icons,
  ModalAction,
  TextInput
} from '@sede-x/shell-ds-react-framework';
import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api';
import { STALE_TIME } from 'react-query';
import QueryError from 'components/QueryError';
import { getOverviewColumns } from 'components/Table/columns/overviewColumns';
import Table from 'components/Table/Table';
import { ZipCode } from 'utils/Interfaces/StaticData';
import { useFormikContext } from 'formik';
import { createMap } from 'utils/helpers';
import { useSdsPagination } from 'hooks/use-pagination';
import { StyledModal } from './styles';
import { Contact } from '../../types';

interface ZipCodeMap {
  [key: string]: ZipCode;
}

async function fetchDHLZIPCodes(
  countryId: string,
  pageNumber: number,
  pageSize: number
) {
  return customerInstance.post('contact/search-Dhl-zip-code', {
    countryId,
    pageNumber,
    pageSize
  });
}

const DEFAULT_PAGE_SIZE = 20;
const ZipCodePicker: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<unknown[]>([]);
  const [zipCodeMap, setZipCodeMap] = useState<ZipCodeMap>();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const { values, setFieldValue } = useFormikContext<Contact>();

  const { isError, data, isLoading } = useQuery({
    queryKey: ['zip-code', values.countryTypeID, pageNumber, pageSize],
    queryFn: () =>
      fetchDHLZIPCodes(values.countryTypeID ?? '', pageNumber, pageSize).then(
        (res) => res.data
      ),
    enabled: !!values.countryTypeID,
    staleTime: STALE_TIME
  });

  const handleChangePagination = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
  };

  const paginationProps = useSdsPagination(handleChangePagination);
  const { resetPagination } = paginationProps;

  const handleOnClose = () => {
    setOpen(false);
    setSelectedRows([]);
    resetPagination();
  };

  const { data: zipCodeData, count } = data || {};

  useEffect(() => {
    if (zipCodeData?.length) {
      const map = createMap(zipCodeData, 'zipCodeId') as unknown as ZipCodeMap;
      const existingKeys = Object.keys(zipCodeMap ?? {});
      for (const key in map) {
        if (!existingKeys.includes(key)) {
          setZipCodeMap((prev) => ({
            ...prev,
            [key]: map[key]
          }));
        }
      }
    }
  }, [zipCodeData]);

  const [selectedZipCode] = selectedRows as ZipCode[];

  const actions = [
    {
      label: 'Cancel',
      action: handleOnClose,
      props: {
        variant: 'outlined',
        iconPosition: 'left',
        size: 'small',
        hidden: isError || isLoading
      }
    },
    {
      label: 'Ok',
      action: async () => {
        setFieldValue('dhlZipCodeId', selectedZipCode?.zipCodeId);
        handleOnClose();
      },
      props: {
        variant: 'filled',
        iconPosition: 'left',
        size: 'small',
        hidden: isError || isLoading,
        disabled: !selectedRows.length,
        'data-testid': `zipcode-assign-btn`
      }
    }
  ];

  const zipCodeInfo = zipCodeMap?.[values.dhlZipCodeId ?? ''];
  const defaultZipInfo = values.dhlZipCodeId
    ? `${values.dhlZipCountry ?? ''}, ${values.dhlZipCity ?? ''}, ${
        values.dhlZipCode
      }`
    : '';

  return (
    <>
      <div className="flex gap-2 items-center">
        <TextInput
          readOnly
          disabled={!values.countryTypeID}
          value={
            zipCodeInfo
              ? `${zipCodeInfo.country},${zipCodeInfo.city}, ${zipCodeInfo.zipCode}`
              : defaultZipInfo
          }
          clearable
          onClear={() => setFieldValue('dhlZipCodeId', null)}
          data-testid="zipcode-input"
        />
        <Button
          icon={<Icons.Search />}
          size="small"
          onClick={() => setOpen(true)}
          iconOnly
          disabled={!values.countryTypeID}
          data-testid="zipcode-search-btn"
        />
      </div>
      {open && (
        <StyledModal
          width="80%"
          contentScrollable
          zIndex={3}
          open={open}
          onClose={handleOnClose}
          actions={actions as ModalAction[]}
          title="DHL Zip Code Search"
          prefixCls="ui-refresh-select"
          maskClosable={false}
        >
          <div className="flex flex-col h-full overflow-auto">
            <QueryError isError={isError} isLoading={isLoading}>
              <Table
                columns={getOverviewColumns('zipCode')}
                data={zipCodeData || []}
                columnSelection={false}
                exportEnabled={false}
                enableMultiRowSelection={false}
                onSelectedRowsChange={setSelectedRows}
                paginationData={{ ...paginationProps, total: count }}
              />
            </QueryError>
          </div>
        </StyledModal>
      )}
    </>
  );
};

export default ZipCodePicker;
