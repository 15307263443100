import { Tabs } from '@sede-x/shell-ds-react-framework';
import React from 'react';
import styled from 'styled-components';
import TollStatus from './TollStatus/TollStatus';
import Comments from './Comments/Comments';
import Logs from './Logs/Logs';

const StyledTabs = styled(Tabs)`
  flex-grow: 1;
  height: 100%;

  .shell-tabs-nav {
    .shell-tabs-tab-btn {
      font-weight: 600;
    }
  }

  .shell-tabs-content-holder {
    background-color: transparent;
    overflow: auto;
  }
  .shell-tabs-nav-list {
    gap: 10px;
  }
`;

type TollInformationProps = {
  type: string;
  id: string;
};

const _tempTab = [
  {
    label: 'Documents',
    key: 'documents',
    children: <div>Documents</div>,
    supportedTypes: [
      'customer',
      'contact',
      'vehicle',
      'obu',
      'contract',
      'address',
      'card'
    ]
  },
  {
    label: 'Toll Specific',
    key: 'tollSpecific',
    children: <div>Toll specific</div>,
    supportedTypes: ['customer', 'contact', 'vehicle', 'obu', 'contract']
  }
];

const TollInformation: React.FC<TollInformationProps> = ({ type, id }) => {
  const items = [
    {
      label: 'Toll Status',
      key: 'tollStatus',
      children: <TollStatus type={type} id={id} />,
      supportedTypes: [
        'customer',
        'contact',
        'vehicle',
        'obu',
        'contract',
        'card'
      ]
    },
    {
      label: 'Log',
      key: 'log',
      children: <Logs type={type} id={id} />,
      supportedTypes: [
        'customer',
        'contact',
        'vehicle',
        'obu',
        'contract',
        'address',
        'card'
      ]
    },
    {
      label: 'Comments',
      key: 'comments',
      children: <Comments type={type} id={id} />,
      supportedTypes: [
        'customer',
        'contact',
        'vehicle',
        'obu',
        'contract',
        'address',
        'card'
      ]
    }
  ];
  return (
    <div
      className="bg-shellExtraPaleGrey2 w-full h-full"
      data-testid="toll-info"
    >
      <StyledTabs
        items={items.filter((item) => item.supportedTypes.includes(type))}
      />
    </div>
  );
};

export default TollInformation;
