import { TextInput } from '@sede-x/shell-ds-react-framework';
import { FieldProps } from 'formik';
import FormLabel from './FormLabel';

const TextField = (props: FieldProps) => {
  const {
    field,
    form: { touched, errors },
    ...rest
  } = props;

  return (
    <div>
      <FormLabel {...field} {...rest}>
        <TextInput
          {...field}
          {...rest}
          invalid={!!(touched[field.name] && errors[field.name])}
          data-testid={`${field.name}-field`}
          autoComplete="off"
        />
        {touched[field.name] && errors[field.name] && (
          <div className="text-red-500 text-sm">
            {errors[field.name] as string}
          </div>
        )}
      </FormLabel>
    </div>
  );
};

export default TextField;
