import React from 'react';
import { Card } from '@sede-x/shell-ds-react-framework';
import { Field, useFormikContext } from 'formik';
import TextField from 'components/FormCmponents/TextField';
import { Contract } from '../types';
import T4ERentalFee from './T4ERentalFee';
import TelepassRentalFee from './TelepassRentalFee';

interface ContractFormProps {
  contractData?: Contract;
}
const ContractForm: React.FC<ContractFormProps> = ({ contractData }) => {
  const { values } = useFormikContext<Contract>();

  return (
    <div className="flex flex-col gap-3">
      <Card header={{ title: 'Details' }}>
        <div className="grid grid-cols-1 md:grid-cols-2  gap-3">
          <Field
            id="tollName"
            name="tollName"
            placeholder="Type here"
            label="Toll"
            labelPosition="left"
            component={TextField}
            readOnly
          />
          <Field
            id="categoryName"
            name="categoryName"
            placeholder="Type here"
            label="Contract Type"
            labelPosition="left"
            component={TextField}
            readOnly
          />
          <Field
            id="contractNumber"
            name="contractNumber"
            placeholder="Type here"
            label="Contract Number"
            labelPosition="left"
            component={TextField}
            readOnly
          />
          <Field
            id="partnerContractNumber"
            name="partnerContractNumber"
            placeholder="Type here"
            label="Partner Contract Number"
            labelPosition="left"
            component={TextField}
            readOnly
          />
          <Field
            id="contractCardNumber"
            name="contractCardNumber"
            placeholder="Type here"
            label="Contract Card Number"
            labelPosition="left"
            component={TextField}
            readOnly
          />
          <Field
            id="partnerContractStatusLongName"
            name="partnerContractStatusLongName"
            placeholder="Type here"
            label="Partner Contract Status"
            labelPosition="left"
            component={TextField}
            readOnly
          />
        </div>
      </Card>
      {values.tollName === 'T4E' && (
        <Card>
          <T4ERentalFee
            rentalId={values.rentalFeesId}
            contractData={contractData}
          />
        </Card>
      )}
      {values.tollName === 'Telepass' && (
        <Card header={{ title: 'Rental Fees' }}>
          <TelepassRentalFee />
        </Card>
      )}
    </div>
  );
};

export default ContractForm;
