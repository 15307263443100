import NumberField from 'components/FormCmponents/NumberField';
import { Field, useFormikContext } from 'formik';
import React, { ChangeEvent, KeyboardEvent } from 'react';
import { Contract } from '../types';

const blockInvalidChar = (event: KeyboardEvent<HTMLInputElement>) => {
  ['e', 'E', '+', '-', 'ArrowDown', 'ArrowUp'].includes(event.key) &&
    event.preventDefault();
};

const TelepassRentalFee: React.FC = () => {
  const { setFieldValue } = useFormikContext<Contract>();

  return (
    <>
      <Field
        id="rentalFee1"
        name="rentalFee1"
        placeholder="Type here"
        label="Telepass EU"
        onKeyDown={blockInvalidChar}
        labelPosition="left"
        component={NumberField}
      />
      <Field
        id="rentalFee3"
        name="rentalFee3"
        placeholder="Type here"
        label="Telepass SAT"
        onKeyDown={blockInvalidChar}
        labelPosition="left"
        component={NumberField}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setFieldValue('rentalFee3', e.target.value);
          setFieldValue('rentalFee2', e.target.value);
        }}
      />
      <Field
        id="rentalFee4"
        name="rentalFee4"
        placeholder="Type here"
        label="Telepass Medpass"
        onKeyDown={blockInvalidChar}
        labelPosition="left"
        component={NumberField}
      />
      <Field
        id="rentalFeeKMaster"
        name="rentalFeeKMaster"
        placeholder="Type here"
        label="Telepass KMaster"
        onKeyDown={blockInvalidChar}
        labelPosition="left"
        component={NumberField}
      />
      <Field
        id="rentalFeeDigitalPayment"
        name="rentalFeeDigitalPayment"
        placeholder="Type here"
        label="Telepass Digital Payment"
        onKeyDown={blockInvalidChar}
        labelPosition="left"
        component={NumberField}
      />
    </>
  );
};

export default TelepassRentalFee;
