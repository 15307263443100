export const Label = ({
  text,
  required,
  textWrap = true,
  mandatory = false
}: {
  text: string;
  required?: boolean;
  textWrap?: boolean;
  mandatory?: boolean;
}) => (
  <span
    className={`text-sm text-shellLabel items-center flex  ${
      textWrap ? 'lg:whitespace-nowrap' : ''
    }`}
  >
    <span>
      <b>{text}</b>
      {(required || mandatory) && <span className="text-shellRed">*</span>}
    </span>
  </span>
);
