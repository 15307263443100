import styled from 'styled-components';
import { Menu as SDFMenu } from '@sede-x/shell-ds-react-framework';

export const StyledMenu = styled(SDFMenu)`
  .shell-menu-item {
    border-bottom: 1px solid rgba(28, 28, 28, 0.14);
    min-height: 56px;
    align-items: center;
  }
`;
