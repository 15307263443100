import { useFormikContext } from 'formik';
import React from 'react';
import { customerInstance } from 'api';
import { customerEndPoints } from 'api/apiEndpoints';
import { useQuery } from '@tanstack/react-query';
import { STALE_TIME, queryClient } from 'react-query';
import { Label } from 'components/Label/Label';
import { Button, Text } from '@sede-x/shell-ds-react-framework';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { errorHelper } from 'utils/helpers/errorHelper';
import { Customer } from '../../types';

async function fetchCustomerConsortiumDetails(id: string) {
  const { data } = await customerInstance.post(
    customerEndPoints.customerConsortiumDetails,
    {
      customerId: id
    }
  );
  return data;
}

const ALERT_TITLE = 'Shell Consortium';

const ShellConsortiumMember: React.FC = () => {
  const {
    values: {
      customerID,
      euLicense,
      euLicenseIssueDate,
      euLicenseExpiryDate,
      euLicenseStartDate
    }
  } = useFormikContext<Customer>();

  const { data: consortiumData } = useQuery({
    queryKey: ['consortium', customerID],
    queryFn: () => fetchCustomerConsortiumDetails(customerID ?? ''),
    staleTime: STALE_TIME,
    enabled: !!customerID,
    refetchOnWindowFocus: false
  });

  const { confirmCloseDialog, errorDialog } = useConfirmDialogs();

  const handleAddMember = async () => {
    const isConfirmed = await confirmCloseDialog(
      'Shell Consortium',
      'Customer will be added into Shell Consortium. Are you sure?'
    );
    if (!isConfirmed) {
      return;
    }
    customerInstance
      .post(customerEndPoints.addConsortiumMember, { customerID })
      .then(() => {
        queryClient.invalidateQueries(['consortium', customerID]);
      })
      .catch((error) => {
        const message = 'Failed to add customer into Shell Consortium';

        errorDialog('Error', errorHelper(error, message));
      });
  };

  const handleRemoveMember = async () => {
    const isConfirmed = await confirmCloseDialog(
      ALERT_TITLE,
      'Customer will be removed from Shell Consortium. Are you sure?'
    );
    if (!isConfirmed) {
      return;
    }
    customerInstance
      .post(customerEndPoints.removeConsortiumMember, { customerID })
      .then(() => {
        queryClient.invalidateQueries(['consortium', customerID]);
      })
      .catch((error) => {
        const message = 'Failed to remove customer from Shell Consortium';
        errorDialog('Error', errorHelper(error, message));
      });
  };

  return (
    <div>
      <div className="grid gap-1 w-full  h-full grid-cols-1 md:grid-cols-3 lg:grid-cols-3  items-center p-2 ">
        <div>
          <Label text="Member of Shell Consortium" />
        </div>
        <div className="grid grid-cols-2 gap-3 col-span-2">
          <Text size="small">
            {consortiumData?.consortiumMemberContractString ??
              'No active Telepass, T4E or Axxes contract'}
          </Text>
          <div>
            {consortiumData?.canAddMember && (
              <Button
                size="xsmall"
                variant="outlined"
                sentiment="positive"
                onClick={handleAddMember}
                disabled={
                  !euLicense ||
                  !euLicenseIssueDate ||
                  !euLicenseExpiryDate ||
                  !euLicenseStartDate
                }
                data-testid="add-consortium-member"
              >
                Add Member
              </Button>
            )}
            {consortiumData?.canRemoveMember && (
              <Button
                size="xsmall"
                variant="outlined"
                sentiment="negative"
                onClick={handleRemoveMember}
                data-testid="remove-consortium-member"
              >
                Remove Member
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShellConsortiumMember;
