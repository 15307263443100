import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api';
import { customerEndPoints } from 'api/apiEndpoints';
import QueryError from 'components/QueryError';
import Table from 'components/Table/Table';
import { getOverviewColumns } from 'components/Table/columns/overviewColumns';
import React, { useEffect, useState } from 'react';
import { STALE_TIME } from 'react-query';
import { ColumnDef } from '@tanstack/react-table';
import { useFormikContext } from 'formik';
import { useSdsPagination } from 'hooks/use-pagination';
import { Vehicle } from './types';
import { NewOBU } from '../../types';

async function fetchVehicleList(
  tollName: string,
  customerId: string,
  pageNumber: number,
  size: number
) {
  const { data } = await customerInstance.post(
    customerEndPoints.getVehiclesByCustomer,
    {
      tollName,
      customerId,
      pageNumber,
      pageSize: size
    }
  );
  return data;
}

interface ContractSelectionProps {
  customerId: string;
}

const DEFAULT_PAGE_SIZE = 20;

const VehicleSelection: React.FC<ContractSelectionProps> = ({ customerId }) => {
  const [selectedRows, setSelectedRows] = useState<Vehicle[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const { values, setFieldValue } = useFormikContext<NewOBU>();

  const handleChangePagination = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
    setSelectedRows([]);
  };

  const paginationProps = useSdsPagination(handleChangePagination);

  const { isError, data, isLoading } = useQuery({
    queryKey: ['obu-vehicle-list', values.tollName, pageNumber, pageSize],
    queryFn: () =>
      fetchVehicleList(values.tollName ?? '', customerId, pageNumber, pageSize),
    staleTime: STALE_TIME,
    refetchOnWindowFocus: 'always',
    enabled: !!values.tollID
  });

  useEffect(() => {
    if (selectedRows.length) {
      const [selectedContract] = selectedRows;
      setFieldValue('vehicleID', selectedContract.vehicleID);
      setFieldValue('vehicleNo', selectedContract.licensePlate);
    } else {
      setFieldValue('vehicleID', undefined);
      setFieldValue('vehicleNo', undefined);
    }
  }, [selectedRows]);

  const { data: contractList, count } = data || {};

  return (
    <QueryError isLoading={isLoading} isError={isError}>
      <Table<Vehicle>
        data={contractList}
        columns={getOverviewColumns('obuVehicleList') as ColumnDef<Vehicle>[]}
        columnSelection={false}
        exportEnabled={false}
        enableMultiRowSelection={false}
        onSelectedRowsChange={setSelectedRows}
        paginationData={{ ...paginationProps, total: count }}
        globalFilterEnabled
      />
    </QueryError>
  );
};

export default VehicleSelection;
