import { useMutation } from '@tanstack/react-query';
import { axiosInstance } from '../api';
import { endpoints } from '../api/apiEndpoints';
import { ReportPayload } from './types';

export const useReport = (key: string) =>
  useMutation(
    [key],
    async (payload: ReportPayload) => {
      const result = await axiosInstance.post(
        `Report/${endpoints[key]}`,
        payload
      );
      return result.data;
    },
    {
      cacheTime: 0,
      retry: false
    }
  );
