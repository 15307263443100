import { Checkbox as SDSCheckBox } from '@sede-x/shell-ds-react-framework';
import { FieldProps } from 'formik';
import FormLabel from './FormLabel';

const CheckBox = (props: FieldProps) => {
  const {
    field,
    form: { touched, errors },
    ...rest
  } = props;

  return (
    <div>
      <FormLabel {...field} {...rest}>
        <SDSCheckBox
          {...field}
          {...rest}
          invalid={!!(touched[field.name] && errors[field.name])}
          data-testid={`${field.name}-checkbox`}
          autoComplete="off"
          label=""
        />
        {touched[field.name] && errors[field.name] && (
          <div className="text-red-500 text-sm">
            {errors[field.name] as string}
          </div>
        )}
      </FormLabel>
    </div>
  );
};

export default CheckBox;
