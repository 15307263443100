import {
  Button,
  Icons,
  Modal,
  Sizes,
  Alignments,
  TextInput,
  Flexbox,
  RadioButton,
  ModalAction,
  Sentiments,
  FormField
} from '@sede-x/shell-ds-react-framework';
import QueryError from 'components/QueryError';
import Table from 'components/Table/Table';
import { createColumnHelper } from '@tanstack/react-table';
import styled from 'styled-components';
import { KeyboardEvent, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api';
import { Customers } from 'utils/Interfaces/Providers';
import { columnBuilder } from 'utils/helpers';
import { useSdsPagination } from 'hooks/use-pagination';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import ActionColumns from '../../../../../components/Table/columns/actionColumns.json';

const StyledModal = styled(Modal)`
  .shell-modal-container-content {
    height: 80vh;
  }
`;

const columnHelper = createColumnHelper<Customers>();
const columns = [
  columnHelper.display({
    id: 'actions',
    size: 20,
    cell: ({ row }) => (
      <Flexbox gap="12px" alignItems="center">
        <RadioButton
          checked={row.getIsSelected()}
          onChange={row.getToggleSelectedHandler()}
          data-testid={`rowcheck-${row.index}`}
          size={Sizes.ExtraSmall}
        />
      </Flexbox>
    ),
    header: ''
  }),
  ...columnBuilder<Customers>(ActionColumns.customerSearchColumns)
];

export const StyledTextInput = styled(TextInput)`
  > div[type='prefix'] {
    font-size: 13px;
    white-space: nowrap;
  }
`;

async function fetchCustomer(
  pageNumber: number,
  pageSize: number,
  searchTerm: string
) {
  return axiosInstance.post('report/action/czechunknowncontracts/customers', {
    pageNumber,
    pageSize,
    searchTerm
  });
}

const DEFAULT_LIMIT = 20;

function GFNSearch({
  title,
  disabled,
  onSubmit
}: Readonly<{
  title: string;
  disabled?: boolean;
  onSubmit: (value?: Customers) => void;
}>) {
  const [open, setOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Customers[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_LIMIT);
  const [searchParam, setSearchParam] = useState('');
  const [fetchEnabled, setFetchEnabled] = useState(false);

  const { confirmCloseDialog } = useConfirmDialogs();

  const { isError, data, isLoading, isFetching, remove } = useQuery({
    queryKey: ['customers', page, pageSize, searchParam],
    queryFn: () =>
      fetchCustomer(page, pageSize, searchParam)
        .then((res) => res.data)
        .finally(() => setFetchEnabled(false)),
    enabled: fetchEnabled,
    cacheTime: 0
  });

  const handleChangePagination = (pageNumber: number, newPageSize: number) => {
    setSelectedRows([]);
    setPage(pageNumber);
    setPageSize(newPageSize);
    setFetchEnabled(true);
  };
  const paginationProps = useSdsPagination(handleChangePagination);
  const { resetPagination } = paginationProps;

  const handleOnClose = () => {
    resetPagination();
    setOpen(false);
    setSelectedRows([]);
    setSearchParam('');
    remove();
  };

  const [selectedCustomer] = selectedRows;

  const actions = [
    {
      label: 'Assign GFN',
      action: async () => {
        const isConfirmed = await confirmCloseDialog(
          'Assign GFN',
          `All the associated contracts will be transferred to <b>${selectedCustomer.gfnid}</b>, click on Confirm to proceed.`
        );
        if (!isConfirmed) {
          return;
        }
        onSubmit(selectedCustomer);
        handleOnClose();
      },
      props: {
        variant: 'filled',
        iconPosition: 'left',
        size: 'small',
        hidden: isError || isLoading,
        disabled: !selectedRows.length,
        'data-testid': `GFN-assign-btn`
      }
    }
  ];
  const { data: customerData, totalNumberOfRecords } = data || {};

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && searchParam) {
      setFetchEnabled(true);
    }
  };

  return (
    <div>
      <Button
        size="small"
        onClick={() => {
          setOpen(true);
        }}
        sentiment={Sentiments.Positive}
        icon={<Icons.SwapHorizontal />}
        variant="outlined"
        data-testid="customer-assign-btn"
        aria-label="Customer selector"
        disabled={disabled}
      >
        Assign GFN
      </Button>
      {open && (
        <StyledModal
          width="70%"
          title={title}
          open={open}
          size={Sizes.Small}
          maskClosable={false}
          actions={actions as ModalAction[]}
          mask
          onClose={handleOnClose}
          contentScrollable
          actionsAlignment={Alignments.Right}
          centered
          bodyPadding={false}
        >
          <div className="mr-2 flex p-3 flex-col flex-grow overflow-auto h-full ">
            <div className="flex flex-col gap-3 pb-3">
              <div className="w-full md:w-[40%] lg:w-[40%] p-1">
                <FormField label="Search Customer">
                  <StyledTextInput
                    value={searchParam}
                    suffix={{
                      node: (
                        <Button
                          iconOnly
                          size="small"
                          icon={<Icons.Search />}
                          variant="transparent"
                          onClick={() => {
                            if (searchParam) {
                              setFetchEnabled(true);
                            }
                          }}
                          data-testid="search-btn"
                          aria-label="Customer selector"
                        />
                      )
                    }}
                    onChange={(e) => {
                      setSearchParam(e.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                    data-testid="search-gfn-input"
                    size="small"
                    placeholder="Search"
                    autoComplete="off"
                  />
                </FormField>
              </div>
            </div>
            <QueryError isError={isError} isLoading={isFetching}>
              <Table<Customers>
                columns={columns}
                data={customerData || []}
                columnSelection={false}
                exportEnabled={false}
                enableMultiRowSelection={false}
                onSelectedRowsChange={setSelectedRows}
                paginationData={{
                  ...paginationProps,
                  total: totalNumberOfRecords
                }}
              />
            </QueryError>
          </div>
        </StyledModal>
      )}
    </div>
  );
}
export default GFNSearch;
