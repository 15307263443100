import { Icons, Loading } from '@sede-x/shell-ds-react-framework';
import ErrorState from 'components/ErrorState/ErrorState';
import { ReactNode } from 'react';

function QueryError({
  children,
  isLoading,
  isError,
  errorText = 'An error occurred while fetching the data, please try again after some time.'
}: Readonly<{
  children: ReactNode;
  isLoading?: boolean;
  isError?: boolean;
  errorText?: string;
}>) {
  if (isLoading) {
    return (
      <div className="flex justify-center items-center m-auto">
        <Loading size="large" />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex justify-center items-center m-auto">
        <ErrorState
          icon={<Icons.Alert />}
          sentiment="negative"
          title="Error"
          description={errorText}
        />
      </div>
    );
  }
  return <>{children}</>;
}

export default QueryError;
