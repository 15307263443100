import React, { useRef, useState } from 'react';
import {
  Button,
  Icons,
  Modal,
  Sizes,
  Alignments,
  Variants
} from '@sede-x/shell-ds-react-framework';
import {
  Field,
  Formik,
  FormikErrors,
  FormikHelpers,
  FormikProps
} from 'formik';
import SelectField from 'components/FormCmponents/SelectField';
import TextField from 'components/FormCmponents/TextField';
import CustomerForm from 'Pages/CustomerDetails/components/Customer/CustomerForm/CustomerForm';
import { Customer } from 'Pages/CustomerDetails/components/Customer/types';
import { customerInstance } from 'api';
import { customerEndPoints } from 'api/apiEndpoints';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import {
  customerInitialValues,
  customerValidate
} from 'Pages/CustomerDetails/utils';
import { useNavigate } from 'react-router-dom';
import { errorHelper } from 'utils/helpers/errorHelper';
import useCustomerColco from 'hooks/useCustomerColco';
import { GFNData } from './types';

const TOTAL_STEPS = 2;

const CustomerNew: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  const formRef = useRef<FormikProps<Customer>>(null);

  const [colcoMap] = useCustomerColco(open);

  const navigate = useNavigate();

  const { errorDialog, successDialog, infoDialog } = useConfirmDialogs();

  const handleOnStepClick = (num: number) => {
    setStep(num);
  };

  const handleOnClose = () => {
    setStep(1);
    setOpen(false);
  };

  const validate = (values: Customer) => {
    const errors: FormikErrors<Customer> = {};
    const MAX_LENGTH = 8;

    if (!values.customerNo) {
      errors.customerNo = 'Please fill out this mandatory field';
    } else if (values.customerNo.length < MAX_LENGTH) {
      errors.customerNo = 'Please enter 8 digits';
    }

    if (!values.colcoCountryTypeID) {
      errors.colcoCountryTypeID = 'Please select Shell colco';
    }

    return errors;
  };

  const handleConfirm = (
    values: Customer,
    { setSubmitting }: FormikHelpers<Customer>
  ) => {
    customerInstance
      .post(customerEndPoints.saveCustomer, values, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        const { data: newCustomerID } = response.data;
        successDialog('', 'Successfully added new customer');
        navigate(`/customer/${newCustomerID}?type=customer`);
        handleOnClose();
      })
      .catch((error) => {
        const message = 'Failed to create new customer, please try again later';
        errorDialog('', errorHelper(error, message));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleCheckGFN = (values: Customer) => {
    const country = colcoMap[values.colcoCountryTypeID ?? ''];

    if (country?.isH3) {
      customerInstance
        .post(customerEndPoints.GFNDetails, {
          customerNo: values.customerNo,
          countryId: country.countryID,
          countryName: country.name,
          shellCountryCode: country.shellCountryCode
        })
        .then((res) => {
          const {
            gfnid,
            currencyID,
            tradeNumber,
            vatID,
            legalForm,
            name,
            phone
          } = res.data as GFNData;
          formRef.current?.setValues((prev) => ({
            ...prev,
            gfnid,
            currencyTypeID: currencyID,
            tradeNumber,
            vatID,
            legalForm,
            name,
            phone,
            payer: gfnid
          }));
          handleOnStepClick(step + 1);
        })
        .catch(() => {
          errorDialog('', `${values.customerNo} could not be found in GFN.`);
        });
    } else {
      infoDialog('', 'Selected colco is not H3, Please select H3 colco.');
    }
  };

  return (
    <>
      <Button
        icon={<Icons.Add />}
        iconPosition="left"
        data-testid="add-new-customer"
        onClick={() => setOpen(true)}
      >
        New Customer
      </Button>

      {open && (
        <Formik<Customer>
          initialValues={customerInitialValues}
          enableReinitialize
          validate={customerValidate}
          onSubmit={handleConfirm}
          innerRef={formRef}
        >
          {({ handleSubmit, isSubmitting, values, setFieldValue }) => (
            <Modal
              title="New Customer"
              open={open}
              width={step === TOTAL_STEPS ? '90%' : '570px'}
              size={Sizes.Small}
              maskClosable={false}
              mask
              onClose={handleOnClose}
              contentScrollable
              actionsAlignment={Alignments.Right}
              centered
              actions={[
                {
                  label: 'Back',
                  action: () => {
                    handleOnStepClick(step - 1);
                  },
                  props: {
                    variant: Variants.Outlined,
                    icon: <Icons.ArrowLeft />,
                    hidden: step === 1,
                    style: {
                      display: step === 1 ? 'none' : ''
                    }
                  }
                },
                {
                  label: 'Next',
                  action: () => {
                    handleCheckGFN(values);
                  },
                  props: {
                    hidden: step === TOTAL_STEPS,
                    style: {
                      display: step === TOTAL_STEPS ? 'none' : ''
                    },
                    disabled: Object.keys(validate(values)).length > 0
                  }
                },
                {
                  label: 'Submit',
                  action: () => {
                    handleSubmit();
                  },
                  props: {
                    icon: <Icons.Save />,
                    disabled: isSubmitting,
                    type: 'submit',
                    hidden: step !== TOTAL_STEPS,
                    style: {
                      display: step !== TOTAL_STEPS ? 'none' : ''
                    },
                    'aria-hidden': step !== TOTAL_STEPS
                  }
                }
              ]}
            >
              {step === 1 && (
                <>
                  <Field
                    id="colcoCountryTypeID"
                    name="colcoCountryTypeID"
                    placeholder="Select"
                    label="Shell Colco"
                    component={SelectField}
                    dataEndPoint="/country"
                    dataField="countryID"
                    dataLabelField="name"
                    onOption
                    mandatory
                    onChange={(value: string) => {
                      setFieldValue('colcoCountryTypeID', value);
                      setFieldValue('countryTypeID', value);
                    }}
                  />
                  <Field
                    id="customerNo"
                    name="customerNo"
                    placeholder="Type here"
                    label="Customer Number"
                    maxLength={8}
                    component={TextField}
                    mandatory
                  />
                </>
              )}
              {step === TOTAL_STEPS && <CustomerForm />}
            </Modal>
          )}
        </Formik>
      )}
    </>
  );
};

export default CustomerNew;
