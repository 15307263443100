import { Flags } from '@sede-x/shell-ds-react-framework';
import TelepassIcon from '../../assets/icons/telepass_icon.png';
import T4EIcon from '../../assets/icons/t4e_icon.png';
import AutoPay from '../../assets/icons/autopay_icon.png';
import { StyledAvatar } from './style';

const LabelConstants: { [key: string]: string } = {
  cardBlocked: 'Card Blocked (customer)',
  cardExpired: 'Card Expired (customer)',
  unknownContracts: 'Unknown Contracts',
  transactionReport: 'Transaction Report',
  usingBlockedCard: 'Using Blocked Card'
};

const ReportNameConstants: { [key: string]: string } = {
  unknownContracts: 'Unknown Contracts',
  transactionReport: 'Transaction Report',
  contractList: 'Contract List',
  cardBlocked: 'Card Blocked (customer)',
  cardExpired: 'Card Expired (customer)',
  transactionNotification: 'Transaction Notification',
  usingBlockedCard: 'Using Blocked Card'
};

export const ProvidersMenu = [
  {
    children: [
      {
        key: 'autopayCardBlocked',
        label: LabelConstants.cardBlocked,
        reportName: ReportNameConstants.cardBlocked,
        exportAll: true
      },
      {
        key: 'autopayCardExpired',
        label: LabelConstants.cardExpired,
        reportName: LabelConstants.cardExpired,
        exportAll: true
      },
      {
        key: 'autoPayRegisteredVehiclesReport',
        label: 'Registered Vehicles Report',
        reportName: 'Registered Vehicles Report',
        exportAll: true
      },
      {
        key: 'autoPayVehicleRequestReport',
        label: 'Vehicle Request Report',
        reportName: 'Vehicle Request Report',
        exportAll: true
      }
    ],
    key: 'Autopay',
    label: 'Auto pay',
    itemIcon: <StyledAvatar size="small" src={AutoPay} />
  },
  {
    children: [
      {
        key: 'czechTollCardBlocked',
        label: LabelConstants.cardBlocked,
        reportName: ReportNameConstants.cardBlocked,
        exportAll: true
      },
      {
        key: 'czechTollCardChange',
        label: 'Card Change Request',
        reportName: 'Card Change Request',
        exportAll: true
      },
      {
        key: 'czechTollCardExpired',
        label: LabelConstants.cardExpired,
        reportName: LabelConstants.cardExpired,
        exportAll: true
      },
      {
        key: 'czechTollRegAccounts',
        label: 'Registered Accounts',
        reportName: 'Registered Accounts',
        exportAll: true
      },
      {
        key: 'czechTollRegistrationRequests',
        label: 'Registration Requests',
        reportName: 'Registration Requests',
        exportAll: true
      }
    ],
    key: 'CzechToll',
    label: 'Czech Toll',
    itemIcon: <Flags.Cz />
  },
  {
    children: [
      {
        key: 'activeEvignettes',
        label: 'Active Evignettes',
        reportName: 'Active eVignettes',
        exportAll: true
      },
      {
        key: 'evignetteCardBlocked',
        label: LabelConstants.cardBlocked,
        reportName: ReportNameConstants.cardBlocked,
        exportAll: true
      },
      {
        key: 'evignetteCardExpired',
        label: LabelConstants.cardExpired,
        reportName: LabelConstants.cardExpired,
        exportAll: true
      },
      {
        key: 'evignetteError',
        label: 'Error',
        reportName: 'Error',
        exportAll: true
      },
      {
        key: 'evignettesOpenOrders',
        label: 'Open Orders',
        reportName: 'Open Orders',
        exportAll: true
      },
      {
        key: 'evignettesOrders',
        label: 'Orders',
        reportName: 'Orders',
        exportAll: true
      },
      {
        key: 'evignetteRegisteredCustomers',
        label: 'Registered Customers',
        reportName: 'Registered Customers',
        exportAll: true
      }
    ],
    key: 'eVignette',
    label: 'eVignette',
    itemIcon: <Flags.Lu />
  },
  {
    children: [
      {
        key: 'fdeCardBlocked',
        label: LabelConstants.cardBlocked,
        reportName: ReportNameConstants.cardBlocked,
        exportAll: true
      },
      {
        key: 'fdeCardExpired',
        label: LabelConstants.cardExpired,
        reportName: LabelConstants.cardExpired,
        exportAll: true
      },
      {
        key: 'fdeCCLReport',
        label: 'CCL report',
        reportName: 'CCL report',
        exportAll: true
      },
      {
        key: 'fdeRegistrationReport',
        label: 'Registration Report',
        reportName: 'Registration',
        exportAll: true
      },
      {
        key: 'fdeTransactionReport',
        label: LabelConstants.transactionReport,
        reportName: ReportNameConstants.transactionReport,
        exportAll: true
      },
      {
        key: 'fdeVirtualCardOrder',
        label: 'Virtual Card Order',
        reportName: 'Virtual Card Order',
        exportAll: true
      }
    ],
    key: 'FDE',
    label: 'FDE',
    itemIcon: <Flags.Earth />
  },
  {
    children: [
      {
        key: 'belgiumCardBlocked',
        label: LabelConstants.cardBlocked,
        reportName: ReportNameConstants.cardBlocked,
        exportAll: true
      },
      {
        key: 'belgiumCardExpired',
        label: LabelConstants.cardExpired,
        reportName: LabelConstants.cardExpired,
        exportAll: true
      },
      {
        key: 'belgiumCardSyncErrors',
        label: 'Card Synchronization Errors',
        reportName: 'Card Synchronization Errors',
        exportAll: true
      },
      {
        key: 'belgiumContracts',
        label: 'Contracts',
        reportName: 'Contracts',
        exportAll: true
      },
      {
        key: 'belgiumCustomerListReport',
        label: 'Customer List Report',
        reportName: 'Customer List Report',
        exportAll: true
      },
      {
        key: 'belgiumObusOnBlackList',
        label: 'OBUs on Black List',
        reportName: 'OBUs On Blacklist',
        exportAll: true
      },
      {
        key: 'belgiumTransaction',
        label: 'Transaction',
        reportName: ReportNameConstants.transactionReport,
        exportAll: true
      },
      {
        key: 'belgiumUnknownContracts',
        label: LabelConstants.unknownContracts,
        reportName: ReportNameConstants.unknownContracts,
        exportAll: true
      },
      {
        key: 'belgiumUnknownCountries',
        label: 'Unknown Countries',
        reportName: 'Unknown Countries',
        exportAll: true
      }
    ],
    key: 'Belgium',
    label: 'Satellic',
    itemIcon: <Flags.Be />
  },
  {
    children: [
      {
        key: 'slovakiaCardBlocked',
        label: LabelConstants.cardBlocked,
        reportName: ReportNameConstants.cardBlocked,
        exportAll: true
      },
      {
        key: 'slovakiaCardExpired',
        label: LabelConstants.cardExpired,
        reportName: LabelConstants.cardExpired,
        exportAll: true
      },
      {
        key: 'slovakiaBlacklist',
        label: 'Blacklist',
        reportName: 'Blacklist',
        exportAll: true
      },
      {
        key: 'slovakiaCardChange',
        label: 'Card Change',
        reportName: 'Card Change',
        exportAll: true
      },
      {
        key: 'slovakiaCardExpiryByFCE',
        label: 'Card Expiry by FCE',
        reportName: 'Card Expiry by FCE',
        exportAll: true
      },
      {
        key: 'slovakiaContractList',
        label: 'Contract List',
        reportName: ReportNameConstants.contractList,
        exportAll: true
      },
      {
        key: 'slovakiaErrors',
        label: 'Errors',
        reportName: 'Errors',
        exportAll: true
      },
      {
        key: 'slovakiaRegistration',
        label: 'Registration',
        reportName: 'Registration',
        exportAll: true
      },
      {
        key: 'slovakiaUnknownContracts',
        label: LabelConstants.unknownContracts,
        reportName: ReportNameConstants.unknownContracts,
        exportAll: true
      },
      {
        key: 'slovakiaRejectedOrders',
        label: 'Rejected Orders',
        reportName: 'Rejected Orders',
        exportAll: true
      },
      {
        key: 'slovakiaTransaction',
        label: LabelConstants.transactionReport,
        reportName: ReportNameConstants.transactionReport,
        exportAll: true
      },
      {
        key: 'slovakiaUsingBlockedCard',
        label: LabelConstants.usingBlockedCard,
        reportName: ReportNameConstants.usingBlockedCard,
        exportAll: true
      }
    ],
    key: 'Slovakia',
    label: 'SkyToll',
    itemIcon: <Flags.Sk />
  },
  {
    children: [
      {
        key: 'swizCardBlocked',
        label: LabelConstants.cardBlocked,
        reportName: ReportNameConstants.cardBlocked,
        exportAll: true
      },
      {
        key: 'swizCardExpired',
        label: LabelConstants.cardExpired,
        reportName: LabelConstants.cardExpired,
        exportAll: true
      },
      {
        key: 'swizTransaction',
        label: LabelConstants.transactionReport,
        reportName: ReportNameConstants.transactionReport,
        exportAll: true
      }
    ],
    key: 'Switzerland_EZV',
    label: 'Switzerland_EZV',
    itemIcon: <Flags.Ch />
  },
  {
    children: [
      {
        key: 't4eCardBlocked',
        label: LabelConstants.cardBlocked,
        reportName: ReportNameConstants.cardBlocked,
        exportAll: true
      },
      {
        key: 't4eCardExpired',
        label: LabelConstants.cardExpired,
        reportName: LabelConstants.cardExpired,
        exportAll: true
      },
      {
        key: 't4eCardPayerAssociationCodes',
        label: 'Card Payer Association Codes',
        reportName: 'Card Payer Association Codes',
        exportAll: true
      },
      {
        key: 't4eObuMonthlyRentalFees',
        label: 'Monthly OBU Rental Fee Processing',
        reportName: 'OBU Monthly Rental Fees',
        exportAll: true
      }
    ],
    key: 'T4E',
    label: 'T4E',
    itemIcon: <StyledAvatar size="small" src={T4EIcon} />
  },

  {
    children: [
      {
        key: 'telepassCardBlocked',
        label: LabelConstants.cardBlocked,
        reportName: ReportNameConstants.cardBlocked,
        exportAll: true
      },
      {
        key: 'telepassCardExpired',
        label: LabelConstants.cardExpired,
        reportName: LabelConstants.cardExpired,
        exportAll: true
      },
      {
        key: 'telepassContractList',
        label: 'Contract List Report',
        reportName: ReportNameConstants.contractList,
        exportAll: true
      },
      {
        key: 'telepassELCONReport',
        label: 'ELCON Report',
        reportName: 'ELCON Report',
        exportAll: true
      },
      {
        key: 'obu',
        label: 'OBU',
        children: [
          {
            key: 'telepassOBUListReport',
            label: 'List Report',
            reportName: 'OBU List Report',
            exportAll: true
          },
          {
            key: 'telepassOBUMonitoringReport',
            label: 'OBU Monitoring Report',
            reportName: 'OBU Monitoring Report',
            exportAll: true
          },
          {
            key: 'telepassObuServiceListReport',
            label: 'Service List Report',
            reportName: 'OBU Service List Report',
            exportAll: true
          },
          {
            key: 'telepassOBUMonthlyRentalFees',
            label: 'Monthly Rental Fees',
            reportName: 'OBU Monthly Rental Fees',
            exportAll: true
          }
        ]
      },
      {
        key: 'errorReports',
        label: 'Error Report',
        children: [
          {
            key: 'errorReportContract',
            label: 'Contract',
            reportName: 'Contract Error',
            exportAll: true
          },
          {
            key: 'telepassErrorReportOBU',
            label: 'OBU',
            reportName: 'OBU Error',
            exportAll: true
          }
        ]
      },
      {
        key: 'telepassVehicleList',
        label: 'Vehicle List Report',
        reportName: 'Vehicle List Report',
        exportAll: true
      }
    ],
    key: 'Telepass',
    label: 'Telepass',
    itemIcon: <StyledAvatar size="small" src={TelepassIcon} />
  },
  {
    children: [
      {
        key: 'germanyCardBlocked',
        label: LabelConstants.cardBlocked,
        reportName: ReportNameConstants.cardBlocked,
        exportAll: true
      },
      {
        key: 'germanyCardExpired',
        label: LabelConstants.cardExpired,
        reportName: LabelConstants.cardExpired,
        exportAll: true
      },
      {
        key: 'germanyContract',
        label: 'Contract',
        children: [
          {
            key: 'germanyContractStatus',
            label: 'Status',
            reportName: 'Contract Status',
            exportAll: true
          },
          {
            key: 'germanyContractList',
            label: 'List',
            reportName: ReportNameConstants.contractList,
            exportAll: true
          }
        ]
      },
      {
        key: 'germanyDeregisteredbyCustomer',
        label: 'Deregistered By Customer',
        reportName: 'Deregistered By Customer',
        exportAll: true
      },
      {
        key: 'limit',
        label: 'Limit',
        children: [
          {
            key: 'germanyLimitConfirmations',
            label: 'Confirmations',
            reportName: 'Limit Confirmations',
            exportAll: true
          },
          {
            key: 'germanyLimitConfirmationsErrors',
            label: 'Confirmations Errors',
            reportName: 'Limit Confirmations Errors',
            exportAll: true
          },
          {
            key: 'germanyLimitRequests',
            label: 'Requests',
            reportName: 'Limit Requests',
            exportAll: true
          }
        ]
      },
      {
        key: 'germanyPanIDMismatch',
        label: 'PANID Mismatch',
        reportName: 'PANID Mismatch',
        exportAll: true
      },
      {
        key: 'transaction',
        label: 'Transaction',
        children: [
          {
            key: 'germanyTransactionESI',
            label: 'eSI',
            reportName: 'Transaction eSI',
            exportAll: true
          },
          {
            key: 'germanyTransactionGFN',
            label: 'GFN',
            reportName: 'Transaction GFN',
            exportAll: true
          },
          {
            key: 'germanyTransactionError',
            label: 'Error',
            reportName: 'Transaction Error',
            exportAll: true
          },
          {
            key: 'germanyInboundOutbound',
            label: 'Inbound - Outbound',
            reportName: 'Transaction Inbound - Outbound',
            exportAll: true
          },
          {
            key: 'germanyInvoice',
            label: 'Invoice',
            reportName: 'Transaction Invoice',
            exportAll: true
          },
          {
            key: 'germanyTransNotificationDaily',
            label: 'Notification Daily',
            reportName: 'Transaction Notification Daily',
            exportAll: true
          },
          {
            key: 'germanytransNotificationFortnight',
            label: 'Notification fortnight',
            reportName: 'Transaction Notification FortNight',
            exportAll: true
          },
          {
            key: 'germanyNotificationMonthly',
            label: 'Notification Monthly',
            reportName: 'Transaction Notification Monthly',
            exportAll: true
          },
          {
            key: 'germanyTransReconciliation',
            label: 'Reconciliation',
            reportName: 'Transaction Reconciliation',
            exportAll: true
          },
          {
            key: 'germanyUninvoice',
            label: 'Uninvoice',
            reportName: 'Transaction Uninvoice',
            exportAll: true
          }
        ]
      },
      {
        key: 'germanyUsingBlockedCard',
        label: LabelConstants.usingBlockedCard,
        reportName: ReportNameConstants.usingBlockedCard,
        exportAll: true
      }
    ],
    key: 'Germany',
    label: 'Toll Collect',
    itemIcon: <Flags.De />
  },
  {
    children: [
      {
        key: 'ukLevyCardBlocked',
        label: LabelConstants.cardBlocked,
        reportName: ReportNameConstants.cardBlocked,
        exportAll: true
      },
      {
        key: 'ukLevyCardExpired',
        label: LabelConstants.cardExpired,
        reportName: LabelConstants.cardExpired,
        exportAll: true
      },
      {
        key: 'ukLevyTransaction',
        label: LabelConstants.transactionReport,
        reportName: ReportNameConstants.transactionReport,
        exportAll: true
      }
    ],
    key: 'UKLevy',
    label: 'UKLevy',
    itemIcon: <Flags.Gb />
  }
];
