import { Component, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}
interface State {
  hasError: boolean;
}
class AppErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch() {
    this.setState({ hasError: true });
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div
          className="flex justify-center items-center h-screen"
          data-testid="errorboundary"
        >
          <h1>There was a problem. Contact our support team to get help</h1>
        </div>
      );
    }
    return this.props.children;
  }
}
export default AppErrorBoundary;
