import {
  Avatar,
  Icons,
  Menu,
  Modal,
  Popover,
  Sizes,
  Text
} from '@sede-x/shell-ds-react-framework';
import { Roles } from 'utils/helpers/types';
import { TTokenData } from 'auth/Types';
import { useState } from 'react';
import { StyledMenuItem, StyledText } from './style';

interface Props {
  userRoles?: Roles;
  tokenData?: TTokenData;
}

const UserDetails = ({ userRoles, tokenData }: Props) => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [openAbout, setOpenAbout] = useState(false);

  const abbreviation = tokenData
    ? `${String(tokenData?.givenName).charAt(0)}${String(tokenData?.sn).charAt(
        0
      )}`
    : '';
  const userName = tokenData ? `${tokenData?.givenName} ${tokenData?.sn}` : '';
  const mail = tokenData?.mail ? tokenData?.mail : '';

  const onInnerClose = () => {
    setOpenAbout(false);
  };

  const handlePopoverClick = () => {
    setPopupVisible((visible) => !visible);
  };
  return (
    <>
      <Popover
        className="no-scrollbar"
        popupVisible={popupVisible}
        popup={
          <div className="bg-white pb-3 gap-1 flex flex-col">
            <div className="p-3 flex flex-col gap-3">
              <div>
                <Avatar
                  size={Sizes.Small}
                  abbreviation={abbreviation}
                  label={userName}
                  secondaryLabel={mail as string}
                />
              </div>
              <div className="flex justify-center flex-row">
                <div className="border-r-2">
                  <StyledText
                    size={Sizes.Small}
                    className="whitespace-nowrap mr-2 "
                  >
                    <b>SOTR</b>
                  </StyledText>
                </div>
                <div className="ml-2">
                  <StyledText
                    size={Sizes.Small}
                    className="whitespace-nowrap mr-2 "
                  >
                    {userRoles?.userGroupName}
                  </StyledText>
                </div>
              </div>
            </div>
            <Menu
              mode="vertical"
              selectable={false}
              size={Sizes.Small}
              className="font-ShellFont"
              data-testid="user-details-menu"
              onClick={handlePopoverClick}
            >
              <StyledMenuItem
                itemIcon={<Icons.InfoCircle />}
                onClick={() => {
                  setOpenAbout(true);
                }}
                data-testid="about"
              >
                About eTM
              </StyledMenuItem>
            </Menu>
          </div>
        }
        arrow
        popupPlacement="bottomRight"
        getPopupContainer={(triggerNode: HTMLElement) =>
          triggerNode.parentElement as HTMLElement
        }
      >
        <Avatar
          size={Sizes.Small}
          abbreviation={abbreviation}
          className="cursor-pointer"
          data-testid="user-details"
          onClick={handlePopoverClick}
        />
      </Popover>
      <Modal
        title="About eTM"
        open={openAbout}
        size={Sizes.Small}
        maskClosable={false}
        mask
        onClose={onInnerClose}
        contentScrollable
      >
        <Text
          size={Sizes.Small}
          className="font-ShellFont text-sm font-normal"
          data-testid="aboutText"
        >
          Dear eTM Users,
          <br />
          <br />
          Currently etoll Manager is hosted on a Shell server which is out of
          vendor support and need to be decommissioned by end of this year.
          Because of the growing number of tolls processed via eTollManager also
          an increase in capacity is required to ensure sustainable performance
          of the application.
          <br />
          In response to this the CGI eTollManager support team is preparing the
          migration of eTollManager from Shell server to a Cloud based solution
          that brings eTollManager to the next level in terms of scalability and
          resilience of the application.
          <br />
          <br />
          Benefits
          <br />
          · Better performance and resilience <br />· Increased capacity and
          performance <br />· High (99.5%) availability, removing single point
          of failure in current setup <br />· Future proof <br />· Upgrade to
          latest versions of operation system and Database <br />· Capacity
          Scale on demand through cloud based design <br />· Enables external
          access <br />· Foundation for client facing roadservice portal (eRS){' '}
          <br />· Removes the need for Agency users requiring to have Shell
          equipment (GID laptop) à Note: switching Agency users to direct access
          is not part of the cutover but will be dealt separately
        </Text>
      </Modal>
    </>
  );
};

export default UserDetails;
