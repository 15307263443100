import React, { useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import SelectField from 'components/FormCmponents/SelectField';
import CheckBox from 'components/FormCmponents/CheckBox';
import Table from 'components/Table/Table';
import { columns } from 'components/Table/columns/overviewColumns';
import { Row } from '@tanstack/react-table';
import OfferType from 'utils/StaticData/Contract/offerType.json';
import { Contract } from '../types';

const InvoiceType = ['E-invoice', 'Printed Invoice'];

interface OfferTypeData {
  Value: string;
  Description: string;
}
const LV_CONTRACT_TYPE_ID = '000fa969-989e-4cbb-92e6-014d6b49ada9';
const TRUCK_CONTRACT_TYPE_ID = 'af4b3f75-bb8e-4416-8fb8-44e3d69966a8';
const BUS_CONTRACT_TYPE_ID = '2c4f2c37-e140-4585-9da6-9a709e39f9b6';

const OfferTypeTable = (closeModal: () => void) => {
  const { setFieldValue } = useFormikContext();
  return (
    <Table
      data={OfferType}
      onRowClick={(row) => {
        setFieldValue('offerValue', (row as Row<OfferTypeData>).original.Value);
        closeModal();
      }}
      columnSelection={false}
      columns={columns.OfferType}
      exportEnabled={false}
    />
  );
};

const FranceContract: React.FC = () => {
  const { values, setValues } = useFormikContext<Contract>();
  const isContractTypeLV = values.contractTypeID === LV_CONTRACT_TYPE_ID;
  const isContractTypeTruck = values.contractTypeID === TRUCK_CONTRACT_TYPE_ID;
  const isContractTypeBus = values.contractTypeID === BUS_CONTRACT_TYPE_ID;

  useEffect(() => {
    switch (values.contractTypeID) {
      case LV_CONTRACT_TYPE_ID:
        setValues((prev) => ({
          ...prev,
          isFranceContractEnabled: true
        }));
        break;
      case TRUCK_CONTRACT_TYPE_ID:
      case BUS_CONTRACT_TYPE_ID:
        setValues((prev) => ({
          ...prev,
          isAfmStandardEnabled: true,
          isGeofencingNotificationEnabled: true,
          isPortugalContractEnabled: true,
          isLiefkenshokEnabled: true,
          isSpanishContractEnabled: true,
          isFranceContractEnabled: true,
          isHerrentunnelContractEnabled: true,
          isViapassContractEnabled: true,
          isEasyGoContractEnabled: true,
          isGermanyContractEnabled: true,
          isItalyContractEnabled: true
        }));
        break;

      default:
    }
    return () => {
      setValues((prev) => ({
        tollID: prev.tollID,
        contractTypeID: prev.contractTypeID
      }));
    };
  }, [values.contractTypeID]);

  return (
    <div>
      <Field
        id="offerValue"
        name="offerValue"
        label="Offer Type"
        labelPosition="left"
        component={SelectField}
        placeholder="Select"
        options={OfferType.map((item) => ({
          label: item.Value,
          value: item.Description
        }))}
        modalContent={OfferTypeTable}
        mandatory
      />
      <Field
        id="axxessInvoiceTypeOptionValue"
        name="axxessInvoiceTypeOptionValue"
        placeholder="Select"
        label="Invoice Type"
        labelPosition="left"
        component={SelectField}
        options={InvoiceType.map((type) => ({
          value: type,
          label: type
        }))}
        mandatory
      />
      <Field
        id="isAfmStandardEnabled"
        name="isAfmStandardEnabled"
        label="AFM Standard"
        labelPosition="left"
        component={CheckBox}
        checked={values.isAfmStandardEnabled}
        disabled={isContractTypeLV || isContractTypeTruck || isContractTypeBus}
      />
      <Field
        id="isGeofencingNotificationEnabled"
        name="isGeofencingNotificationEnabled"
        label="Geofencing Notification"
        labelPosition="left"
        component={CheckBox}
        checked={values.isGeofencingNotificationEnabled}
        disabled={isContractTypeLV}
      />
      <Field
        id="isPortugalContractEnabled"
        name="isPortugalContractEnabled"
        label="Portugal"
        labelPosition="left"
        component={CheckBox}
        checked={values.isPortugalContractEnabled}
        disabled={isContractTypeLV || isContractTypeTruck}
      />
      <Field
        id="isLiefkenshokEnabled"
        name="isLiefkenshokEnabled"
        label="Liefkenshok(BE)"
        labelPosition="left"
        component={CheckBox}
        checked={values.isLiefkenshokEnabled}
        disabled={isContractTypeLV || isContractTypeTruck}
      />
      <Field
        id="isSpanishContractEnabled"
        name="isSpanishContractEnabled"
        label="Spain"
        labelPosition="left"
        component={CheckBox}
        checked={values.isSpanishContractEnabled}
        disabled={isContractTypeLV || isContractTypeTruck}
      />
      <Field
        id="isFranceContractEnabled"
        name="isFranceContractEnabled"
        label="France"
        labelPosition="left"
        component={CheckBox}
        checked={values.isFranceContractEnabled}
        disabled={isContractTypeLV || isContractTypeTruck || isContractTypeBus}
      />
      <Field
        id="isHerrentunnelContractEnabled"
        name="isHerrentunnelContractEnabled"
        label="Herrentunnel"
        labelPosition="left"
        component={CheckBox}
        checked={values.isHerrentunnelContractEnabled}
        disabled={isContractTypeLV || isContractTypeTruck}
      />
      <Field
        id="isViapassContractEnabled"
        name="isViapassContractEnabled"
        label="Viapass(BE)"
        labelPosition="left"
        component={CheckBox}
        checked={values.isViapassContractEnabled}
        disabled={isContractTypeLV || isContractTypeTruck}
      />
      <Field
        id="isEasyGoContractEnabled"
        name="isEasyGoContractEnabled"
        label="Easy Go(AT)"
        labelPosition="left"
        component={CheckBox}
        checked={values.isEasyGoContractEnabled}
        disabled={isContractTypeLV || isContractTypeTruck}
      />
      <Field
        id="isGermanyContractEnabled"
        name="isGermanyContractEnabled"
        label="Germany"
        labelPosition="left"
        component={CheckBox}
        checked={values.isGermanyContractEnabled}
        disabled={isContractTypeLV || isContractTypeTruck}
      />
      <Field
        id="isItalyContractEnabled"
        name="isItalyContractEnabled"
        label="Italy"
        labelPosition="left"
        component={CheckBox}
        checked={values.isItalyContractEnabled}
        disabled={isContractTypeLV || isContractTypeTruck}
      />
    </div>
  );
};

export default FranceContract;
