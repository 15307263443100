import { MenuItem, NavBar, Tabs, Text } from '@sede-x/shell-ds-react-framework';
import styled, { css } from 'styled-components';

export const StyledNavbar = styled(NavBar)`
  position: fixed;
  top: 0;
  height: 56px;
  z-index: 2;
`;

export const StyledText = styled(Text)`
  ${({ theme }) => css`
    color: ${theme.text.onSurface.subtle};
  `}
  font-size: 12px;
`;

export const StyledMenuItem = styled(MenuItem)`
  cursor: pointer;
  padding: 10px !important;

  &.shell-menu-item {
    white-space: normal !important;

    & .shell-menu-label {
      background-color: transparent;
      height: 40px !important;
      font-weight: 800 !important;
      color: #757575;
      align-items: center;
    }
  }
`;

export const StyledTabs = styled(Tabs)`
  flex-grow: 1;

  .shell-tabs-nav {
    margin-bottom: 1em;
  }

  .shell-tabs-content-holder {
    overflow: auto;
  }
`;
