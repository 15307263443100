import TextField from 'components/FormCmponents/TextField';
import { Field } from 'formik';

export const LicensePlates = () => (
  <>
    <Field
      id="slovakiaLicensePlate"
      name="slovakiaLicensePlate"
      label="Slovakia License Plate"
      labelPosition="left"
      component={TextField}
      placeholder="Type here"
    />
    <Field
      id="czechRepublicLicensePlate"
      name="czechRepublicLicensePlate"
      label="Czech Republic License Plate"
      labelPosition="left"
      component={TextField}
      placeholder="Type here"
    />
    <Field
      id="cleanLicensePlate"
      name="cleanLicensePlate"
      label="Clean License Plate"
      labelPosition="left"
      component={TextField}
      placeholder="Type here"
      required
    />
  </>
);
