import {
  Button,
  Icons,
  Modal,
  Sizes,
  TextInput,
  Flexbox,
  RadioButton,
  ModalAction,
  Tooltip
} from '@sede-x/shell-ds-react-framework';
import QueryError from 'components/QueryError';
import Table from 'components/Table/Table';
import { createColumnHelper } from '@tanstack/react-table';
import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api';
import { columnBuilder, createMap } from 'utils/helpers';
import { useSdsPagination } from 'hooks/use-pagination';
import { useFormikContext } from 'formik';
import OverviewColumns from 'components/Table/columns/overviewColumns.json';
import { STALE_TIME } from 'react-query';
import { OBU, ContractMap } from '../types';

const StyledModal = styled(Modal)`
  .shell-modal-container-content {
    height: 80vh;
  }

  .shell-modal-container-wrap {
    z-index: 5;
  }
`;

const columnHelper = createColumnHelper();
const columns = [
  columnHelper.display({
    id: 'actions',
    size: 20,
    cell: ({ row }) => (
      <Flexbox gap="12px" alignItems="center">
        <RadioButton
          checked={row.getIsSelected()}
          onChange={row.getToggleSelectedHandler()}
          data-testid={`rowcheck-${row.index}`}
          size={Sizes.ExtraSmall}
        />
      </Flexbox>
    ),
    header: ''
  }),
  ...columnBuilder(OverviewColumns.Contract)
];

export const StyledTextInput = styled(TextInput)`
  > div[type='suffix'] {
    padding: 0px;
  }
`;

async function fetchCustomerContract(
  customerId: string,
  pageNumber: number,
  pageSize: number
) {
  return customerInstance.post('contracts/contract-list', {
    customerId,
    pageNumber,
    pageSize
  });
}

const DEFAULT_LIMIT = 20;

function ContractSelection() {
  const [open, setOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<unknown[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_LIMIT);
  const [searchParam, setSearchParam] = useState('');
  const [fetchEnabled, setFetchEnabled] = useState(true);
  const [contractMap, setContractMap] = useState<ContractMap>({});

  const { setFieldValue, values } = useFormikContext<OBU>();

  const { isError, data, isLoading, isFetching, remove } = useQuery({
    queryKey: ['customer-contract', page, pageSize, searchParam],
    queryFn: () =>
      fetchCustomerContract(values.customerId ?? '', page, pageSize)
        .then((res) => res.data)
        .finally(() => setFetchEnabled(false)),
    enabled: fetchEnabled,
    staleTime: STALE_TIME
  });

  const handleChangePagination = (pageNumber: number, newPageSize: number) => {
    setSelectedRows([]);
    setPage(pageNumber);
    setPageSize(newPageSize);
    setFetchEnabled(true);
  };
  const paginationProps = useSdsPagination(handleChangePagination);
  const { resetPagination } = paginationProps;

  const handleOnClose = () => {
    resetPagination();
    setOpen(false);
    setSelectedRows([]);
    setSearchParam('');
    remove();
  };

  const [selectedContract] = selectedRows;

  const actions = [
    {
      label: 'Assign',
      action: async () => {
        setFieldValue(
          'contractId',
          (selectedContract as { contractID?: string }).contractID
        );
        handleOnClose();
      },
      props: {
        variant: 'filled',
        iconPosition: 'left',
        size: 'small',
        hidden: isError || isLoading,
        disabled: !selectedRows.length,
        'data-testid': `Contract-assign-btn`
      }
    }
  ];
  const { data: contractData, count: totalNumberOfRecords } = data || {};

  useEffect(() => {
    if (contractData?.length) {
      setContractMap(
        createMap(contractData, 'contractID') as unknown as ContractMap
      );
    }
  }, [contractData]);

  return (
    <div>
      <div className="flex items-end gap-2 h-full ">
        <StyledTextInput
          readOnly
          suffix={{
            node: (
              <Button
                iconOnly
                icon={<Icons.Search />}
                variant="transparent"
                onClick={() => {
                  setOpen(true);
                }}
                aria-label="Contract selector"
                data-testid="contract-selection-icon"
              />
            )
          }}
          placeholder="Search"
          autoComplete="off"
          value={
            (contractMap[values.contractId ?? ''] as { categoryName?: string })
              ?.categoryName ?? ''
          }
          onClick={() => {
            setOpen(true);
          }}
          data-testid="contract-selection"
        />
        <div>
          <Tooltip
            overlay="open"
            placement="top"
            trigger={
              <Link
                to={`/customer/${values.customerId}/${values.contractId}?type=contract`}
                target="_blank"
              >
                <Button size="small">Open</Button>
              </Link>
            }
            arrow={false}
          />
        </div>
      </div>
      {open && (
        <StyledModal
          width="70%"
          open={open}
          size={Sizes.Small}
          title="Contract Selection"
          maskClosable={false}
          actions={actions as ModalAction[]}
          mask
          onClose={handleOnClose}
          contentScrollable
          bodyPadding={false}
        >
          <div className="mr-2 flex p-3 flex-col flex-grow overflow-auto h-full ">
            <QueryError isError={isError} isLoading={isFetching}>
              <Table
                columns={columns}
                data={contractData || []}
                columnSelection={false}
                exportEnabled={false}
                enableMultiRowSelection={false}
                onSelectedRowsChange={setSelectedRows}
                paginationData={{
                  ...paginationProps,
                  total: totalNumberOfRecords
                }}
              />
            </QueryError>
          </div>
        </StyledModal>
      )}
    </div>
  );
}
export default ContractSelection;
