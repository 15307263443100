import {
  Alignments,
  Button,
  Checkbox,
  Icons,
  Modal,
  Sizes
} from '@sede-x/shell-ds-react-framework';
import { useState, ChangeEvent } from 'react';
import { Label } from 'components/Label/Label';
import { Customers } from 'utils/Interfaces/Providers';
import { addException } from 'utils/helpers';
import { actionButtonEndpoints } from 'api/apiEndpoints';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import styled from 'styled-components';
import { AxiosError } from 'axios';
import CustomerSearch from '../CustomerSearch/CustomerSearch';

interface ExceptionType {
  forCustomerId: string;
  toCustomerAnyChecked: boolean;
  toCustomerId?: string;
}
const StyledModal = styled(Modal)`
  .shell-modal-container-content {
    height: auto;
    padding: 20px;
  }
`;

function AddException({ onSuccess }: Readonly<{ onSuccess?: () => void }>) {
  const [isOpen, setIsOpen] = useState(false);
  const [exception, setException] = useState<ExceptionType>({
    forCustomerId: '',
    toCustomerAnyChecked: false,
    toCustomerId: ''
  });
  const { errorDialog, successDialog } = useConfirmDialogs();

  const handleSubmitForCustomer = (rowSelection?: Customers) => {
    setException({
      ...exception,
      forCustomerId: rowSelection?.customerId ?? ''
    });
  };

  const handleSubmitToCustomer = (rowSelection?: Customers) => {
    setException({
      ...exception,
      toCustomerId: rowSelection?.customerId ?? ''
    });
  };

  const handleAnychecked = (e: ChangeEvent<HTMLInputElement>) => {
    setException({
      ...exception,
      toCustomerAnyChecked: e.target.checked
    });
  };

  const clearException = () => {
    setException({
      forCustomerId: '',
      toCustomerAnyChecked: false,
      toCustomerId: ''
    });
  };

  const handleAddException = () => {
    const requestPayload = { ...exception };
    if (requestPayload.toCustomerAnyChecked) {
      delete requestPayload.toCustomerId;
    }
    addException(actionButtonEndpoints.t4eTransferObuExceptions, requestPayload)
      .then((_response) => {
        setIsOpen(false);
        successDialog('', 'Record updated successfully');
        onSuccess?.();
        clearException();
      })
      .catch((error) => {
        let message = 'Your request has been failed. Please try again.';
        const axiosError = error as AxiosError;
        if (
          axiosError.response &&
          typeof axiosError.response?.data === 'string'
        ) {
          message = axiosError.response?.data;
        }
        errorDialog('', message);
      });
  };

  const onClose = () => {
    setIsOpen(false);
    clearException();
  };
  return (
    <div className="flex flex-row justify-end h-full">
      <Button
        data-testid="add-exception-Btn"
        onClick={() => setIsOpen(true)}
        id="blockbtn"
        size="small"
        icon={<Icons.Add />}
      >
        Add Exception
      </Button>

      {isOpen && (
        <StyledModal
          width="40%"
          title="Add Exception"
          size={Sizes.Small}
          maskClosable={false}
          mask
          contentScrollable
          actionsAlignment={Alignments.Right}
          centered
          open={isOpen}
          onClose={onClose}
          actions={[
            {
              label: 'Submit',
              action: () => {
                handleAddException();
              },
              props: {
                disabled:
                  !exception.forCustomerId ||
                  !(exception.toCustomerAnyChecked || exception.toCustomerId)
              }
            }
          ]}
        >
          <div className="w-10/12 ">
            <CustomerSearch
              label="From Customer"
              title="Add Exception for customer"
              value={exception.forCustomerId}
              onSubmit={handleSubmitForCustomer}
            />
          </div>
          <div className="flex justify-between pt-5">
            <div className="w-10/12 ">
              <CustomerSearch
                label="To Customer"
                title="Add Exception to customer"
                value={exception.toCustomerId}
                onSubmit={handleSubmitToCustomer}
                anychecked={exception.toCustomerAnyChecked}
              />
            </div>

            <div className="flex pt-4 pl-5 gap-3 w-2/12">
              <Label text="Any" />
              <Checkbox
                size="small"
                checked={exception.toCustomerAnyChecked}
                onChange={handleAnychecked}
                data-testid="any-checkbox"
              />
            </div>
          </div>
        </StyledModal>
      )}
    </div>
  );
}

export default AddException;
