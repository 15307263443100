import { AuthContext } from 'auth/AuthContext';
import { useEffect, useLayoutEffect, useState, useContext } from 'react';
import { useSdsPagination } from 'hooks/use-pagination';
import { useStaticData } from 'hooks/useStaticData';
import { SelectedMenu } from 'components/SideMenu/types';
import { IAuthContext } from 'auth/Types';
import {
  createExportAllRequest,
  filterReportMenuBasedOnRoles,
  getReportDetails
} from 'utils/helpers';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { exportAllEndpoints } from 'api/apiEndpoints';
import SideMenu from '../../components/SideMenu/SideMenu';
import ReportsDetails from '../../components/ReportsContent/ReportsDetails';
import { getProvidersColumn } from '../../components/Table/columns/providersColumns';
import ReportsMenuUnselected from '../../components/ReportsContent/ReportsMenuUnselected';
import { useReport } from '../../hooks/useReports';
import { ProvidersMenu } from './ProvidersMenu';
import { ProvidersContainer } from './style';
import { ParamsType } from '../../components/ContentHeader/types';
import { ProvidersData, TollMap, TollObject } from './types';
import ProviderFilters from '../../assets/filters/ProviderFilters.json';

const SUCCESS_CODE = 200;

const Providers = () => {
  const [menu, setMenu] = useState<string>('');
  const [menuLabel, setMenuLabel] = useState<string>('');
  const [exportAllEnabled, setExportAllEnabled] = useState<boolean>();
  const [parentMenu, setParentMenu] = useState<string>('');
  const [reportName, setReportName] = useState<string>('');
  const [params, setParams] = useState<ParamsType>({});
  const [tollMap, setTollMap] = useState<TollMap>({});
  const { errorDialog, successDialog } = useConfirmDialogs();
  const {
    mutate: getReport,
    data,
    isLoading,
    isError,
    reset: resetData
  } = useReport(menu);

  const { data: tollData } = useStaticData('toll', 'toll', true);
  const { tokenData } = useContext<IAuthContext>(AuthContext);

  const { data: userRoles } = useStaticData(
    'userroles',
    'Auth/userroles',
    !!tokenData?.mail,
    null,
    { retryOnMount: false }
  );

  const handleChangePagination = (pageNumber: number, newPageSize: number) => {
    getReport({
      pageNumber,
      pageSize: newPageSize,
      tollId: tollMap[parentMenu]?.tollId,
      ...params
    });
  };
  const paginationProps = useSdsPagination(handleChangePagination);
  const { resetPagination } = paginationProps;

  useEffect(() => {
    if (tollData) {
      const obj: TollMap = {};
      tollData.data.forEach((tollObj: TollObject) => {
        obj[tollObj.name] = tollObj;
      });
      setTollMap(obj);
    }
  }, [tollData]);

  useLayoutEffect(() => {
    setParams({});
    resetPagination();
    resetData();
  }, [menu]);

  const getReportsData = () => {
    resetPagination();
    getReport({
      pageNumber: 1,
      pageSize: 20,
      tollId: tollMap[parentMenu]?.tollId,
      ...params
    });
  };

  const handleMenuClick = (selectedMenu: SelectedMenu) => {
    const { keyPath, key, exportAll, reportName: name } = selectedMenu;
    setMenu(key);
    setParentMenu(keyPath[keyPath.length - 1]);
    setMenuLabel(selectedMenu.label);
    setExportAllEnabled(exportAll);
    name && setReportName(name);
  };

  const onClickExportAll = (errors?: ParamsType) => {
    if (errors && Object.keys(errors).length) {
      errorDialog('', 'Please enter correct data.');
    } else {
      const reportsDetails = getReportDetails(
        reportName,
        tollMap[parentMenu]?.tollId,
        userRoles
      );

      const requestPayload = {
        reportName,
        reportID: reportsDetails?.reportID,
        eventType: reportsDetails?.eventType ?? reportsDetails?.reportName,
        reportType: reportName,
        tollId: tollMap[parentMenu]?.tollId,
        typeOfReport: reportsDetails?.typeOfReport,
        ...params
      };

      createExportAllRequest(
        exportAllEndpoints[menu] ?? exportAllEndpoints.exportAll,
        requestPayload
      )
        .then((respose) => {
          if (respose.status === SUCCESS_CODE) {
            successDialog(
              '',
              'Your download request has been received. Notifications will be updated as soon as the download link is available. Thank you for your patience!'
            );
          }
        })
        .catch(() => {
          errorDialog(
            '',
            'Your download request has been failed. Please try again.'
          );
        });
    }
  };

  return (
    <ProvidersContainer className="flex p-3">
      <div className="flex flex-shrink-0 flex-col bg-white mr-2 overflow-y-scroll no-scrollbar">
        <SideMenu
          setMenu={handleMenuClick}
          menuItems={filterReportMenuBasedOnRoles(ProvidersMenu, userRoles)}
          searchEnabled
        />
      </div>
      <div className="mr-2 flex flex-col flex-grow overflow-auto ">
        {!menu ? (
          <ReportsMenuUnselected
            welcomeTitle="Welcome to Providers Section"
            content="In this section you can find provider specific reports and can filter and export
          them, Please select the report from left hand side menu."
          />
        ) : (
          <ReportsDetails<ProvidersData>
            title={menuLabel}
            menuKey={menu}
            onButtonClick={getReportsData}
            data={data?.data}
            cols={getProvidersColumn(menu)}
            params={params}
            isLoading={isLoading}
            isError={isError}
            setParams={setParams}
            paginationProps={{
              ...paginationProps,
              total: data?.totalNumberOfRecords
            }}
            filterObject={{ ...ProviderFilters }}
            onClickExportAll={onClickExportAll}
            exportAllEnabled={exportAllEnabled}
          />
        )}
      </div>
    </ProvidersContainer>
  );
};

export default Providers;
