import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api';
import { customerEndPoints } from 'api/apiEndpoints';
import QueryError from 'components/QueryError';
import Table from 'components/Table/Table';
import { getOverviewColumns } from 'components/Table/columns/overviewColumns';
import React, { useEffect, useState } from 'react';
import { STALE_TIME } from 'react-query';
import { ColumnDef } from '@tanstack/react-table';
import { useFormikContext } from 'formik';
import { useSdsPagination } from 'hooks/use-pagination';
import { Contract } from './types';
import { NewOBU } from '../../types';

async function fetchContractList(
  tollId: string,
  customerId: string,
  pageNumber: number,
  size: number
) {
  const { data } = await customerInstance.post(
    customerEndPoints.getCustomerContract,
    {
      tollId,
      customerId,
      pageNumber,
      pageSize: size
    }
  );
  return data;
}

interface ContractSelectionProps {
  customerId: string;
}

const DEFAULT_PAGE_SIZE = 20;

const ContractSelection: React.FC<ContractSelectionProps> = ({
  customerId
}) => {
  const [selectedRows, setSelectedRows] = useState<Contract[]>([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const { values, setFieldValue } = useFormikContext<NewOBU>();

  const handleChangePagination = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
    setSelectedRows([]);
  };

  const paginationProps = useSdsPagination(handleChangePagination);

  const { isError, data, isLoading } = useQuery({
    queryKey: [
      'obu-contract-list',
      values.tollID,
      pageNumber,
      pageSize,
      customerId
    ],
    queryFn: () =>
      fetchContractList(values.tollID ?? '', customerId, pageNumber, pageSize),
    staleTime: STALE_TIME,
    refetchOnWindowFocus: 'always',
    enabled: !!values.tollID
  });

  useEffect(() => {
    if (selectedRows.length) {
      const [selectedContract] = selectedRows;
      setFieldValue('contractID', selectedContract.contractID);
    } else {
      setFieldValue('contractID', undefined);
    }
  }, [selectedRows]);

  const { data: contractList, count } = data || {};

  return (
    <QueryError isLoading={isLoading} isError={isError}>
      <Table<Contract>
        data={contractList}
        columns={getOverviewColumns('obuContractList') as ColumnDef<Contract>[]}
        columnSelection={false}
        exportEnabled={false}
        enableMultiRowSelection={false}
        onSelectedRowsChange={setSelectedRows}
        paginationData={{ ...paginationProps, total: count }}
      />
    </QueryError>
  );
};

export default ContractSelection;
