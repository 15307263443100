import { Customers } from 'utils/Interfaces/Providers';
import { axiosInstance } from 'api';
import { actionButtonEndpoints } from 'api/apiEndpoints';
import { CzechUnknownContracts } from 'utils/Interfaces/Actions';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { AxiosError } from 'axios';
import { BEError } from 'utils/Interfaces';
import GFNSearch from '../GFNSearch/GFNSearch';

function AssignGFN<TData>({
  selectedRows,
  onSuccess
}: Readonly<{
  selectedRows: Array<TData>;
  onSuccess?: () => void;
}>) {
  const { errorDialog, successDialog } = useConfirmDialogs();
  const handleAssignGFN = (selectedCustomer?: Customers) => {
    const [selectedContract] = selectedRows;

    const payload = {
      contractNumber: (selectedContract as CzechUnknownContracts)
        .contractNumber,
      customerId: selectedCustomer?.customerId
    };

    axiosInstance
      .post(actionButtonEndpoints.czechUnknownContractsAssign, [payload])
      .then((_response) => {
        successDialog('', 'Record updated successfully');
        onSuccess?.();
      })
      .catch((error: AxiosError) => {
        let message = 'Failed to assign GFN, please try again later';
        const { messages: errorMessage } = error.response?.data as BEError;
        if (
          errorMessage &&
          Array.isArray(errorMessage) &&
          errorMessage.length
        ) {
          message = errorMessage.join(', ');
        }
        errorDialog('', message);
      });
  };
  return (
    <div data-testid="czechUnknownContract-ActionSection">
      <GFNSearch
        onSubmit={handleAssignGFN}
        title="Assign GFN"
        disabled={!selectedRows.length}
      />
    </div>
  );
}

export default AssignGFN;
