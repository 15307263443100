import { useContext, useEffect, useState, JSX } from 'react';
import { ConfirmModalType, IConfirmConfig } from 'utils/Interfaces/confirm';
import {
  ConfirmContext,
  confirmInitialValue
} from '../common/confirmContextProvider';

export interface IUseConfirm extends IConfirmConfig {
  isConfirmed: (
    title: string,
    description: string,
    primaryAction?: string,
    secondaryAction?: string,
    type?: ConfirmModalType,
    primaryIcon?: JSX.Element,
    secondaryIcon?: JSX.Element
  ) => Promise<boolean>;
}

const useConfirm = (): IUseConfirm => {
  const [confirm, setConfirm] = useContext(ConfirmContext);
  const [needsCleanup, setNeedsCleanup] = useState(false);

  const isConfirmed = (
    title: string,
    description: string,
    primaryAction?: string,
    secondaryAction?: string,
    type?: ConfirmModalType,
    primaryIcon?: JSX.Element,
    secondaryIcon?: JSX.Element
  ) => {
    const promise = new Promise((resolve, reject) => {
      setConfirm({
        title,
        description,
        primaryActionLabel: primaryAction,
        secondaryActionLabel: secondaryAction,
        isOpen: true,
        proceed: resolve,
        cancel: reject,
        type,
        primaryActionIcon: primaryIcon,
        secondaryActionIcon: secondaryIcon
      });
      setNeedsCleanup(true);
    });

    const reset = () => {
      setConfirm(confirmInitialValue[0]);
      setNeedsCleanup(false);
    };

    return promise.then(
      () => {
        reset();
        return true;
      },
      () => {
        reset();
        return false;
      }
    );
  };

  useEffect(
    () => () => {
      if (confirm.cancel && needsCleanup) {
        confirm.cancel();
      }
    },
    [confirm, needsCleanup]
  );

  return {
    ...confirm,
    isConfirmed
  };
};

export default useConfirm;
