import {
  Button,
  FormField,
  Heading,
  Icons,
  Popover,
  Positions,
  Text,
  TextInput,
  Variants
} from '@sede-x/shell-ds-react-framework';
import { useMutation } from '@tanstack/react-query';
import { ChangeEvent, useEffect, useState, KeyboardEvent } from 'react';
import styled from 'styled-components';
import { customerInstance } from 'api';
import { searchByTypeEndpoints } from 'api/apiEndpoints';
import { ChevronDown } from '@sede-x/shell-ds-react-framework/build/esm/components/Icon/components';
import { MenuInfo } from 'rc-menu/lib/interface';
import { getOverviewColumns } from 'components/Table/columns/overviewColumns';
import { useSdsPagination } from 'hooks/use-pagination';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import PopupMenu from './PopupMenu';
import OverviewDetails from './OverviewDetails/OverviewDetails';
import CustomerNew from './CustomerNew/CustomerNew';

export interface SearchPayload {
  pageNumber: number;
  pageSize: number;
  searchString?: string;
  requestId?: string;
}

const Container = styled.div`
  height: calc(100vh - 56px);
`;
const StyledTextInput = styled(TextInput)`
  &.shell-text-input {
  }
`;
const renderError = (error: string) => {
  if (error) {
    return <div className="text-sm text-shellRed">{error}</div>;
  }
  return <></>;
};
const MINLEN_3 = 3;
const MINLEN_6 = 6;
const MINLEN_9 = 9;

const Customer = () => {
  const [searchType, setSearchType] = useState('Customer');
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [errorData, setErrorData] = useState<string>('');
  const [touched, setTouched] = useState<boolean>(false);
  const { errorDialog } = useConfirmDialogs();

  const {
    mutate: getBySearchType,
    data,
    isLoading,
    isError,
    reset: resetData
  } = useMutation(
    ['getBySearchType'],
    (searchPayload: SearchPayload) =>
      customerInstance.post(searchByTypeEndpoints[searchType], searchPayload, {
        headers: {
          'Content-Type': 'application/json'
        }
      }),
    {
      cacheTime: 0,
      retry: false
    }
  );

  const validate = () => {
    let errors: string = '';
    const mandatoryMsg = 'Please fill out this mandatory field';
    if (!searchKeyword) {
      errors = mandatoryMsg;
    } else {
      switch (searchType) {
        case 'Customer':
        case 'Vehicle':
        case 'Contract':
          if (searchKeyword.length < MINLEN_3) {
            errors = 'Please enter atleast 3 characters';
          }
          break;
        case 'Card':
          if (searchKeyword.length < MINLEN_6) {
            errors = 'Please enter atleast 6 characters';
          }
          break;
        case 'OBU':
          if (searchKeyword.length < MINLEN_9) {
            errors = 'Please enter atleast 9 characters';
          }
          break;
        default:
          return errors;
      }
    }
    setErrorData(errors);
    return errors;
  };
  const handleBlur = () => {
    setTouched(true);
  };
  useEffect(() => {
    validate();
  }, [searchKeyword, touched]);

  useEffect(() => {
    resetData();
  }, [searchType]);

  const handleMenuSelect = (selectedMenu: MenuInfo) => {
    setSearchKeyword('');
    setTouched(false);
    setErrorData('');
    const { key } = selectedMenu;
    setSearchType(key);
  };

  const handleChangePagination = (pageNumber: number, newPageSize: number) => {
    getBySearchType({
      pageNumber,
      pageSize: newPageSize,
      searchString: searchKeyword
    });
  };
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      getSearchData();
    }
  };
  const paginationProps = useSdsPagination(handleChangePagination);
  const { resetPagination } = paginationProps;

  const getSearchData = () => {
    const errors = validate();
    if (!Object.keys(errors).length) {
      resetPagination();
      getBySearchType({
        pageNumber: 1,
        pageSize: 20,
        searchString: searchKeyword
      });
    } else {
      errorDialog('', 'Please enter correct data.');
    }
  };

  const { data: overviewData, count } = data?.data || {};

  return (
    <Container className="flex p-3">
      <div className="flex bg-white mr-2 h-full flex-col w-full p-10">
        <div className="flex  flex-col w-full  p-[5px] ">
          <div className="flex flex-row justify-between">
            <Heading level={2} className="font-bold font-">
              <Text prominence="strong" size="large" type="p" bold>
                What are you looking for?
              </Text>
            </Heading>
            <CustomerNew />
          </div>
          <div>
            <FormField label="Search By" className="mt-5" mandatory>
              <div className=" w-full md:w-[40%] lg:w-[40%]">
                <div className="grid grid-rows border grid-flow-col">
                  <Popover
                    arrow
                    popup={
                      <PopupMenu
                        setMenu={handleMenuSelect}
                        selectedMenu={searchType}
                      />
                    }
                    popupPlacement="bottomRight"
                    getPopupContainer={(triggerNode: HTMLElement) =>
                      triggerNode.parentElement as HTMLElement
                    }
                  >
                    <Button
                      variant={Variants.Transparent}
                      icon={<ChevronDown />}
                      iconPosition={Positions.Right}
                      className="w-full"
                      data-testid="search-by-menu"
                    >
                      {searchType}
                    </Button>
                  </Popover>
                  <StyledTextInput
                    value={searchKeyword}
                    suffix={{
                      node: (
                        <Button
                          iconOnly
                          size="small"
                          icon={<Icons.Search />}
                          variant="transparent"
                          onClick={() => {
                            getSearchData();
                          }}
                          data-testid="search-by-type"
                          aria-label="Search by Type"
                        />
                      )
                    }}
                    size="small"
                    data-testid="keyword-value-field"
                    placeholder="Keywords"
                    autoComplete="off"
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setSearchKeyword(e.target.value);
                      setTouched(true);
                    }}
                    onKeyDown={handleKeyDown}
                    invalid={!!errorData && touched}
                    onBlur={handleBlur}
                  />
                </div>
                <div className="flex justify-end">
                  <span>{touched && renderError(errorData)}</span>
                </div>
              </div>
            </FormField>
          </div>
        </div>
        <div className=" flex flex-col flex-grow overflow-auto">
          {!data && !isLoading && !isError ? (
            <div className="flex h-full mt-3 p-[5px]">
              <div className="flex flex-col w-full h-full items-center justify-center bg-[#F5F5F5]">
                <Text
                  prominence="strong"
                  size="large"
                  type="p"
                  bold
                  data-testid="reportsWelcomeText"
                >
                  Welcome to eTM
                </Text>
                <Text
                  prominence="subtle"
                  size="medium"
                  type="p"
                  className="break-words w-4/12 text-center"
                >
                  Please select and search by type and enter the search keywords
                </Text>
              </div>
            </div>
          ) : (
            <OverviewDetails<unknown>
              data={overviewData ?? []}
              cols={getOverviewColumns(searchType)}
              paginationProps={{
                ...paginationProps,
                total: count
              }}
              isLoading={isLoading}
              isError={isError}
              selectedOverview={searchType}
            />
          )}
        </div>
      </div>
    </Container>
  );
};

export default Customer;
