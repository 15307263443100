import { Alert, Modal } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const StyledAlert = styled(Alert)`
  .shell-button[filled='true'] {
    background-color: rgba(255, 198, 0, 1);
  }

  [actionsalignment='bottom'] {
    justify-content: right;
    overflow: auto;
  }
`;

export const StyledModal = styled(Modal)`
  & .uiRefresh-confirm-modal-content {
    min-height: auto !important;
  }

  & .uiRefresh-confirm-modal-body {
    width: 100%;
  }
`;
