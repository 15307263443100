import QueryError from 'components/QueryError';
import { ExpandableTable } from 'components/Table/ExpandableTable';
import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api/customerInstance';
import {
  getOverviewColumns,
  detailAction
} from 'components/Table/columns/overviewColumns';
import { useState } from 'react';
import { useSdsPagination } from 'hooks/use-pagination';
import { Text } from '@sede-x/shell-ds-react-framework';
import { Row } from '@tanstack/react-table';
import { STALE_TIME } from 'react-query';
import { Drawer } from 'components/Drawer/Drawer';
import ContactDetails from '../ContactDetails/ContactDetails';
import { Contact } from '../types';
import AddContact from '../AddContact/AddContact';

interface CustomerDataProps {
  customerId: string;
}
export interface SearchPayload {
  pageNumber: number;
  pageSize: number;
  customerId?: string;
  requestId?: string;
}
const DEFAULT_PAGE_SIZE = 20;
const SEARCH_CONTACT_QUERY_KEY = 'search-contact';

const ContactList: React.FC<CustomerDataProps> = ({ customerId }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [openDetails, setOpenDetails] = useState(false);
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  const cacheKey = SEARCH_CONTACT_QUERY_KEY;

  const { data, isLoading, isError } = useQuery(
    [cacheKey, customerId, pageSize, pageNumber],
    async () => {
      const result = await customerInstance.post(
        'contact/search-contact',
        {
          pageNumber,
          pageSize,
          customerId
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      return result.data;
    },
    {
      staleTime: STALE_TIME
    }
  );

  const handleChangePagination = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
  };

  const paginationProps = useSdsPagination(handleChangePagination);

  const handleClickDetails = (row: Row<unknown>) => {
    setSelectedContact(row.original as Contact);
    setOpenDetails(true);
  };

  const handleOnClose = () => {
    setOpenDetails(false);
    setSelectedContact(null);
  };

  const detailIcons = detailAction(handleClickDetails);

  return (
    <div
      className="flex flex-col grow overflow-y-auto pb-3 gap-2 bg-shellExtraPaleGrey2"
      data-testid="contact-list"
    >
      <div className=" bg-white p-5">
        <div className="flex flex-row justify-between items-center">
          <Text prominence="strong" size="medium" type="p" bold>
            Contact List
          </Text>
          <div className="flex justify-end gap-3">
            <AddContact customerId={customerId} />
          </div>
        </div>
      </div>
      <QueryError isLoading={isLoading} isError={isError}>
        <ExpandableTable
          data={data?.data ?? []}
          columns={[...detailIcons, ...getOverviewColumns('Contact')]}
          columnSelection={false}
          paginationData={{
            ...paginationProps,
            total: data?.count
          }}
          exportEnabled={false}
          stickyColumns={['expandAction']}
          selectedRows={{
            [selectedContact?.contactID as string]: true
          }}
          getRowId={(row) => (row as Contact).contactID as string}
        />
      </QueryError>
      <Drawer
        open={openDetails}
        header="Contact Details"
        mask={false}
        onClose={handleOnClose}
        width="70%"
        bodyPadding={false}
        sticky
      >
        <div className="flex grow h-full">
          <ContactDetails
            contactID={selectedContact?.contactID}
            onSuccess={handleOnClose}
            cacheKey={cacheKey}
          />
        </div>
      </Drawer>
    </div>
  );
};

export default ContactList;
