import { AxiosError } from 'axios';
import { BEError } from 'utils/Interfaces';

export const errorHelper = (error: unknown, defaultError: string) => {
  let message = defaultError;

  const axiosError = error as AxiosError;

  if (
    axiosError?.response?.data &&
    typeof axiosError.response?.data === 'string'
  ) {
    message = axiosError.response?.data;
  }

  if (axiosError.response && typeof axiosError.response?.data === 'object') {
    const { messages } = axiosError.response?.data as BEError;
    if (messages && Array.isArray(messages) && messages.length) {
      const result = messages.map((msg: string) => `<li>${msg}</li>`);
      message = `<ul class="list-disc 
          list-outside p-2 m-2">${result.join('')}</ul>`;
    }

    if (messages && typeof messages === 'string') {
      message = messages;
    }
  }

  return message;
};
