import { Modal, Select } from '@sede-x/shell-ds-react-framework';
import { FieldProps } from 'formik';
import React, { useEffect, useState } from 'react';
import { SelectProps } from 'components/SDSSelect/types';
import { useStaticData } from 'hooks/useStaticData';
import styled from 'styled-components';
import FormLabel from './FormLabel';

interface CustomSelectComponent {
  onOption?: boolean;
  dataEndPoint?: string;
  dataField?: string;
  dataLabelField?: string;
  dataIdentifier?: string;
  options?: SelectProps['options'];
  onChange?: (value: string) => void;
  modalContent?: (closeModal: () => void) => React.ReactNode;
  isCustomerInstance?: boolean;
}

const StyledModal = styled(Modal)`
  .ui-refresh-select-content {
    height: 80vh;
  }
`;

const SelectField = (props: FieldProps & CustomSelectComponent) => {
  const {
    field,
    form: { touched, errors, setFieldValue },
    modalContent,
    ...rest
  } = props;

  const [menuOptions, setMenuOptions] = useState<SelectProps['options']>();
  const [open, setOpen] = useState(false);

  const {
    onOption,
    dataEndPoint,
    dataField = 'value',
    dataLabelField = 'label',
    dataIdentifier,
    isCustomerInstance = false,
    options,
    onChange
  } = rest;

  const { data, isSuccess } = useStaticData(
    dataEndPoint as string,
    dataEndPoint as string,
    onOption ?? false,
    undefined,
    undefined,
    isCustomerInstance
  );

  useEffect(() => {
    if (isSuccess) {
      const results: SelectProps['options'] = [];
      data.data.forEach((dataObj: { [x: string]: string }) => {
        if (dataObj[dataField]) {
          results.push({
            value: dataIdentifier
              ? dataObj[dataIdentifier]
              : dataObj[dataField],
            label: dataObj[dataLabelField]
          });
        }
      });
      setMenuOptions(results);
    }
  }, [data, isSuccess, dataField, dataLabelField, dataIdentifier]);

  useEffect(() => {
    if (!onOption) {
      setMenuOptions(options);
    }
  }, [options]);

  const handleOnChange = (newValue: string) => {
    setFieldValue(field.name, newValue);
  };

  const closeModal = () => setOpen(false);

  return (
    <div>
      <FormLabel {...field} {...rest}>
        <Select
          {...field}
          {...rest}
          invalid={!!(touched[field.name] && errors[field.name])}
          data-testid={`${field.name}-select-field`}
          options={menuOptions}
          optionLabelProp="label"
          optionFilterProp="label"
          getPopupContainer={() => document.body}
          onChange={onChange ?? handleOnChange}
          onClick={() => modalContent && setOpen(true)}
          dropdownStyle={{ display: modalContent ? 'none' : '' }}
        />

        {touched[field.name] && errors[field.name] && (
          <div className="text-red-500 text-sm">
            {errors[field.name] as string}
          </div>
        )}
      </FormLabel>
      {modalContent && (
        <StyledModal
          width="40%"
          contentScrollable
          zIndex={3}
          open={open}
          onClose={closeModal}
          title=""
          prefixCls="ui-refresh-select"
          maskClosable={false}
        >
          <div className="flex flex-col h-full overflow-auto">
            {modalContent(closeModal)}
          </div>
        </StyledModal>
      )}
    </div>
  );
};

export default SelectField;
