import { FormikErrors } from 'formik';
import { CardType } from '../types';

export const CardValidate = (values: CardType) => {
  const errors: FormikErrors<CardType> = {};
  const MAX_LENGTH = 19;
  const mandatoryMsg = 'Please fill out this mandatory field';

  if (!values.cardNo) {
    errors.cardNo = mandatoryMsg;
  } else if (values.cardNo.length < MAX_LENGTH) {
    errors.cardNo = 'Please enter 19 digits';
  } else if (
    !values.cardNo.startsWith('7002') &&
    !values.cardNo.startsWith('7077')
  ) {
    errors.cardNo =
      'Please enter a valid card number starting with 7002 or 7077';
  }
  if (!values.validFrom) {
    errors.validFrom = mandatoryMsg;
  }
  if (!values.validTo) {
    errors.validTo = mandatoryMsg;
  }
  return errors;
};
