import { SubMenu, MenuItem } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const SubMenuItem = styled(SubMenu)`
  cursor: pointer;

  .shell-menu-submenu-title {
    .shell-menu-label-text {
      display: flex;
      gap: 10px;
      justify-content: flex-start;
      align-items: center;
    }

    .shell-menu-submenu-title-content {
      background-color: transparent !important;
    }
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  cursor: pointer;

  &.shell-menu-item {
    white-space: normal !important;

    &.shell-menu-item-active .shell-menu-label {
      background-color: transparent;
    }
  }
`;
