import { ColumnDef, Row } from '@tanstack/react-table';
import Table from 'components/Table/Table';
import { PaginationProps } from 'components/Table/types';
import QueryError from 'components/QueryError';

interface Props<TData> {
  data: TData[];
  cols: ColumnDef<TData>[];
  isLoading?: boolean;
  isError?: boolean;
  paginationProps: PaginationProps;
  disableDetailSection?: boolean;
  selectedOverview?: string;
}

interface RowData {
  customerID: string;
  vehicleID?: string;
  obuid?: string;
  contractID?: string;
  cardID?: string;
}

function OverviewDetails<TData>({
  data,
  cols,
  isLoading,
  isError,
  paginationProps,
  disableDetailSection = false,
  selectedOverview
}: Readonly<Props<TData>>) {
  const handleDoubleClick = (row: Row<TData>) => {
    let customerID = '';
    let type = '';
    let id: string | undefined = '';
    switch (selectedOverview) {
      case 'Customer':
        customerID = (row.original as RowData).customerID;
        type = 'customer';
        break;
      case 'Vehicle':
        customerID = (row.original as RowData).customerID;
        id = (row.original as RowData).vehicleID;
        type = 'vehicle';
        break;
      case 'OBU':
        customerID = (row.original as RowData).customerID;
        id = (row.original as RowData).obuid;
        type = 'obu';
        break;
      case 'Contract':
        customerID = (row.original as RowData).customerID;
        id = (row.original as RowData).contractID;
        type = 'contract';
        break;
      case 'Card':
        customerID = (row.original as RowData).customerID;
        id = (row.original as RowData).cardID;
        type = 'card';
        break;
      default:
        break;
    }

    if (id) {
      window.open(`/customer/${customerID}/${id}?type=${type}`, '_blank');
      return;
    }

    window.open(`/customer/${customerID}?type=${type}`, '_blank');
  };

  return (
    <div className="flex flex-col h-full">
      {!disableDetailSection && (
        <div className="flex flex-col mt-2  h-full row overflow-y-auto gap-2">
          <QueryError isLoading={isLoading} isError={isError}>
            <Table<TData>
              data={data}
              columns={cols}
              paginationData={paginationProps}
              columnSelection={false}
              onRowDoubleClick={handleDoubleClick}
              exportEnabled={false}
            />
          </QueryError>
        </div>
      )}
    </div>
  );
}

export default OverviewDetails;
