import React, { useState } from 'react';
import Tabs from 'components/Tabs/Tabs';
import { customerEndPoints } from 'api/apiEndpoints';
import { customerInstance } from 'api';
import { useQuery } from '@tanstack/react-query';
import QueryError from 'components/QueryError';
import { Formik, FormikHelpers } from 'formik';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { Button, Icons } from '@sede-x/shell-ds-react-framework';
import { queryClient } from 'react-query';
import { errorHelper } from 'utils/helpers/errorHelper';
import TollInformation from 'components/TollInformation/TollInformation';
import { Address } from '../types';
import AddressForm from '../AddressForm/AddressForm';
import { addressValidation } from '../utils/formUtils';

interface AddressDetailsProps {
  addressID?: string;
  onSuccess: () => void;
}

async function fetchAddressbyId(id: string) {
  const { data } = await customerInstance.post(
    customerEndPoints.addressDetails,
    {
      addressId: id
    }
  );
  return data;
}
const ADDRESS_LIST_QKEY = 'address-list';
const AddressDetails: React.FC<AddressDetailsProps> = ({
  addressID: addressId,
  onSuccess
}) => {
  const [selectedTab, setSelectedTab] = useState<string>('AddressDetails');
  const { errorDialog, successDialog, confirmCloseDialog } =
    useConfirmDialogs();

  const {
    isError,
    data: addressData,
    isLoading
  } = useQuery({
    queryKey: ['address-details', addressId],
    queryFn: () => fetchAddressbyId(addressId ?? ''),
    enabled: !!addressId && selectedTab === 'AddressDetails',
    refetchOnWindowFocus: false,
    staleTime: 300000
  });

  const handleSave = (
    values: Address,
    formikHelpers: FormikHelpers<Address>
  ) => {
    const { setSubmitting } = formikHelpers;
    customerInstance
      .post(customerEndPoints.updateAddress, values)
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: [ADDRESS_LIST_QKEY]
        });
        queryClient.invalidateQueries({
          queryKey: ['address-details', addressId]
        });
        successDialog('', 'Address updated successfully');
      })
      .catch((error) => {
        const message = 'Failed to update Address. Please try again.';
        errorDialog('Error', errorHelper(error, message));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleSaveAs = (values: Address) => {
    const { addressID, ...rest } = values;
    customerInstance
      .post(customerEndPoints.updateAddress, rest)
      .then(() => {
        queryClient.invalidateQueries({ queryKey: [ADDRESS_LIST_QKEY] });
        onSuccess();
        successDialog('', 'New Address saved successfully');
      })
      .catch(() => {
        errorDialog('', 'Failed to save as new Address');
      });
  };

  const handleDelete = async () => {
    const isConfirmed = await confirmCloseDialog(
      'Delete Address',
      'Are you sure you want to delete this Address?'
    );
    if (!isConfirmed) {
      return;
    }

    customerInstance
      .post(customerEndPoints.deleteAddress, { addressID: addressId })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: [ADDRESS_LIST_QKEY] });
        onSuccess();
        successDialog('', 'Address deleted successfully');
      })
      .catch(() => {
        errorDialog('', 'Failed to delete Address.');
      });
  };

  const items = [
    {
      label: 'ADDRESS DETAILS',
      key: 'AddressDetails',
      children: (
        <div className="flex h-full">
          <QueryError isError={isError} isLoading={isLoading}>
            <Formik
              initialValues={addressData?.data}
              validate={addressValidation}
              enableReinitialize
              onSubmit={handleSave}
            >
              {({ values, handleSubmit, isSubmitting, isValid }) => (
                <form className="p-2 w-full" onSubmit={handleSubmit}>
                  <div className="flex justify-end mb-2">
                    <Button
                      icon={<Icons.Save />}
                      size="xsmall"
                      variant="transparent"
                      type="submit"
                      disabled={!isValid || isSubmitting}
                      data-testid="save-address"
                    >
                      Save
                    </Button>

                    <Button
                      icon={<Icons.Save />}
                      size="xsmall"
                      variant="transparent"
                      disabled={!isValid || isSubmitting}
                      onClick={() => handleSaveAs(values)}
                      data-testid="save-as-address"
                    >
                      Save As
                    </Button>
                    <Button
                      icon={<Icons.TrashClear />}
                      size="xsmall"
                      variant="transparent"
                      onClick={handleDelete}
                      data-testid="delete-address"
                    >
                      Delete
                    </Button>
                  </div>
                  <AddressForm />
                </form>
              )}
            </Formik>
          </QueryError>
        </div>
      )
    },
    {
      label: 'INFORMATION',
      key: 'information',
      children: <TollInformation type="address" id={addressId ?? ''} />
    }
  ];

  const handleChangeTabs = (key: string) => {
    setSelectedTab(key);
  };
  return (
    <div className="bg-[#F7F7F7] w-full" data-testid="address-details">
      <Tabs items={items} onChange={handleChangeTabs} />
    </div>
  );
};

export default AddressDetails;
