import {
  Navigate,
  createBrowserRouter,
  RouterProvider,
  RouteObject
} from 'react-router-dom';
import Customer from 'Pages/Customer/Customer';
import TollReports from 'Pages/Actions/ProviderSpecfic/TollReports';
import CustomerDetails from 'Pages/CustomerDetails/CustomerDetails';
import Home from '../Pages/Home/Home';
import Reports from '../Pages/Reports/Reports';
import ErrorBoundary from '../utils/ErrorBoundary/ErrorBoundary';
import Providers from '../Pages/Providers/Providers';

export const routesConfig: RouteObject[] = [
  {
    ErrorBoundary,
    path: '/',
    element: <Home />,
    children: [
      {
        path: '/reports',
        element: <Reports />,
        handle: {
          crumb: () => <>Reports</>
        }
      },
      {
        path: '/',
        element: <Customer />,
        handle: {
          crumb: () => <>Home</>
        }
      },
      {
        path: '/customer/:customerId/:id?',
        element: <CustomerDetails />,
        handle: {
          crumb: () => <>Home</>
        }
      },
      {
        path: '/providers',
        element: <Providers />,
        handle: {
          crumb: () => <>Providers</>
        }
      },
      {
        path: '/providerSpecific',
        element: <TollReports />,
        handle: {
          crumb: () => <>Toll Specific</>
        }
      }
    ]
  },
  {
    path: '/*',
    element: <Navigate to="/" replace />
  }
];

function Navigation() {
  const router = createBrowserRouter(routesConfig);
  return <RouterProvider router={router} />;
}
export default Navigation;
