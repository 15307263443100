import {
  ExpanderButton,
  Flexbox,
  SelectionCheckbox
} from '@sede-x/shell-ds-react-framework';
import { createColumnHelper } from '@tanstack/react-table';
import { ReportData } from '../../../Pages/Reports/types';
import { columnBuilder } from '../../../utils/helpers';
import ReportColumns from './reportsColumns.json';

const columnHelper = createColumnHelper<ReportData>();

export function getReportsColumn(value: string) {
  return columns[value as keyof typeof columns];
}
const actions = [
  columnHelper.display({
    id: 'actions',
    size: 20,
    cell: ({ row }) => (
      <Flexbox gap="12px" alignItems="center">
        <SelectionCheckbox
          checked={row.getIsSelected()}
          indeterminate={row.getIsSomeSelected()}
          onChange={row.getToggleSelectedHandler()}
          data-testid={`rowcheck-${row.index}`}
        />
      </Flexbox>
    ),
    header: ({ table }) => (
      <Flexbox gap="12px" alignItems="center">
        <SelectionCheckbox
          checked={table.getIsAllRowsSelected()}
          indeterminate={table.getIsSomeRowsSelected()}
          onChange={table.getToggleAllRowsSelectedHandler()}
        />
      </Flexbox>
    )
  })
];

const expandAction = [
  columnHelper.display({
    id: 'expandAction',
    size: 20,
    cell: ({ row }) => (
      <ExpanderButton
        expanded={row.getIsExpanded()}
        onClick={() => row.toggleExpanded()}
      />
    ),
    header: ''
  })
];

const userError = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.userError)
];

const userActive = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.userActive)
];

const DuplicateTollDomainRegistration = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.DuplicateTollDomainRegistration)
];

const blacklist = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.blacklist)
];

const gfnCardExchangeHistory = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.gfnCardExchangeHistory)
];

const cardinuse = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.cardinuse)
];

const cardExchange = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.cardExchange)
];

const cardIssuerIncorrect = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.cardIssuerIncorrect)
];

const lastCardExchange = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.lastCardExchange)
];

const customerComments = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.customerComments)
];

const blockedCustomer = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.blockedCustomer)
];

const cardIncorrectAssigned = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.cardIncorrectAssigned)
];

const customerRegistration = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.customerRegistration)
];

const customerContacts = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.customerContacts)
];

const interfaceRunError = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.interfaceRunError)
];

const interfaceRuns = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.interfaceRuns)
];

const customerStatus = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.customerStatus)
];

const reports = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.reports)
];

const noVirtualCardActivated = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.noVirtualCardActivated)
];

const duplicatedVehicles = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.duplicatedVehicles)
];

const vehicleWithCardsInfo = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.vehicleWithCardsInfo)
];
const registeredAccountsOverview = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.registeredAccountsOverview)
];

const systemJobs = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.systemJobs)
];

const customerCardOrderPending = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.customerCardOrderPending)
];

const SFH = [...actions, ...columnBuilder<ReportData>(ReportColumns.SFH)];

const telepassOrderReport = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.telepassOrderReport)
];

const userList = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.userList)
];

const customerMissingMail = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.customerMissingMail)
];

const gfncustomerCardOrderError = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.gfncustomerCardOrderError)
];

const gfntransaction = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.gfntransaction)
];

const gfnVehicleCardOrderError = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.gfnVehicleCardOrderError)
];

const gfnExpiredCardReports = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.gfnExpiredCardReports)
];

const gfnVehicleCardOrder = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.gfnVehicleCardOrder)
];

const gfnVirtualCardOrder = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.gfnVirtualCardOrder)
];

const gfnOpenTransaction = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.gfnOpenTransaction)
];

const gfncustomerCardOrder = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.gfncustomerCardOrder)
];

const gfnTransactionError = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.gfnTransactionError)
];

const gfnMissingCardVehicle = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.gfnMissingCardVehicle)
];

const gfnCardBlocked = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.cardBlocked)
];

const UnprocessedDeviceOrders = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.UnprocessedDeviceOrders)
];

const axxesOrder = [
  ...actions,
  ...columnBuilder<ReportData>(ReportColumns.axxesOrder)
];

export const columns = {
  userError,
  userActive,
  blacklist,
  cardinuse,
  cardExchange,
  cardIssuerIncorrect,
  lastCardExchange,
  blockedCustomer,
  cardIncorrectAssigned,
  customerRegistration,
  customerContacts,
  interfaceRunError,
  interfaceRuns,
  customerComments,
  customerStatus,
  reports,
  noVirtualCardActivated,
  duplicatedVehicles,
  vehicleWithCardsInfo,
  registeredAccountsOverview,
  systemJobs,
  customerCardOrderPending,
  SFH,
  userList,
  customerMissingMail,
  gfncustomerCardOrderError,
  gfntransaction,
  gfnTransactionError,
  gfnVehicleCardOrderError,
  gfnExpiredCardReports,
  gfnVehicleCardOrder,
  gfnVirtualCardOrder,
  gfnOpenTransaction,
  gfncustomerCardOrder,
  gfnCardExchangeHistory,
  gfnCardBlocked,
  gfnMissingCardVehicle,
  UnprocessedDeviceOrders,
  DuplicateTollDomainRegistration,
  telepassOrderReport,
  axxesOrder
};
