import { TTokenData } from './Types';

const STRING_LENGTH = 16;
const SLICE_LENGTH = 2;

/**
 * Decodes the base64 encoded JWT. Returns a TToken.
 */
export const decodeJWT = (token: string): TTokenData => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => {
          const code = `00${c.charCodeAt(0).toString(STRING_LENGTH)}`;
          return `%${code.slice(SLICE_LENGTH)}`;
        })
        .join('')
    );
    return JSON.parse(jsonPayload);
  } catch (e) {
    throw Error('Failed to decode the access token.');
  }
};
