import TextField from 'components/FormCmponents/TextField';
import dayjs from 'dayjs';
import { Field, useFormikContext } from 'formik';
import DatePicker from 'components/FormCmponents/DatePicker';
import { Vehicle } from '../types';

export const Details = () => {
  const { setFieldValue } = useFormikContext<Vehicle>();
  return (
    <>
      <Field
        id="firstDayOfRegistration"
        name="firstDayOfRegistration"
        label="First day of registration"
        labelPosition="left"
        onChange={(v: dayjs.Dayjs | null) => {
          setFieldValue('firstDayOfRegistration', v?.toISOString());
        }}
        component={DatePicker}
        placeholder="DD/MM/YYYY"
        required
      />
      <Field
        id="truckTractorAxlesNumber"
        name="truckTractorAxlesNumber"
        label="Truck axles number"
        labelPosition="left"
        component={TextField}
        placeholder="Type here"
        required
      />
      <Field
        id="trailerAxlesNumber"
        name="trailerAxlesNumber"
        label="Trailer axels number"
        labelPosition="left"
        component={TextField}
        placeholder="Type here"
      />
      <Field
        id="grossVehicleWeightRating"
        name="grossVehicleWeightRating"
        label="Vehicle weight(F.2)[kg]"
        labelPosition="left"
        component={TextField}
        placeholder="Type here"
      />
      <Field
        id="authorizedGrossVehicleWeight"
        name="authorizedGrossVehicleWeight"
        label="Authorized vehicle weight(F.3)[kg]"
        labelPosition="left"
        component={TextField}
        placeholder="Type here"
      />
      <Field
        id="totalVehicleWeight"
        name="totalVehicleWeight"
        label="Total vehicle weight(G)[kg]"
        labelPosition="left"
        component={TextField}
        placeholder="Type here"
      />
      <Field
        id="weightF1"
        name="weightF1"
        label="Max. technical laden weight(F.1)[kg]"
        labelPosition="left"
        component={TextField}
        placeholder="Type here"
        required
      />
      <Field
        id="trailerMaxLadenWeight"
        name="trailerMaxLadenWeight"
        label="Trailer Max. laden weight(F.2)[kg]"
        labelPosition="left"
        component={TextField}
        placeholder="Type here"
      />
      <Field
        id="cop"
        name="cop"
        label="Cop value[g/km]"
        labelPosition="left"
        component={TextField}
        placeholder="Type here"
      />
    </>
  );
};
