import React from 'react';

import ReactDOM from 'react-dom/client';
import { ShellThemeProvider } from '@sede-x/shell-ds-react-framework';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ConfirmContextProvider from 'common/confirmContextProvider';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import { AuthProvider } from 'auth/AuthContext';
import AppErrorBoundary from 'AppErrorBoundary';
import { queryClient } from './react-query';

import './index.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <AppErrorBoundary>
    <AuthProvider>
      <React.StrictMode>
        <ShellThemeProvider theme="light">
          <QueryClientProvider client={queryClient}>
            <ConfirmContextProvider>
              <App />
              <ReactQueryDevtools />
              <ConfirmDialog />
            </ConfirmContextProvider>
          </QueryClientProvider>
        </ShellThemeProvider>
      </React.StrictMode>
    </AuthProvider>
  </AppErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
