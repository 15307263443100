import SelectField from 'components/FormCmponents/SelectField';
import TextField from 'components/FormCmponents/TextField';
import { Field } from 'formik';
import ChassisTypes from 'utils/StaticData/Vehicle/chassisType.json';
import CabinTypes from 'utils/StaticData/Vehicle/cabinType.json';
import EuroVehicleSubGroupTypes from 'utils/StaticData/Vehicle/euroVehicleSubGroup.json';
import co2ClassTypes from 'utils/StaticData/Vehicle/co2ClassType.json';

export const Co2Tolling = () => (
  <>
    <Field
      id="drivenAxlesNumber"
      name="drivenAxlesNumber"
      label="Driven Axels Number"
      labelPosition="left"
      component={TextField}
      placeholder="Type here"
    />
    <Field
      id="chassisTypeID"
      name="chassisTypeID"
      label="Chassis type"
      labelPosition="left"
      component={SelectField}
      placeholder="Select"
      options={ChassisTypes.map((item) => ({
        label: item.Name,
        value: item.Id
      }))}
    />
    <Field
      id="CabinTypeID"
      name="CabinTypeID"
      label="Cabin type"
      labelPosition="left"
      component={SelectField}
      placeholder="Select"
      options={CabinTypes.map((item) => ({
        label: item.Name,
        value: item.Id
      }))}
    />
    <Field
      id="enginePower"
      name="enginePower"
      label="Engine power[kw]"
      labelPosition="left"
      component={TextField}
      placeholder="Type here"
    />
    <Field
      id="engineCapacity"
      name="engineCapacity"
      label="Engine capacity[kw]"
      labelPosition="left"
      component={TextField}
      placeholder="Type here"
    />
    <Field
      id="euroVehicleSubGroupTypeId"
      name="euroVehicleSubGroupTypeId"
      label="Euro Vehicle Sub Group"
      labelPosition="left"
      component={SelectField}
      placeholder="Select"
      options={EuroVehicleSubGroupTypes.map((item) => ({
        label: item.Name,
        value: item.Id
      }))}
    />
    <Field
      id="co2EmissionValueLoad"
      name="co2EmissionValueLoad"
      label="CO2 emission [g/t*km]"
      labelPosition="left"
      component={TextField}
      placeholder="Type here"
    />
    <Field
      id="cop"
      name="cop"
      label="CO2 emission [g/km]"
      labelPosition="left"
      component={TextField}
      placeholder="Type here"
    />
    <Field
      id="carbonDioxideClassTypeId"
      name="carbonDioxideClassTypeId"
      label="Carbon dioxide class type"
      labelPosition="left"
      component={SelectField}
      disabled
      options={co2ClassTypes.map((item) => ({
        label: item.Name,
        value: item.Id
      }))}
    />
    <Field
      id="fuelTankCapacity"
      name="fuelTankCapacity"
      label="Fuel Tank Capacity"
      labelPosition="left"
      component={TextField}
      placeholder="Type here"
    />
  </>
);
