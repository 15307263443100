import {
  Alignments,
  Button,
  Icons,
  Modal,
  Sizes
} from '@sede-x/shell-ds-react-framework';
import { useState } from 'react';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import styled from 'styled-components';
import { Formik, FormikHelpers } from 'formik';
import { customerEndPoints } from 'api/apiEndpoints';
import { customerInstance } from 'api';
import { queryClient } from 'react-query';
import { Address } from '../types';
import AddressForm from '../AddressForm/AddressForm';
import { addressValidation } from '../utils/formUtils';

const StyledModal = styled(Modal)`
  .shell-modal-container-content {
    height: auto;
    padding: 20px;
  }
`;
interface AddressDetailsProps {
  customerId: string;
  onSuccess?: () => void;
}
const AddAddress: React.FC<AddressDetailsProps> = ({ customerId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { errorDialog, successDialog } = useConfirmDialogs();
  const onClose = () => {
    setIsOpen(false);
  };

  const handleSave = (
    values: Address,
    formikHelpers: FormikHelpers<Address>
  ) => {
    const { setSubmitting } = formikHelpers;
    customerInstance
      .post(customerEndPoints.updateAddress, {
        customerID: customerId,
        ...values
      })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: ['address-list']
        });
        successDialog('', 'Address created successfully');
        onClose();
      })
      .catch(() => {
        errorDialog('', 'Failed to create new address');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  return (
    <div>
      <Button
        onClick={() => setIsOpen(true)}
        icon={<Icons.Add />}
        iconPosition="left"
        data-testid="add-new-address"
      >
        New Address
      </Button>

      {isOpen && (
        <Formik<Address>
          initialValues={{}}
          enableReinitialize
          validate={addressValidation}
          onSubmit={handleSave}
        >
          {({ isValid, handleSubmit, isSubmitting, dirty }) => (
            <StyledModal
              width="60%"
              title="New Address"
              size={Sizes.Small}
              maskClosable={false}
              mask
              contentScrollable
              actionsAlignment={Alignments.Right}
              centered
              open={isOpen}
              onClose={onClose}
              actions={[
                {
                  label: 'Submit',
                  action: () => {
                    handleSubmit();
                  },
                  props: {
                    icon: <Icons.Save />,
                    disabled: !(isValid && dirty) || isSubmitting,
                    name: 'submit'
                  }
                }
              ]}
            >
              <div>
                <form className="p-2 w-full" onSubmit={handleSubmit}>
                  <AddressForm />
                </form>
              </div>
            </StyledModal>
          )}
        </Formik>
      )}
    </div>
  );
};

export default AddAddress;
