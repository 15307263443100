import { ConfirmModalType } from 'utils/Interfaces/confirm';
import { JSX } from 'react';
import useConfirm from './useConfirm';

interface IConfirmPayload {
  primaryAction?: string;
  secondaryAction?: string;
  type?: ConfirmModalType;
  primaryIcon?: JSX.Element;
  secondaryIcon?: JSX.Element;
}

type ConfirmFunction = (
  title: string,
  text: string,
  options?: IConfirmPayload
) => Promise<boolean>;

function useConfirmDialogs() {
  const { isConfirmed } = useConfirm();

  const successDialog: ConfirmFunction = (title, text, options) =>
    isConfirmed(
      title,
      text,
      options?.primaryAction,
      options?.secondaryAction,
      'success'
    );

  const infoDialog: ConfirmFunction = (title, text, options) =>
    isConfirmed(
      title,
      text,
      options?.primaryAction,
      options?.secondaryAction,
      options?.type ?? 'warning',
      options?.primaryIcon,
      options?.secondaryIcon
    );

  const errorDialog: ConfirmFunction = (title, text, options) =>
    isConfirmed(
      title,
      text,
      options?.primaryAction,
      options?.secondaryAction,
      'error'
    );

  const confirmCloseDialog: ConfirmFunction = (title, text, options) => {
    const defaults = {
      title: 'Are you sure you want to reject this record?',
      text: 'Reject the record',
      primaryAction: 'Confirm',
      secondaryAction: 'Cancel'
    };
    return infoDialog(title || defaults.title, text || defaults.text, {
      primaryAction: options?.primaryAction ?? defaults.primaryAction,
      secondaryAction: options?.secondaryAction ?? defaults.secondaryAction,
      type: 'info'
    });
  };

  return {
    successDialog,
    errorDialog,
    infoDialog,
    confirmCloseDialog
  };
}

export default useConfirmDialogs;
