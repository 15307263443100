import { useQuery } from '@tanstack/react-query';
import { customerInstance } from 'api';
import { LogsEndpoints } from 'api/apiEndpoints';
import QueryError from 'components/QueryError';
import { ExpandableTable } from 'components/Table/ExpandableTable';
import { getOverviewColumns } from 'components/Table/columns/overviewColumns';
import React, { useState } from 'react';
import { STALE_TIME } from 'react-query';
import { columnBuilder } from 'utils/helpers';
import { useSdsPagination } from 'hooks/use-pagination';
import { ColumnDef } from '@tanstack/react-table';
import { Log } from './types';

interface LogProps {
  type: string;
  id: string;
}

type Identifier = {
  [key: string]: {
    field: string;
    label: string;
  };
};

const identifier: Identifier = {
  customer: {
    field: 'customerID',
    label: 'Customer ID'
  },
  obu: {
    field: 'obuid',
    label: 'OBU ID'
  },
  contract: {
    field: 'contractID',
    label: 'Contract ID'
  },
  vehicle: {
    field: 'vehicleID',
    label: 'Vehicle ID'
  },
  contact: {
    field: 'contactID',
    label: 'Contact ID'
  },
  card: {
    field: 'cardID',
    label: 'Card ID'
  },
  address: {
    field: 'addressID',
    label: 'Address ID'
  }
};

async function fetchLogs(
  type: string,
  id: string,
  pageNumber: number,
  size: number
) {
  const { data } = await customerInstance.post(LogsEndpoints.get[type], {
    id,
    pageNumber,
    pageSize: size
  });
  return data;
}

const DEFAULT_PAGE_SIZE = 20;

const Logs: React.FC<LogProps> = ({ type, id }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const { isError, isLoading, data } = useQuery({
    queryKey: ['Logs', id, type, pageNumber, pageSize],
    queryFn: () => fetchLogs(type, id, pageNumber, pageSize),
    staleTime: STALE_TIME,
    refetchOnWindowFocus: 'always',
    enabled: !!id
  });

  const handleChangePagination = (
    newPageNumber: number,
    newPageSize: number
  ) => {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
  };

  const paginationProps = useSdsPagination(handleChangePagination);
  const customColumn = columnBuilder([identifier[type]]);
  const { data: logData, count } = data || {};

  return (
    <div className=" bg-white p-4 h-full" data-testid="logs">
      <div className="flex  flex-col h-full">
        <QueryError isLoading={isLoading} isError={isError}>
          <div className="bg-[#F0F0F0] h-full pb-3 overflow-y-auto flex flex-col">
            <ExpandableTable<Log>
              data={logData}
              columns={
                [
                  ...customColumn,
                  ...getOverviewColumns('Logs')
                ] as ColumnDef<Log>[]
              }
              stickyColumns={['actions']}
              columnSelection={false}
              exportEnabled={false}
              paginationData={{ ...paginationProps, total: count }}
            />
          </div>
        </QueryError>
      </div>
    </div>
  );
};

export default Logs;
