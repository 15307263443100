import { Tabs } from '@sede-x/shell-ds-react-framework';
import styled from 'styled-components';

export const StyledTabs = styled(Tabs)`
  flex-grow: 1;
  height: 100%;

  .uirefresh-tabs-nav {
    .uirefresh-tabs-tab-btn {
      font-size: 13px;
      font-weight: 600;
    }
  }

  .uirefresh-tabs-content-holder {
    padding: 16px;
    overflow: auto;
    background-color: transparent;
  }
`;
