import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useSdsPagination } from 'hooks/use-pagination';
import { SelectedMenu } from 'components/SideMenu/types';
import { useStaticData } from 'hooks/useStaticData';
import { TollMap, TollObject } from 'Pages/Providers/types';
import { AuthContext, IAuthContext } from 'auth';
import {
  createExportAllRequest,
  filterReportMenuBasedOnRoles,
  getReportDetails
} from 'utils/helpers';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { endpoints, exportAllEndpoints } from 'api/apiEndpoints';
import { axiosInstance } from 'api';
import { saveAs } from 'file-saver';
import SideMenu from '../../components/SideMenu/SideMenu';
import ReportsDetails from '../../components/ReportsContent/ReportsDetails';
import { getReportsColumn } from '../../components/Table/columns/reportsColumns';
import { ReportData } from './types';
import ReportsMenuUnselected from '../../components/ReportsContent/ReportsMenuUnselected';
import { useReport } from '../../hooks/useReports';

import { ReportsMenu, ShellConsortiumMenu } from './ReportMenu';
import { ReportContainer } from './style';
import { ParamsType } from '../../components/ContentHeader/types';
import ReportFilters from '../../assets/filters/ReportFilters.json';

const SUCCESS_CODE = 200;

const Reports = () => {
  const [menu, setMenu] = useState<string>('');
  const [menuLabel, setMenuLabel] = useState<string>('');
  const [exportAllEnabled, setExportAllEnabled] = useState<boolean>();
  const [params, setParams] = useState<ParamsType>({});
  const [tollMap, setTollMap] = useState<TollMap>({});
  const [parentMenu, setParentMenu] = useState<string>('');
  const [reportName, setReportName] = useState<string>('');
  const { tokenData } = useContext<IAuthContext>(AuthContext);

  const { errorDialog, successDialog } = useConfirmDialogs();

  const { data: userRoles } = useStaticData(
    'userroles',
    'Auth/userroles',
    !!tokenData?.mail,
    null,
    { retryOnMount: false }
  );

  const {
    mutate: getReport,
    data,
    isLoading,
    isError,
    reset: resetData
  } = useReport(menu);
  const { data: tollData } = useStaticData('toll', 'toll', true);

  const handleChangePagination = (pageNumber: number, newPageSize: number) => {
    getReport({
      pageNumber,
      pageSize: newPageSize,
      tollId: tollMap[parentMenu]?.tollId,
      ...params
    });
  };
  const paginationProps = useSdsPagination(handleChangePagination);
  const { resetPagination } = paginationProps;

  useEffect(() => {
    if (tollData) {
      const obj: TollMap = {};
      tollData.data.forEach((tollObj: TollObject) => {
        obj[tollObj.name] = tollObj;
      });
      setTollMap(obj);
    }
  }, [tollData]);

  useLayoutEffect(() => {
    setParams({});
    resetPagination();
    resetData();
  }, [menu]);

  const getReportsData = () => {
    if (parentMenu === 'ShellConsortium') {
      const requestPayload = {
        ...params
      };

      axiosInstance
        .post(endpoints[parentMenu], requestPayload, {
          responseType: 'arraybuffer'
        })
        .then((response) => {
          if (response.status === SUCCESS_CODE) {
            const file = new Blob([response.data], {
              type: 'application/octet-stream;'
            });

            saveAs(file, `${parentMenu}-${menu}.xlsx`);
          }
        })
        .catch(() => {
          errorDialog(
            '',
            'Your download request has been failed. Please try again.'
          );
        });
    } else {
      resetPagination();
      getReport({
        pageNumber: 1,
        pageSize: 20,
        tollId: tollMap[parentMenu]?.tollId,
        ...params
      });
    }
  };

  const handleMenuClick = (selectedMenu: SelectedMenu) => {
    const { keyPath, key, label, exportAll, reportName: name } = selectedMenu;
    setMenu(key);
    setParentMenu(keyPath[keyPath.length - 1]);
    setMenuLabel(label);
    setExportAllEnabled(exportAll);
    name && setReportName(name);
  };

  const onClickExportAll = (errors?: ParamsType) => {
    if (errors && Object.keys(errors).length) {
      errorDialog('', 'Please enter correct data.');
    } else {
      const reportsDetails = getReportDetails(
        reportName,
        tollMap[parentMenu]?.tollId,
        userRoles
      );
      const requestPayload = {
        reportName,
        reportID: reportsDetails?.reportID,
        eventType: reportsDetails?.eventType ?? reportsDetails?.reportName,
        reportType: reportName,
        tollId: tollMap[parentMenu]?.tollId,
        ...params
      };

      createExportAllRequest(
        exportAllEndpoints[menu] ?? exportAllEndpoints.exportAll,
        requestPayload
      )
        .then((response) => {
          if (response.status === SUCCESS_CODE) {
            successDialog(
              '',
              'Your download request has been received. Notifications will be updated as soon as the download link is available. Thank you for your patience!'
            );
          }
        })
        .catch(() => {
          errorDialog(
            '',
            'Your download request has been failed. Please try again.'
          );
        });
    }
  };

  return (
    <ReportContainer className="flex p-3">
      <div className="flex flex-shrink-0 flex-col bg-white mr-2 overflow-y-scroll no-scrollbar">
        <SideMenu
          setMenu={handleMenuClick}
          menuItems={[
            ...filterReportMenuBasedOnRoles(ReportsMenu, userRoles),
            ...ShellConsortiumMenu
          ]}
          searchEnabled
        />
      </div>
      <div className="mr-2 flex flex-col flex-grow overflow-auto">
        {!menu ? (
          <ReportsMenuUnselected
            welcomeTitle="Welcome to Reports"
            content="In this section you can find specific reports and can filter and export
          them, Please select the report from left hand side menu."
          />
        ) : (
          <ReportsDetails<ReportData>
            title={menuLabel}
            menuKey={menu}
            onButtonClick={getReportsData}
            buttonText={parentMenu === 'ShellConsortium' ? 'Export' : 'Execute'}
            data={data?.data}
            cols={getReportsColumn(menu)}
            params={params}
            isLoading={isLoading}
            isError={isError}
            setParams={setParams}
            paginationProps={{
              ...paginationProps,
              total: data?.totalNumberOfRecords
            }}
            filterObject={{ ...ReportFilters }}
            onClickExportAll={onClickExportAll}
            exportAllEnabled={exportAllEnabled}
            disableDetailSection={parentMenu === 'ShellConsortium'}
          />
        )}
      </div>
    </ReportContainer>
  );
};

export default Reports;
