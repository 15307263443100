import { useState, useEffect } from 'react';
import {
  Button,
  Icons,
  Sizes,
  Flexbox,
  Variants,
  Badge,
  Positions,
  Text,
  Loading
} from '@sede-x/shell-ds-react-framework';
import { useQuery } from '@tanstack/react-query';
import { axiosInstance } from 'api';
import useConfirmDialogs from 'hooks/useConfirmDialogs';
import { formatGMTtoLocalDate } from 'utils/helpers';
import { saveAs } from 'file-saver';
import {
  NotificationPopupType,
  CustomTabs,
  NotificationType,
  DownloadTabType
} from './types';
import { StyledTabs } from './style';

interface DownloadPayload {
  eventType: string;
  downloadDocumentId: string;
  tollId: string;
  isProviderReport?: boolean;
  providerReportPathSuffix?: string;
}

const STALE_TIME = 60000;

function NotificationCard({
  eventType,
  status,
  createdOn,
  downloadDocumentId,
  requestId,
  tollId,
  fileName,
  isProviderReport,
  providerReportPathSuffix
}: Readonly<NotificationType>) {
  const { errorDialog } = useConfirmDialogs();

  const handleDownloadReport = () => {
    const payload: DownloadPayload = {
      eventType,
      downloadDocumentId,
      tollId,
      isProviderReport,
      providerReportPathSuffix
    };
    axiosInstance
      .post('exportall/download', payload, {
        responseType: 'arraybuffer'
      })
      .then((res) => {
        const file = new Blob([res.data], {
          type: 'application/octet-stream;'
        });

        saveAs(file, `${fileName}-${createdOn}.xlsx`);
      })
      .catch(() => {
        errorDialog(
          '',
          'Your download request has been failed. Please try again.'
        );
      });
  };

  return (
    <button
      type="button"
      className="flex justify-between items-center text-left font-ShellFont w-full mb-2 p-4 border-solid border rounded bg-[#F1F4F9]"
      onClick={handleDownloadReport}
      aria-label="Download"
    >
      <div>
        <Text size={Sizes.Small}>
          <span className="font-bold">{fileName}</span>
        </Text>
        <Text size={Sizes.Small}>{status}</Text>
        <Text size={Sizes.Small}>
          <span className="text-shellLabel text-xs">
            {formatGMTtoLocalDate(createdOn, 'DD/MM/YYYY h:mm A')}
          </span>
        </Text>
      </div>
      <Button
        iconOnly
        icon={<Icons.Download />}
        variant={Variants.Transparent}
        size={Sizes.Medium}
        data-testid={`download-report-${requestId}`}
      />
    </button>
  );
}

function DownloadTabContent({
  setNotificationCount,
  userRoles
}: Readonly<DownloadTabType>) {
  const { data, isLoading, refetch } = useQuery(
    ['exportallnotification'],
    async () => {
      const result = await axiosInstance.get('exportall/notifications');
      return result.data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: STALE_TIME,
      refetchInterval: STALE_TIME
    }
  );

  useEffect(() => {
    if (data?.data && data?.data.length >= 0) {
      setNotificationCount(data?.data.length);
    }
  }, [data]);

  return (
    <div>
      <div className="flex justify-end mr-1 mb-2">
        <Button
          variant="outlined"
          size={Sizes.ExtraSmall}
          icon={<Icons.ArrowCcw />}
          iconPosition={Positions.Right}
          onClick={() => refetch()}
          data-testid="refresh-notifictions"
        >
          Refresh
        </Button>
      </div>
      <div className="h-[40vh] overflow-auto no-scrollbar">
        {isLoading && (
          <div className="flex justify-center items-center">
            <Loading type="default" size="medium" data-testid="roles-loader" />
          </div>
        )}
        {!isLoading &&
          data?.data?.map((report: NotificationType) => (
            <NotificationCard {...report} userRoles={userRoles} />
          ))}
      </div>
    </div>
  );
}

const LabelAndBadge = ({ label, count }: CustomTabs) => (
  <Flexbox columnGap="8px" alignItems="center" className="font-ShellFont">
    <Text size={Sizes.Small}>
      <span className="text-xs font-bold">{label}</span>
    </Text>
    <Badge size={Sizes.ExtraSmall} variant={Variants.Filled}>
      <span className="text-xs font-bold">{count}</span>
    </Badge>
  </Flexbox>
);

function NotificationPopup({
  setpopupVisible,
  userRoles
}: Readonly<NotificationPopupType>) {
  const [notificationCount, setNotificationCount] = useState(0);

  const items = [
    {
      label: <LabelAndBadge label="Downloads" count={notificationCount} />,
      key: 'downloads',
      children: (
        <DownloadTabContent
          setNotificationCount={setNotificationCount}
          userRoles={userRoles}
        />
      )
    }
  ];
  return (
    <div
      style={{ width: '400px' }}
      className="overflow-y-auto"
      data-testid="notifications"
    >
      <div className="flex justify-between items-center mx-2   my-1">
        <div>
          <p className="font-bold">Notifications</p>
        </div>
        <Button
          iconOnly
          icon={<Icons.Cross />}
          data-testid="notifications-close"
          size="small"
          variant="transparent"
          onClick={() => setpopupVisible(false)}
        />
      </div>
      <StyledTabs items={items} className="p-2" />
    </div>
  );
}

export default NotificationPopup;
