import styled from 'styled-components';
import { Flexbox } from '@sede-x/shell-ds-react-framework';

export const FileInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: pointer;
`;

export const ChooseFile = styled(Flexbox)`
  border: solid 1px ${({ theme }) => theme.border.medium};
  border-radius: 2px;
  padding: 5px;
`;
