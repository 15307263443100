import styled from 'styled-components';
import {
    Icons,
} from '@sede-x/shell-ds-react-framework';

export const ReportsIcon = styled(Icons.File)`
width:50px;
height:50px;
    &&& path {
    fill:#FFC600;
    }
  `;
