import {
  ISelectProps,
  Menu,
  MenuItem,
  Popover,
  Icons
} from '@sede-x/shell-ds-react-framework';
import { ChangeEvent, useEffect, useState } from 'react';
import { StyledTextInput } from '../ContentHeader/styles';

function MultiSelectTextInput({
  placeHolder,
  options,
  onChange
}: Readonly<{
  options: ISelectProps['options'];
  placeHolder: string | undefined;
  onChange: (key: string, value: string) => void;
}>) {
  const [selectedMenu, setSelectedMenu] = useState({
    key: '',
    label: 'Select'
  });

  const [value, setValue] = useState('');

  useEffect(() => {
    if (options?.length) {
      setSelectedMenu({
        key: options[0].key,
        label: options[0].label as string
      });
    }
  }, []);

  useEffect(() => {
    onChange(selectedMenu.key, value);
  }, [selectedMenu]);

  return (
    <StyledTextInput
      prefix={{
        node: (
          <Popover
            arrow
            popup={
              <Menu>
                {options?.map((o) => (
                  <MenuItem
                    onClick={() => {
                      onChange(selectedMenu.key, '');
                      setSelectedMenu({ key: o.key, label: o.label as string });
                    }}
                    key={o.key!}
                    data-testid={`${o.label}-menu`}
                  >
                    {o.label}
                  </MenuItem>
                ))}
              </Menu>
            }
          >
            <div className="flex">
              <span
                className="cursor-pointer"
                data-testid={`popover-${selectedMenu.label}`}
              >
                {selectedMenu.label}
              </span>
              <span className="grow">
                <Icons.ChevronSmallDown />
              </span>
            </div>
          </Popover>
        )
      }}
      value={value}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        onChange(selectedMenu.key, event.target.value);
      }}
      size="small"
      data-testid={`${placeHolder}-multi-input`}
      placeholder={placeHolder}
    />
  );
}

export default MultiSelectTextInput;
