import { EmptyStateIconSentiment } from '@sede-x/shell-ds-react-framework';
import { ReactElement } from 'react';
import { StyledEmptyState } from './styles';

const ErrorState = ({
  title,
  description,
  icon,
  sentiment
}: {
  title: string;
  description: string;
  icon: ReactElement;
  sentiment: EmptyStateIconSentiment;
}) => (
  <StyledEmptyState
    className="font-ShellFont"
    icon={{
      component: icon,
      sentiment
    }}
    title={title}
    description={description}
  />
);

export default ErrorState;
