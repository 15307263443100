import {
  Button,
  Checkbox,
  Icons,
  Label
} from '@sede-x/shell-ds-react-framework';
import { capitalize } from 'lodash';
import { Fragment } from 'react';
import { Table as TableType } from '@tanstack/react-table';
import { StyledPopover } from './style';

const COLUMN_VISIBLE_LENGTH = 4;

const Item = <TData,>({ table }: { table: TableType<TData> }) => (
  <>
    {table.getAllLeafColumns().map((column) => (
      <Fragment key={column.id}>
        {!['actions', 'expandAction'].includes(column.id) && (
          <div>
            <div className="flex flex-row space-x-1 text-sm items-center">
              <Checkbox
                size="small"
                checked={column.getIsVisible()}
                data-testid={`columnVisible-${column.id}`}
                aria-label={capitalize(column.id)}
                onChange={(e) => {
                  const { checked } = e.target;
                  if (
                    table.getVisibleLeafColumns().length > COLUMN_VISIBLE_LENGTH
                  ) {
                    column.toggleVisibility?.(checked);
                  } else {
                    checked && column.toggleVisibility?.(checked);
                  }
                }}
              />
              <Label bold>{column.columnDef.header as string}</Label>
            </div>
          </div>
        )}
      </Fragment>
    ))}
  </>
);

const ShowHideColumns = <TData,>({ table }: { table: TableType<TData> }) => (
  <>
    <div
      data-testid="tableFilters"
      className="gap-2 grid-cols-2 md:grid-cols-3 lg:grid-cols-7 hidden lg:grid items-center"
    >
      <Item table={table} />
    </div>
    <div className="flex justify-end relative lg:hidden">
      <StyledPopover
        className="no-scrollbar border"
        popup={
          <div className="flex flex-col gap-2 overflow-y-scroll p-2 no-scrollbar">
            <Item table={table} />
          </div>
        }
        arrow
        popupPlacement="bottomRight"
        getPopupContainer={(triggerNode: HTMLElement) =>
          triggerNode.parentElement as HTMLElement
        }
      >
        <Button
          icon={<Icons.ChevronDown />}
          iconPosition="right"
          size="xsmall"
          variant="outlined"
          data-testid="showHideColumns"
        >
          Columns
        </Button>
      </StyledPopover>
    </div>
  </>
);

export default ShowHideColumns;
